@import 'scss/size.scss';

.blockBased-side-navbar-container {
  height: calc(100vh - #{hs(88px)});
  z-index: 999;
  pointer-events: none;
  @include resD((
    width: hs(200px),
    opacity: 1,
    visibility: visible,
  ));
  @include resM((
    width: hs(0px),
    opacity: 0,
    visibility: hidden,
  ));
  &::-webkit-scrollbar  {
    max-width: hs(8px);
    display: flex;
  }
}
.additionalRenderer{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
}
.navbar {
  overflow: hidden;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  padding-left: 12px;
  padding-right: 12px;
  z-index: 998;
  box-sizing: border-box;
}

.flashContainer{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: rgba(0,0,0,0.4);
  //animation: mymove 0.3s ease-in;
}
.nav-menu {
  background-color: white;
  background-image: url(../../assets/mobileSidebarBg.png);
  background-position: bottom;
  background-repeat: no-repeat;
  width: 270px;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  z-index: 999;
  left: -100%;
  transition: 850ms;
  display: flex;
  flex-direction: column;
}
.nav-menu-items {
  width: 100%;
  margin-top: 20px;
}
.nav-menu.active {
  left: 0;
  transition: 350ms;
}
.arrow-back{
  height: 30px;
  width: 30px;
}
.navbar-toggle {
  background-color: #060b26;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}
.updatedSidebar-heading{
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: #00ADE6;
  margin-left: 15px;
}

.hamburger-icon{
  width: 20px;
  height: 3px;
  background-color: #333333;
  margin-bottom: 3px;
  border-radius: 10px;
  &:nth-child(1) {
    margin-top: 3px;
  }
}
.front-bar{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sidebar-top{
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  align-items: center;
  margin-top: 10px;
  text-decoration: none;
}
.blockBased-side-navbar-bgOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  z-index: -1;
  background: white;
}
.sidebar-top-heading{
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #005773;
  margin-left: 10px;
  text-decoration: none;

}
.sidebar-image{
  margin-right: 15px;
  margin-left: 15px;
  & > svg{
    //fill: rgb(223, 69, 69);
    stroke:#A8A7A7;
    fill: white;
    // stroke: white;
  }
  & > .docIcon {
    stroke: none;
    fill: #A8A7A7;
  }
}
.sidebar-image-available{
  & > svg{
    stroke:#005773;
    fill: white;
    & .pQpath:nth-child(3) {
      fill: #005773;
    }
    &.docIcon > path {
      stroke: none;
      fill: #005773;
    }
    &.hintIcon > path {
      stroke: none;
      fill: #005773;
    }
    &.brushIcon > path {
      stroke:none;
      fill: #005773;
    }
  }
}
.sidebar-image-active{
  & > svg{
    stroke:white;
    fill: #00ADE6;
    & .pQpath:nth-child(3) {
      fill: #FFF;
    }
    &.docIcon > path {
      stroke:none;
      fill: #FFF;
    }
    &.hintIcon > path {
      stroke:none;
      fill: #FFF;
    }
    &.brushIcon > path {
      stroke:none;
      fill: #FFF;
    }
  }
}
.active-sidebar-image{
  margin-left: 15px;
  margin-right: 15px;
}
.losidebar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  pointer-events: none;
}
.losidebar-available{
  pointer-events: auto;
}
.sidebar-item{
  display: flex;
  flex-direction: row;
  // margin-top: 10px;
  align-items: center;
  padding: 10px 0px 5px;
  //padding-bottom: 10px;
  text-decoration: none;
  transform: scale(1.0);
  pointer-events: none;
}
.sidebar-item-active{
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #00ADE6;
  padding-top: 10px;
  color: white;
  width: 100%;
  pointer-events: auto;

  & ~ .forward-arrow svg {
    fill: white;
  }
}
.forward-arrow{
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 4px;
  right: 0px;
  cursor: pointer;
  bottom: 50%;
  transform: translateY(50%);
position: absolute;
// margin-top: 10px;
}
.forward-arrow-rotate{
  padding-left: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  transform:rotateZ(90deg);
}

.sub-menu{
  position: static;
  display: none;
  transition: ease-in;
}
.sub-menu.show{
  display: flex;
  flex-direction: column;
  transition: 320ms;
}

.active-sidebar-item{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
  background-color: #00ADE6;
  padding-top: 10px;
  padding-bottom: 10px;
}

.options{
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #A8A7A7;
  margin-bottom: 7px;
  pointer-events: none;
  width: 100%;
}
.losidebar {
  .options {
    width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.options-available{
  color: #005773;
  pointer-events: auto;
}
.options-active{
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  pointer-events: auto;
  color: #ffffff;
}
.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: rgba(168, 167, 167, 0.2);
}

.circle-progress {
  stroke: #00C187;
  stroke-linecap: square;
  stroke-linejoin: square;
}

.progressIndicator-wh-40 {

  @include resD((
    width: 35px !important,
    height: 35px !important,
  ));
  @include resM((
    width: 22px !important,
    height: 22px !important,
  ));
}

.progressIndicator-overall-progress {
  @include resD((
    margin-top: 58px,
    margin-left: 0,
  ));
  @include resM((
    margin-top: 0,
    margin-left: 32px,
  ))
}

.progressIndicator-mainContainer {
  box-sizing: border-box;
  display: flex;
  margin: auto;
  @include resD((
    position: sticky,
    top: 20px,
    flex-direction: column,
    justify-content: center,
    align-items: center,
    height: 100%,
    width: fit-content,
    padding: 0px 38px,
  ));
  @include resM((
    position: relative,
    top: 0,
    flex-direction: row,
    justify-content: space-around,
    align-items: flex-start,
    width: 100%,
    height: fit-content,
    padding: 20px 12px,
  ));
}

.progressIndicator-individual-progress {
  box-sizing: border-box;
  display: flex;
  position: relative;

  @include resD((
    flex-direction: column,
    justify-content: center,
    align-items: center,
  ));
  @include resM((
    flex-direction: row,
    justify-content: flex-start,
    align-items: flex-start,
  ));
}

.progressIndicator-progress-bar {
  position: absolute;
  background: #ddd;
  z-index: -1;

  @include resD((
    height: 100%,
    top: 0,
    width: 8px,
  ));
  @include resM((
    height: 8px,
    top: 50%,
    width: 100%,
  ));

  .progressIndicator-progress-bar-active {
    position: absolute;
    background: #00ADE6;
    z-index: 1;
    top: 0;

    @include resD((
      height: 0%,
      width: 8px,
    ));
    @include resM((
      width: 0%,
      height: 8px,
    ));
  }
}

.progressIndicator-icon-ProgressCircle {
  background: #eeeded;
  padding: 6px;
  border-radius: 100%;
  transform: scale(1);
  pointer-events: none;
  z-index: 9;
  transition: 0.1s all ease-in-out;
  @include resD((
    margin-bottom: 32px,
    margin-right: 0px,
  ));
  @include resM((
    margin-bottom: 0,
    margin-right: 28px,
  ));

  &:hover > .loOverlayMenu {
    opacity: 1;
    width: auto;
    visibility: visible;
  }
}

.progressIndicator-icon-locked {
  // cursor: no-drop !important;
  z-index: 9;
}

.progressIndicator-icon-ProgressCircle:nth-last-child(2) {
   @include resD((
    margin-bottom: 0,
  ));
  @include resM((
    margin-right: 0,
  ))
}

.progressIndicator-icon-container {
  background-color: #A8A7A7;
  padding: 10px;
  border-radius: 100%;
  border: 4px solid #FFF;
  z-index: 1;
}

.progressIndicator-icon {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  align-self: flex-start;
  transition: all .5s;

  @include resD((
    width: 24px,
    height: 24px,
  ));
  @include resM((
    width: 18px,
    height: 18px,
  ));
}

.progressIndicator-completed {
  background: linear-gradient(180deg, rgba(53, 228, 233, 1) -8.62%, rgba(0, 173, 230, 1) 109.2%);
  pointer-events: all;
  cursor: pointer;
  & > .progressIndicator-icon-container {
    background-color: #00ADE6;
  }
}

.progressIndicator-active {
  transform: scale(1.3);
  pointer-events: all;
  background: #bff4f8;

  & > .lo-component-cicle-stretched-rightArrow {
    background-color: #00ADE6 !important;
  }

  & > .progressIndicator-icon-container {
    background-color: #00ADE6;
  }
  }

.progressIndicator-active-completed {
  background: linear-gradient(100deg, rgba(53, 228, 233, 1) -8.62%, rgba(0, 173, 230, 1) 109.2%);
  & > .lo-component-cicle-stretched-rightArrow {
    background-color: #FFF !important;
  }
}

.overallProgressIndicator-success {
  background-color: #00ADE6 !important;
}

.loOverlayMenu {
  background: rgba(0, 173, 230, 0.1);
  border-radius: 8px 50px 50px 8px;
  width: 0%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  opacity: 0;
  visibility: hidden;
  padding: 0px 10px 0px 40px;
}

.updated-input-tooltip.cn-tooltip {
  background: #005773 !important;
  box-shadow: 0px 2px 4px rgba(65, 87, 102, 0.12), 0px 4px 10px rgba(65, 87, 102, 0.1);
  color: #FFF !important;
  padding: 8px 16px !important;
  opacity: 1 !important;
  text-align: center !important;
  font-weight: bold !important;
  @include resD((
    font-size: 18px !important,
    border-radius: 8px
  ));
}
.updated-input-tooltip.cn-sub-tooltip {
  background: #00ADE6 !important;
  white-space: nowrap;
  box-shadow: 0px 2px 4px rgba(65, 87, 102, 0.12), 0px 4px 10px rgba(65, 87, 102, 0.1) !important;
}
.lo-component-cicle-stretched {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0px;
  z-index: -10;
  height: 100%;
  transform: rotate(315deg);
  background: inherit;
  border-radius: 100%;
  border-bottom-right-radius: 40%;
}

.lo-component-cicle-stretched-rightArrow {
  -webkit-mask-image: url('../../assets/dropdownArrow.svg');
  mask-image: url('../../assets/dropdownArrow.svg');
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
  background-color: #FFF;
  width: 10px;
  height: 10px;
  transform: rotate(270deg);
  position: absolute;
  top: 40%;
  right: -6px;
}

.sidebar-loader-card {
  width: 100%;
  height: 100%;
  .sidebar-loader-1 {
    @include resD((
      y: 250px,
      x: 50px,
      height: 90px,
      width: 90px,
      rx: 100px,
    ));
  }
  .sidebar-loader-2 {
    @include resD((
      y: 360px,
      x: 50px,
      height: 90px,
      width: 90px,
      rx: 100px,
    ));
  }
  .sidebar-loader-3 {
    @include resD((
      y: 470px,
      x: 50px,
      height: 90px,
      width: 90px,
      rx: 100px,
    ));
  }
}

.sidebar-LoTitle {
  background: #005773;
  border-radius: 8px 8px 0px 0px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  color: #EEE;
  max-width: 50%;
  padding: 4px 12px;
  font-size: 8px;
  text-align: center;
  font-weight: bold;
}
