@import 'scss/size.scss';

.container{
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 98;
  background-color: rgba($color: #000000, $alpha: 0.7);
}

.modalContainer{
  // background-color: #fff;
  width: hs(550px);
  height: hs(285.1px);
  bottom: 38vh;
  left: (50vw - hs(275px));
  position: absolute;
  border-radius: hs(20px);
  display: flex;
  flex-direction: column;
  padding-left: hs(21.5px);
  padding-right: hs(21.5px);
  padding-bottom: hs(29.2px);
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../../../assets/submitOverlayBG.svg');
}

.modalContainer:focus{
  outline: none;
}

.submit {
  width: hs(400px);
  height: hs(29.5px);
  object-fit: contain;
  font-family: Nunito;
  font-size: hs(26px);
  float: left;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #1ac9e8;
  margin-top: hs(82px);
}

.confirmText{
  font-family: Nunito;
  font-size: hs(20px);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  margin-top: hs(18px);
}

.buttonArea{
  margin-top: hs(45px);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.cancelBtn{
  cursor: pointer;
  width: hs(188px);
  height: hs(59.7px);
  object-fit: contain;
  border-radius: hs(30px);
  border: solid 1px #1ac9e8;
  font-family: Nunito;
  font-size: hs(25px);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1ac9e8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitBtn{
  cursor: default;
  width: hs(188px);
  height: hs(59.7px);
  border-radius: hs(30px);
  background-image: linear-gradient(to bottom, #34e4ea, #00ade6);
  font-family: Nunito;
  font-size: hs(25px);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  &:hover {
    opacity: 0.7;
  }
}

.disabled {
  background-color: #d1d1d1 !important;
  background-image: none !important;
  &:hover {
    opacity: 1 !important;
  }
}

@media screen and (max-width: 1100px) {
  .modalContainer{
    background-size: contain;
    width: hsm(250px);
    height: 25vh;
    border-radius: 1.2vh;
    padding: 2%;
    left: 24%;
    bottom: 30%;
  }

  .submit {
    width: 70%;
    object-fit: contain;
    font-family: Nunito;
    font-size: 3vh;
    margin-top: 7vh;
  }

  .confirmText{
    font-size: 2.34vh;
    margin-top: 3.6vh;
  }

  .buttonArea{
    width: 100%;
  }

  .cancelBtn{
    width: 30%;
    height: 125%;
    border-radius: 3vh;
    font-size: 2.3vh;
    padding-bottom: 0.1vh;
  }

  .submitBtn{
    width: 30%;
    height: 125%;
    border-radius: 3vh;
    font-size: 2.3vh;
    padding-bottom: 0.1vh;
  }

  .disabled {
    background-color: #d1d1d1 !important;
    background-image: none !important;
    &:hover {
      opacity: 1 !important;
    }
  }
}

@media screen and (max-width: 350px) {
  .modalContainer {
    width: hsm(280px);
  }

  .buttonArea {
    margin-top: hsm(30px) !important;
  }
}

@media screen and (max-width: 500px) {
  .modalContainer {
    left: hsm(54px);
    bottom: hsm(400px);
  }

  .buttonArea {
    position: relative;
    left: hsm(-4px);
    margin-top: hsm(35px);
  }

  .submit {
    font-size: hsm(17px);
    margin-top: hsm(44px);
  }

  .confirmText {
    font-size: hsm(15px);
    margin-top: hsm(24px);
  }

  .submitBtn {
    font-size: hsm(14px);
  }

  .cancelBtn {
    font-size: hsm(14px);
  }
}

@media screen and (min-width: 460px) and (max-width: 500px) {
  .buttonArea {
    left: hsm(-20px);
  }
}

@media screen and (min-width: 350px) and (max-width: 500px) {
  .modalContainer {
    width: hsm(300px);
    bottom: hsm(300px);
  }
}

@media screen and (min-width: 460px) and (max-width: 480px) {
  .buttonArea {
    margin-top: hsm(30px);
  }
}

@media screen and (min-width: 480px) and (max-width: 500px) {
  .buttonArea {
    margin-top: hsm(25px);
  }
}

@media screen and (min-width: 501px) and (max-width: 540px) {
  .submit {
    font-size: hsm(15px);
  }

  .confirmText {
    font-size: hsm(12px);
  }

  .modalContainer {
    left: hsm(70px);
    bottom: hsm(250px);
  }

  .buttonArea {
    margin-top: hsm(28px);
  }
}

@media screen and (min-width: 541px) and (max-width: 600px) {
  .modalContainer {
    left: hsm(75px);
    bottom: hsm(230px);
  }

  .buttonArea {
    margin-top: hsm(25px);
    position: relative;
    left: hsm(-6px);
  }

  .submit {
    font-size: hsm(14px);
  }

  .confirmText {
    font-size: hsm(12px);
  }
}

@media screen and (min-width: 565px) and (max-width: 600px) {
  .modalContainer {
    left: hsm(80px);
  }

  .buttonArea {
    position: relative;
    left: hsm(-10px);
  }

  .submit {
    font-size: hsm(13px);
  }

  .confirmText {
    font-size: hsm(11px);
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .modalContainer {
    left: hsm(80px);
    bottom: hsm(180px);
  }

  .buttonArea {
    position: relative;
    left: hsm(-20px);
    margin-top: hsm(25px);
  }

  .submit {
    font-size: hsm(12px);
  }

  .confirmText {
    font-size: hsm(10px);
  }

  .submitBtn {
    width: hsm(60px);
  }

  .cancelBtn {
    width: hsm(60px);
  }
}

@media screen and (min-width: 650px) and (max-width: 700px) {
  .modalContainer {
    bottom: hsm(160px);
    left: hsm(95px);
  }

  .buttonArea {
    position: relative;
    left: hsm(-24px);
  }
}

@media screen and (min-width: 675px) and (max-width: 700px) {
  .buttonArea {
    left: hsm(-28px);
  }

  .submitBtn {
    width: hsm(55px);
  }

  .cancelBtn {
    width: hsm(55px);
  }
}

@media screen and (min-width: 700px) and (max-width: 800px) {
  .modalContainer {
    left: hsm(105px);
  }

  .buttonArea {
    margin-top: hsm(20px);
    left: hsm(-35px);
  }

  .submit {
    font-size: hsm(11px);
  }

  .confirmText {
    font-size: hsm(9px);
  }

  .submitBtn {
    width: hsm(50px);
  }

  .cancelBtn {
    width: hsm(50px);
  }
}

@media screen and (min-width: 750px) and (max-width: 800px) {
  .buttonArea {
    width: hsm(210px);
    left: hsm(-15px);
    margin-top: hsm(22px);
  }

  .submitBtn {
    width: hsm(55px);
  }

  .cancelBtn {
    width: hsm(55px);
  }

  .submit {
    font-size: hsm(10px);
  }

  .confirmText {
    margin-top: hsm(12px);
    font-size: hsm(8px);
  }
}

@media screen and (min-width: 766px) and (max-width: 769px) {
  .modalContainer {
    width: hsm(200px) !important;
    bottom: hsm(280px);
  }

  .submit {
    margin-top: hsm(30px) !important;
  }

  .submitBtn {
    width: hsm(65px) !important;
    font-size: hsm(9px) !important;
  }

  .cancelBtn {
    width: hsm(65px) !important;
    font-size: hsm(9px) !important;
  }

  .submit {
    font-size: hsm(10px) !important;
  }

  .confirmText {
    font-size: hsm(9px) !important;
  }

  .buttonArea {
    margin-top: hsm(25px) !important;
    width: hsm(230px) !important;
  }
}

@media screen and (min-width: 775px) and (max-width: 800px) {
  .modalContainer {
    bottom: hsm(150px);
  }

  .buttonArea {
    left: hsm(-20px);
  }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
  .modalContainer {
    left: hsm(110px);
    bottom: hsm(130px);
  }

  .buttonArea {
    width: hsm(190px);
    left: hsm(-12px);
    margin-top: hsm(22px);
  }

  .submitBtn {
    width: hsm(50px);
  }

  .cancelBtn {
    width: hsm(50px);
  }

  .submit {
    font-size: hsm(9px);
  }

  .confirmText {
    margin-top: hsm(11px);
    font-size: hsm(7px);
  }
}

@media screen and (min-width: 851px) and (max-width: 900px) {
  .buttonArea {
    left: hsm(-18px);
    margin-top: hsm(19px);
  }
}

@media screen and (min-width: 1020px) and (max-width: 1030px) {
  .modalContainer {
    width: 40%;
    bottom: 55%;
    left: 28% !important;
  }

  .buttonArea {
    margin-top: 12% !important;
    width: 100% !important;
  }

  .submit {
    margin-top: 4vh !important;
    font-size: 1.7vh;
  }

  .confirmText {
    margin-top: 1.5vh !important;
    font-size: 1.2vh;
  }

  .cancelBtn {
    height: 90%;
    font-size: 1.6vh;
    padding: 0.3vh 0 0.3vh 0;
  }

  .submitBtn {
    height: 90%;
    font-size: 1.6vh;
    padding: 0.3vh 0 0.3vh 0;
  }
}

@media screen and (min-width: 901px) and (max-width: 1100px) {
  .submitBtn {
    width: hsm(45px);
  }

  .cancelBtn {
    width: hsm(45px);
  }

  .submit {
    font-size: hsm(8px);
  }

  .confirmText {
    margin-top: hsm(10px);
  }

  .buttonArea {
    position: relative;
    margin-top: hsm(18px);
    left: hsm(-20px);
  }
}

@media screen and (min-width: 951px) and (max-width: 1000px) {
  .modalContainer {
    bottom: hsm(100px);
    left: hsm(130px);
  }

  .buttonArea {
    width: hsm(170px);
    left: hsm(-13px);
  }

  .submit {
    font-size: hsm(8px);
  }

  .confirmText {
    margin-top: hsm(8px);
    font-size: hsm(6.7px);
  }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .buttonArea {
    width: hsm(160px);
    left: hsm(-13px);
  }

  .submit {
    font-size: hsm(7.7px);
  }

  .confirmText {
    margin-top: hsm(8px);
    font-size: hsm(6.4px);
  }
}

@media screen and (min-width: 1023px) and (max-width: 1025px) {
  .buttonArea {
    width: hsm(200px) !important;
  }

  .submit {
    margin-top: hsm(25px) !important;
    font-size: hsm(8px) !important;
  }

  .confirmText {
    font-size: hsm(7px) !important;
    margin-top: hsm(10px) !important;
  }

  .submitBtn {
    width: hsm(50px);
    font-size: hsm(7px) !important;
  }

  .cancelBtn {
    width: hsm(50px);
    font-size: hsm(7px) !important;
  }
}

@media screen and (min-width: 1051px) and (max-width: 1100px) {
  .modalContainer {
    left: hsm(140px);
    bottom: hsm(100px);
  }

  .buttonArea {
    width: hsm(155px);
    left: hsm(-13px);
  }
}

@media screen and (min-width: 1101px) and (max-width: 1200px) {
  .modalContainer {
    bottom: hsm(100px);
    left: hsm(140px);
  }

  .submit {
    font-size: hsm(6.4px);
  }

  .confirmText {
    margin-top: hsm(7px);
    font-size: hsm(5.7px);
  }

  .submitBtn {
    width: hsm(35px);
    font-size: hsm(5px);
  }

  .cancelBtn {
    width: hsm(35px);
    font-size: hsm(5px);
  }
}
