@import 'scss/size.scss';
@import 'scss/utils.scss';

.badgeContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: hs(149.5px);
  height: hs(149.5px);
  position: relative;
}

.holderLayout{
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail{
  width: hs(106.8px);
  height: hs(106.8px);
  position: absolute;
  border-radius: 50%;
}
.badgeTextHolder{
  width: hs(124.4px);
  height: hs(33.1px);
  border-radius: hs(9px);
  border: solid hs(1px) #34e4ea;
  background-image: linear-gradient(to bottom, #00ade6, #2875e5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: hs(-10px);
}

.badgeText{
  font-family: Nunito;
  font-size: hs(17px);
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  // text-align: center;
  color: #ffffff;
}

.unlockStyle{
  cursor: pointer;
}