// primary colors

$cyan: #35E4E9;
$deep-sky-blue: #00ADE6;
$deep-purple: #8C61CB;
$theme: $deep-sky-blue;

// primary palette

$cyan-28: #1B7275;
$cyan-78: #9AF2F4;
$cyan-91: #D7FAFB;
$deep-sky-blue-24: #005773;
$deep-sky-blue-73: #80D6F3;
$deep-sky-blue-95: #E6F7FD;

// neutrals

$emperor: #403F3F;
$emperor-61: #504F4F;
$emperor-25: #504F4F;
$emperor-66: #A8A7A7;
$emperor-86: #DCDCDC;
$white: #ffffff;
$black: #000000;

// functional

$amethyst: #8C61CB;
$gorse: #F9E73F;
$pastel-green: #65DA7A;
$persian-green: #01AA93;
$tangerine: #FAAD14;
$tomato: #FF5744;
$valecia: #D34B57;

// landing-page
$swamp: #00171F;
$swamp-11: #012A38;

// gradients
$primary-gradient: linear-gradient(rgba(53, 228, 233, 1.0) 0%, rgba(0, 173, 230, 1.0) 100%);
