@import 'scss/size.scss';


body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',"Gilroy",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
  overflow-x: hidden;
}
html, body { height: 100%; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',Gilroy
    monospace;
}

@font-face{
    font-family: 'Nunito-Bold';
    src: local(Nunito-Bold),url('./assets/Nunito-Bold.ttf') format('truetype');
}
@font-face{
    font-family: 'Nunito-Bold';
    src: local(Gilroy-Light),url('./assets/Gilroy-Light.woff') format('truetype');
}
@font-face{
    font-family: 'Nunito-Bold';
    src: local(Gilroy-Bold),url('./assets/Gilroy-ExtraBold.woff') format('truetype');
}


@font-face {
    font-family: 'Nunito-SemiBold';
  src: local(Nunito-SemiBold),url('./assets/Nunito-SemiBold.ttf') format('truetype');
}
// @font-face {
//     font-family: 'Gilroy';
//   src: local(Nunito-SemiBold),url('./assets/Nunito-SemiBold.ttf') format('truetype');
// }

@font-face {
    font-family: 'Gilroy';
    src: url('./assets/Gilroy-Medium.ttf') format('woff2');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy-light';
    src: url('./assets/gilroy-light-webfont.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


// @font-face {
//   font-family: 'Gilroy';
// src: local(Gilroy-ExtraBold),url('./assets/Gilroy-ExtraBold.otf') format('truetype');
// }
// @font-face {
//   font-family: 'Gilroy-light';
// src: local(Gilroy-Light),url('./assets/Gilroy-Light.otf') format('truetype');
// }

::-webkit-scrollbar {
  max-width: hs(15px);
  @include resM((
    height: 5px
  ));
  @include resD((
    height:0
  ));
  display: flex;
}

::-webkit-scrollbar-thumb {
  background-color: #34e4ea;
  border-radius: hs(10px);
}

::-webkit-resizer {
  display: none;
}

// For PWA
@media all and (display-mode: standalone) {
  div, span, input, button {
    user-select: none !important;
    -webkit-tap-highlight-color: transparent !important;
  }
}
