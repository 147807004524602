@import '../../pages/TeacherApp/variables/_variables.scss';
.successToaster {
  border-radius: 8px !important;
  background-image: linear-gradient(to bottom, #01ddb2, #16d977);
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bold;
  font-size: 18px;
  min-width: 290px !important;
  min-height: 60px !important;
  margin: 8px;
}

.errorToaster {
  border-radius: 8px !important;
  background-image: linear-gradient(to bottom, #ff5644, #ff5644 0%, #b52c00);
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bold;
  font-size: 18px;
  min-width: 290px !important;
  min-height: 60px !important;
  margin: 8px;
}

.loadingToaster {
  border-radius: 8px !important;
  background-image: linear-gradient(to bottom, #00ade6 -90%, #34e4ea 50%);
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bold;
  font-size: 18px;
  min-width: 290px !important;
  min-height: 60px !important;
  margin: 8px;
}

.Toastify__close-button.Toastify__close-button--success, .Toastify__close-button.Toastify__close-button--error,
.Toastify__close-button.Toastify__close-button--loading {
  border-radius: 50% !important;
  height: 20px !important;
  width: 20px !important;
  border: 2px solid #fff !important;
  font-size: 11px !important;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bold !important;
  color: #fff;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  opacity: 1 !important;
}

.teacher-app-theme {
  background: $primary-color;
  border-radius: 8px !important;
  letter-spacing: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bold;
  font-size: 14px;
  min-width: 290px !important;
  min-height: 60px !important;
  margin: 8px;
}
