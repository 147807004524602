@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);
.test {
  color: red; }

.nProgressContainer {
  pointer-events: none; }

.nProgressWrapper {
  background: #29d;
  height: 2;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1031; }

.nProgressItem {
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  display: block;
  height: 100%;
  opacity: 1;
  position: absolute;
  right: 0;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
  width: 100px; }

:root {
  --accent-color:$tekie-amethyst;
  --accent-color-110: $tekie-amethyst-dark; }

.successToaster {
  border-radius: 8px !important;
  background-image: linear-gradient(to bottom, #01ddb2, #16d977);
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bold;
  font-size: 18px;
  min-width: 290px !important;
  min-height: 60px !important;
  margin: 8px; }

.errorToaster {
  border-radius: 8px !important;
  background-image: linear-gradient(to bottom, #ff5644, #ff5644 0%, #b52c00);
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bold;
  font-size: 18px;
  min-width: 290px !important;
  min-height: 60px !important;
  margin: 8px; }

.loadingToaster {
  border-radius: 8px !important;
  background-image: linear-gradient(to bottom, #00ade6 -90%, #34e4ea 50%);
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bold;
  font-size: 18px;
  min-width: 290px !important;
  min-height: 60px !important;
  margin: 8px; }

.Toastify__close-button.Toastify__close-button--success, .Toastify__close-button.Toastify__close-button--error,
.Toastify__close-button.Toastify__close-button--loading {
  border-radius: 50% !important;
  height: 20px !important;
  width: 20px !important;
  border: 2px solid #fff !important;
  font-size: 11px !important;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bold !important;
  color: #fff;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  opacity: 1 !important; }

.teacher-app-theme {
  background: $tekie-amethyst;
  background: var(--accent-color);
  border-radius: 8px !important;
  letter-spacing: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: bold;
  font-size: 14px;
  min-width: 290px !important;
  min-height: 60px !important;
  margin: 8px; }

.main_navbar_container {
  width: 100%;
  height: 4.58333vw;
  background: white;
  box-shadow: 0px 3px 10px #D4D5D6;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 99990;
  display: none; }
  @media (min-width: 1300px) {
    .main_navbar_container {
      display: flex; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_container {
      display: flex; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_container {
      display: flex; } }

.main_navbar_allottedMentor {
  color: #aaacae;
  font-weight: 500;
  display: block; }
  @media (min-width: 1300px) {
    .main_navbar_allottedMentor {
      margin-top: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_allottedMentor {
      margin-top: 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_allottedMentor {
      margin-top: 0.5787vw; } }

.main_navbar_session_hide_mobile {
  display: none; }
  @media (min-width: 1300px) {
    .main_navbar_session_hide_mobile {
      display: flex; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_session_hide_mobile {
      display: flex; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_session_hide_mobile {
      display: flex; } }

.main_navbar_tekieLogo {
  width: 6.14583vw;
  height: 3.38542vw;
  background-image: url(/f7d2a5f7f85540039186492b15df9701-444.png);
  background-size: contain;
  align-self: flex-start;
  margin-top: 1.19792vw;
  margin-left: 2.60417vw;
  margin-right: 3.125vw;
  background-repeat: no-repeat;
  cursor: pointer; }

.main_navbar_tekie_school_logo {
  width: 3.125vw;
  height: 3.125vw;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  border: 0.3125vw solid #00ADE6;
  margin-top: 0.10417vw;
  margin-left: 2.60417vw;
  margin-right: 3.125vw; }

.main_navbar_coin {
  width: 1.64583vw;
  height: 1.64583vw;
  border-radius: 50%; }

.main_navbar_creditsText {
  font-family: Nunito;
  font-size: 1.14583vw;
  margin-left: 0.52083vw;
  color: #fff;
  font-weight: bold; }

.main_navbar_navContainer {
  flex: 1 1;
  height: 100%;
  margin: 0 2.60417vw 0 2.60417vw;
  display: flex;
  align-items: center; }

.main_navbar_navItem {
  font-size: 1.51042vw;
  color: #aaacae;
  margin-right: 5.20833vw;
  font-weight: 700;
  text-decoration: none;
  transition: 0.4s all ease-in-out;
  text-transform: capitalize; }
  .main_navbar_navItem:hover {
    color: #00ade6; }

.main_navbar_profileIconContainer {
  display: flex;
  flex-direction: row;
  margin-right: 2.60417vw;
  border-radius: 1.35417vw;
  cursor: pointer;
  box-shadow: 0 0.15625vw 0.3125vw 0 rgba(0, 0, 0, 0.08);
  transition: 0.5s all ease-in-out; }

.main_navbar_profileIconContainerNoReferral {
  background: #e0fcfd; }
  .main_navbar_profileIconContainerNoReferral:hover {
    background: #d2fdff;
    box-shadow: 0 0.15625vw 0.3125vw 0 rgba(0, 0, 0, 0.08), 0 0 0 0.10417vw rgba(0, 173, 229, 0.4); }

.main_navbar_creditsContainer {
  height: 2.65625vw;
  border-radius: 1.35417vw 0 0 1.35417vw;
  background-image: linear-gradient(to bottom, #00ade6, #34e4ea);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.52083vw 0 0.36458vw; }

.main_navbar_profileIcon {
  height: 2.65625vw;
  border-radius: 0 1.35417vw 1.35417vw 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center; }

@media (min-width: 1300px) {
  .main_navbar_profileIconNoReferral {
    padding: 0 0.52083vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .main_navbar_profileIconNoReferral {
    padding: 0 0.5787vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .main_navbar_profileIconNoReferral {
    padding: 0 0.5787vw; } }

.main_navbar_profileWrapper {
  width: 1.64062vw;
  height: 1.86458vw;
  margin-right: 0.98958vw; }

.main_navbar_profileWrapperNoReferral {
  margin-right: 0px; }

.main_navbar_noMargin {
  margin-right: 0; }

.main_navbar_activeNav {
  color: #00ade6; }

.main_navbar_arrowUp {
  position: absolute;
  width: 0px;
  height: 0px;
  border-left: 1.08073vw solid transparent;
  border-right: 1.08073vw solid transparent;
  border-bottom: 0.79688vw solid #e0fcfd;
  top: 2.60417vw;
  right: 2.34375vw; }

.main_navbar_arrowUpNoReferral {
  right: 0.20833vw; }

.main_navbar_dropDownContainer {
  position: absolute;
  width: 14.58333vw;
  top: 3.40104vw;
  right: -0.52083vw;
  border-radius: 10px;
  z-index: 9999;
  overflow: hidden;
  background-color: #e0fcfd;
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08); }

.main_navbar_dropDownBG {
  position: absolute;
  width: 100%;
  margin: -12.5vw 0px 0px 0px;
  z-index: -1;
  top: 0px;
  left: 0px; }

.main_navbar_dropDownItemsLayout {
  display: flex;
  flex-direction: column;
  height: 100%; }

.main_navbar_dropDownRow {
  display: flex;
  flex: 1 1;
  padding-left: 1.13021vw;
  align-items: center;
  min-height: 3.95833vw;
  transition: 0.3s all ease-in-out;
  background: transparent; }
  .main_navbar_dropDownRow:hover {
    background: #fff; }
    .main_navbar_dropDownRow:hover .main_navbar_rowText {
      -webkit-transform: translateX(0.52083vw);
              transform: translateX(0.52083vw); }

.main_navbar_rowText {
  padding-left: 0.78125vw;
  font-family: Nunito-SemiBold;
  font-size: 1.14583vw;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  transition: 0.2s all ease-in-out;
  letter-spacing: normal;
  text-align: center;
  color: #004e64; }

.main_navbar_horizontalLine {
  border: 1px solid rgba(255, 255, 255, 0.5);
  width: 80%;
  border-radius: 50%;
  align-self: center; }

.main_navbar_settingsIcon {
  width: 1.17063vw;
  height: 1.17063vw;
  position: relative; }

.main_navbar_logoutIcon {
  width: 1.15432vw;
  height: 1.15432vw;
  position: relative; }

.main_navbar_link {
  text-decoration: none; }

.main_navbar_timerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 6.77083vw; }

@media screen and (max-width: 1000px) {
  .main_navbar_container {
    width: 100%;
    height: 13.04348vw;
    justify-content: space-between; }
  .main_navbar_navContainer {
    display: none !important; }
  .main_navbar_tekieLogo {
    width: 17.3913vw;
    height: 9.66184vw;
    margin-top: 2.89855vw;
    margin-left: 23%; }
  .main_navbar_tekie_school_logo {
    width: 14.49275vw;
    height: 14.49275vw;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    border: 1.44928vw solid #00ADE6;
    margin-top: 2.89855vw;
    margin-left: 20%; }
  .main_navbar_profileIconContainer {
    opacity: 0;
    pointer-events: none;
    margin-right: 3.86473vw;
    border-radius: 6.28019vw;
    cursor: pointer;
    box-shadow: 0 0.72464vw 1.44928vw 0 rgba(0, 0, 0, 0.16); }
  .main_navbar_creditsContainer {
    height: 8.9372vw;
    border-radius: 6.28019vw 0 0 6.28019vw;
    background-image: linear-gradient(to bottom, #00ade6, #34e4ea);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 2.41546vw 0 1.69082vw; }
  .main_navbar_coin {
    width: 4.90338vw;
    height: 4.90338vw;
    border-radius: 50%; }
  .main_navbar_profileIcon {
    height: 8.9372vw;
    border-radius: 0 6.28019vw 6.28019vw 0; }
  .main_navbar_creditsText {
    font-family: Nunito;
    font-size: 3.86473vw;
    margin-left: 1.44928vw; }
  .main_navbar_profileWrapper {
    width: 4.90338vw;
    height: 5.55556vw;
    margin-right: 3.1401vw; }
  .main_navbar_arrowUp {
    position: absolute;
    width: 0px;
    height: 0px;
    border-left: 3.5628vw solid transparent;
    border-right: 3.5628vw solid transparent;
    border-bottom: 3.69565vw solid #c4eff5;
    top: 9.17874vw;
    right: 2.34375vw; }
  .main_navbar_dropDownContainer {
    position: absolute;
    width: 32.6087vw;
    top: 13.04348vw;
    right: -1.93237vw;
    border-radius: 1.44928vw; }
  .main_navbar_settingsIcon {
    width: 4.94589vw;
    height: 4.70435vw;
    position: relative; }
  .main_navbar_logoutIcon {
    width: 4.62874vw;
    height: 4.62874vw;
    position: relative;
    right: -2px; }
  .main_navbar_dropDownRow {
    padding-left: 2.41546vw;
    min-height: 9.17874vw; }
  .main_navbar_rowText {
    padding-left: 1.93237vw;
    font-family: Nunito-Bold;
    font-size: 3.86473vw; }
  .main_navbar_horizontalLine {
    border: 0.5px solid #004e64;
    width: 90%; }
  .main_navbar_hamburgerWrapper {
    height: 60%;
    width: 8%;
    margin-left: 3%;
    margin-top: 2%;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(/static/media/Hamburger.77f934f0.svg); } }

@media screen and (min-width: 475px) and (max-width: 500px) {
  .main_navbar_container {
    height: 12.31884vw; }
  .main_navbar_profileWrapper {
    margin-right: 2.657vw !important; } }

@media screen and (min-width: 500px) and (max-width: 550px) {
  .main_navbar_container {
    height: 12.31884vw; }
  .main_navbar_profileWrapper {
    margin-right: 2.17391vw; }
  .main_navbar_hamburgerWrapper {
    width: 7%; } }

.main_navbar_savedCodeIcon {
  background-image: url(/static/media/All_code.f985c162.svg);
  width: 4.70435vw;
  height: 4.4628vw;
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

.main_navbar_studentCommunityIcon {
  background-image: url(/static/media/studentCommunityIcon.43f7781f.svg);
  width: 4.70435vw;
  height: 4.4628vw;
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

@media screen and (min-width: 550px) and (max-width: 600px) {
  .main_navbar_container {
    height: 12.07729vw; }
  .main_navbar_profileWrapper {
    margin-right: 2.41546vw; }
  .main_navbar_creditsText {
    font-size: 3.38164vw; }
  .main_navbar_hamburgerWrapper {
    width: 7%; } }

@media screen and (min-width: 600px) and (max-width: 700px) {
  .main_navbar_creditsText {
    font-size: 3.1401vw;
    margin-left: 1.20773vw; }
  .main_navbar_profileIcon {
    height: 7.72947vw; }
  .main_navbar_container {
    width: 100%;
    height: 11.83575vw; }
  .main_navbar_creditsContainer {
    height: 8.45411vw; }
  .main_navbar_tekieLogo {
    width: 15.94203vw;
    height: 8.69565vw;
    margin-top: 2.657vw;
    margin-left: 20%; }
  .main_navbar_tekie_school_logo {
    width: 14.49275vw;
    height: 14.49275vw;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    border: 1.44928vw solid #00ADE6;
    margin-top: 2.89855vw;
    margin-left: 20%; }
  .main_navbar_profileWrapper {
    width: 4.66184vw;
    height: 5.31401vw;
    margin-right: 3.1401vw; }
  .main_navbar_coin {
    width: 4.66184vw;
    height: 4.66184vw;
    border-radius: 50%; }
  .main_navbar_dropDownContainer {
    width: 31.40097vw; }
  .main_navbar_settingsIcon {
    width: 4.70435vw;
    height: 4.4628vw; }
  .main_navbar_logoutIcon {
    width: 4.3872vw;
    height: 4.3872vw; }
  .main_navbar_dropDownRow {
    padding-left: 1.93237vw;
    min-height: 8.69565vw; }
  .main_navbar_rowText {
    padding-left: 1.69082vw;
    font-family: Nunito-Bold;
    font-size: 3.62319vw; }
  .hamburgerWrapper main_navbar_ {
    width: 6.5%; }
  .main_navbar_savedCodeIcon {
    width: 4.70435vw;
    height: 4.4628vw; }
  .main_navbar_studentCommunityIcon {
    width: 4.70435vw;
    height: 4.4628vw; } }

@media screen and (min-width: 700px) and (max-width: 1000px) {
  main_navbar_.creditsText {
    font-size: 2.41546vw;
    margin-left: 0.96618vw; }
  .main_navbar_profileIcon {
    height: 6.76329vw; }
  .main_navbar_container {
    width: 100%;
    height: 9.42029vw; }
  .main_navbar_tekieLogo {
    width: 14.49275vw;
    height: 7.24638vw;
    margin-top: 1.93237vw;
    margin-left: 0%; }
  .main_navbar_tekie_school_logo {
    width: 14.49275vw;
    height: 14.49275vw;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    border: 1.44928vw solid #00ADE6;
    margin-top: 2.89855vw;
    margin-left: 20%; }
  .main_navbar_creditsContainer {
    height: 6.76329vw; }
  .main_navbar_profileWrapper {
    width: 3.69565vw;
    height: 4.10628vw;
    margin-right: 2.17391vw; }
  .main_navbar_coin {
    width: 3.69565vw;
    height: 3.69565vw; }
  .main_navbar_arrowUp {
    position: absolute;
    width: 0px;
    height: 0px;
    border-left: 2.83816vw solid transparent;
    border-right: 2.83816vw solid transparent;
    border-bottom: 2.97101vw solid #c4eff5;
    top: 7.24638vw;
    right: 2.34375vw; }
  .main_navbar_dropDownContainer {
    width: 26.57005vw;
    top: 10.38647vw; }
  .main_navbar_settingsIcon {
    width: 3.73816vw;
    height: 3.49662vw; }
  .main_navbar_logoutIcon {
    width: 3.42101vw;
    height: 3.42101vw; }
  .main_navbar_dropDownRow {
    padding-left: 1.69082vw;
    min-height: 7.24638vw; }
  .main_navbar_rowText {
    padding-left: 0.96618vw;
    font-family: Nunito-Bold;
    font-size: 2.89855vw; }
  .main_navbar_hamburgerWrapper {
    width: 6%;
    margin-top: 1.8%; }
  .main_navbar_savedCodeIcon {
    width: 4.70435vw;
    height: 4.4628vw; }
  .main_navbar_studentCommunityIcon {
    width: 4.70435vw;
    height: 4.4628vw; } }

@media screen and (min-width: 800px) and (max-width: 900px) {
  .main_navbar_creditsText {
    font-size: 2.657vw;
    margin-left: 0.96618vw; }
  .main_navbar_profileIcon {
    height: 7.48792vw; }
  .main_navbar_container {
    height: 10.14493vw; }
  .main_navbar_tekieLogo {
    width: 14.97585vw;
    height: 7.97101vw;
    margin-top: 1.93237vw;
    margin-left: 20%; }
  .main_navbar_tekie_school_logo {
    width: 14.49275vw;
    height: 14.49275vw;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    border: 1.44928vw solid #00ADE6;
    margin-top: 2.89855vw;
    margin-left: 20%; }
  .main_navbar_creditsContainer {
    height: 7.24638vw; }
  .main_navbar_profileWrapper {
    width: 4.17874vw;
    height: 4.58937vw;
    margin-right: 2.41546vw; }
  .main_navbar_coin {
    width: 4.17874vw;
    height: 4.17874vw; }
  .main_navbar_savedCodeIcon {
    width: 5.31401vw;
    height: 5.31401vw; }
  .main_navbar_studentCommunityIcon {
    width: 5.31401vw;
    height: 5.31401vw; } }

@media screen and (min-width: 900px) and (max-width: 1000px) {
  .main_navbar_creditsText {
    font-size: 2.41546vw;
    margin-left: 0.72464vw; }
  .main_navbar_profileIcon {
    height: 6.76329vw; }
  .main_navbar_container {
    height: 9.17874vw; }
  .main_navbar_tekieLogo {
    width: 14.00966vw;
    height: 7.24638vw;
    margin-top: 1.44928vw;
    margin-left: 20%; }
  .main_navbar_tekie_school_logo {
    width: 4.83092vw;
    height: 4.83092vw;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    border: 0.48309vw solid #00ADE6;
    margin-top: 0.24155vw;
    margin-left: unset; }
  .main_navbar_creditsContainer {
    height: 6.28019vw; }
  .main_navbar_profileWrapper {
    width: 3.69565vw;
    height: 3.86473vw;
    margin-right: 1.93237vw; }
  .main_navbar_coin {
    width: 3.69565vw;
    height: 3.69565vw; }
  .main_navbar_profileIconContainer {
    height: 6.28019vw; }
  .main_navbar_dropDownContainer {
    width: 24.15459vw; }
  .main_navbar_settingsIcon {
    width: 3.25507vw;
    height: 3.01353vw; }
  .main_navbar_logoutIcon {
    width: 2.93792vw;
    height: 2.93792vw; }
  .main_navbar_dropDownRow {
    padding-left: 1.69082vw;
    min-height: 6.28019vw; }
  .main_navbar_rowText {
    font-size: 2.41546vw; }
  .main_navbar_hamburgerWrapper {
    margin-top: 1.5%; }
  .main_navbar_savedCodeIcon {
    width: 5.31401vw;
    height: 5.31401vw; }
  .main_navbar_studentCommunityIcon {
    width: 5.31401vw;
    height: 5.31401vw; } }

@media screen and (min-width: 1000px) {
  .main_navbar_savedCodeIcon {
    width: 1.5vw;
    height: 4.34783vw; }
  .main_navbar_studentCommunityIcon {
    width: 1.5vw;
    height: 4.34783vw; } }

/* dropdown menu */
.main_navbar_menus {
  position: relative;
  cursor: pointer;
  transition: 0.3s all ease-in-out; }

.main_navbar_drop {
  box-shadow: 0 4px 5px #ccc;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  position: absolute;
  z-index: 25;
  box-sizing: border-box;
  color: black;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: rotateX(90deg);
          transform: rotateX(90deg);
  background: #FFFFFF;
  border-radius: 4px;
  transition: all 0.5s ease-in-out; }
  @media (min-width: 1300px) {
    .main_navbar_drop {
      top: 4.42708vw;
      width: 14.0625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_drop {
      top: 4.91898vw;
      width: 15.625vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_drop {
      top: 4.91898vw;
      width: 15.625vw; } }

.main_navbar_drop a {
  transition: 300ms all ease-in-out;
  text-decoration: none;
  display: block;
  position: relative;
  color: inherit; }

.main_navbar_drop::before {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border: 20px solid transparent;
  border-bottom: 25px solid #E6F7FD;
  left: 25px;
  top: -43px;
  z-index: 1; }

.main_navbar_drop div {
  display: block;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #504F4F;
  transition: 0.3s all ease-in-out; }
  @media (min-width: 1300px) {
    .main_navbar_drop div {
      font-size: 1.22396vw;
      padding: 0.78125vw;
      line-height: 1.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_drop div {
      font-size: 1.35995vw;
      padding: 0.86806vw;
      line-height: 1.62037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_drop div {
      font-size: 1.35995vw;
      padding: 0.86806vw;
      line-height: 1.62037vw; } }
  .main_navbar_drop div:hover {
    background-color: #E6F7FD;
    color: #00ADE6; }
    .main_navbar_drop div:hover > a {
      -webkit-transform: translateX(0.52083vw);
              transform: translateX(0.52083vw); }

.main_navbar_drop div.main_navbar_activeNav {
  background-color: #E6F7FD;
  color: #00ADE6 !important; }

.main_navbar_dropShow {
  z-index: 1;
  opacity: 1;
  visibility: visible;
  -webkit-transform: rotate(0);
          transform: rotate(0); }

.main_navbar_drop_course.main_navbar_dropShowCourse {
  z-index: 99999;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
          transform: translateY(0px); }

.main_navbar_drop_course {
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  z-index: 0;
  box-sizing: border-box;
  color: black;
  transition: all 0.3s ease-in-out;
  top: unset;
  width: unset;
  padding: unset;
  box-shadow: none;
  border: none;
  border-radius: u;
  max-height: unset;
  pointer-events: all;
  background: unset;
  position: relative;
  -webkit-transform: unset;
          transform: unset;
  visibility: visible;
  opacity: 1;
  overflow: visible; }
  @media (min-width: 1300px) {
    .main_navbar_drop_course {
      top: 4.01042vw;
      width: 35.78125vw;
      padding: 0.83333vw 1.25vw;
      box-shadow: 0vw 0.20833vw 1.04167vw rgba(0, 25, 34, 0.1);
      border: 0.05208vw solid #E0E0E0;
      border-radius: 0.20833vw;
      max-height: 70vh;
      pointer-events: none;
      background: #FFFFFF;
      position: absolute;
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
      visibility: hidden;
      opacity: 0;
      overflow: scroll; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_drop_course {
      top: 4.45602vw;
      width: 39.75694vw;
      padding: 0.92593vw 1.38889vw;
      box-shadow: 0vw 0.23148vw 1.15741vw rgba(0, 25, 34, 0.1);
      border: 0.05787vw solid #E0E0E0;
      border-radius: 0.23148vw;
      max-height: 70vh;
      pointer-events: none;
      background: #FFFFFF;
      position: absolute;
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
      visibility: hidden;
      opacity: 0;
      overflow: scroll; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_drop_course {
      top: 4.45602vw;
      width: 39.75694vw;
      padding: 0.92593vw 1.38889vw;
      box-shadow: 0vw 0.23148vw 1.15741vw rgba(0, 25, 34, 0.1);
      border: 0.05787vw solid #E0E0E0;
      border-radius: 0.23148vw;
      max-height: 70vh;
      pointer-events: none;
      background: #FFFFFF;
      position: absolute;
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
      visibility: hidden;
      opacity: 0;
      overflow: scroll; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .main_navbar_drop_course else {
      top: unset; }
    .main_navbar_drop_course else {
      width: unset; }
    .main_navbar_drop_course else {
      padding: unset; }
    .main_navbar_drop_course else {
      box-shadow: none; }
    .main_navbar_drop_course else {
      border: none; }
    .main_navbar_drop_course else {
      border-radius: u; }
    .main_navbar_drop_course else {
      max-height: unset; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .main_navbar_drop_course else {
      top: unset; }
    .main_navbar_drop_course else {
      width: unset; }
    .main_navbar_drop_course else {
      padding: unset; }
    .main_navbar_drop_course else {
      box-shadow: none; }
    .main_navbar_drop_course else {
      border: none; }
    .main_navbar_drop_course else {
      border-radius: u; }
    .main_navbar_drop_course else {
      max-height: unset; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .main_navbar_drop_course else {
      top: unset; }
    .main_navbar_drop_course else {
      width: unset; }
    .main_navbar_drop_course else {
      padding: unset; }
    .main_navbar_drop_course else {
      box-shadow: none; }
    .main_navbar_drop_course else {
      border: none; }
    .main_navbar_drop_course else {
      border-radius: u; }
    .main_navbar_drop_course else {
      max-height: unset; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .main_navbar_drop_course else {
      top: unset; }
    .main_navbar_drop_course else {
      width: unset; }
    .main_navbar_drop_course else {
      padding: unset; }
    .main_navbar_drop_course else {
      box-shadow: none; }
    .main_navbar_drop_course else {
      border: none; }
    .main_navbar_drop_course else {
      border-radius: u; }
    .main_navbar_drop_course else {
      max-height: unset; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .main_navbar_drop_course else {
      top: unset; }
    .main_navbar_drop_course else {
      width: unset; }
    .main_navbar_drop_course else {
      padding: unset; }
    .main_navbar_drop_course else {
      box-shadow: none; }
    .main_navbar_drop_course else {
      border: none; }
    .main_navbar_drop_course else {
      border-radius: u; }
    .main_navbar_drop_course else {
      max-height: unset; } }

.main_navbar_drop_course a {
  transition: 300ms all ease-in-out;
  text-decoration: none;
  display: block;
  position: relative;
  color: inherit; }

.main_navbar_drop_course > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #504F4F;
  transition: 0.3s all ease-in-out; }
  @media (min-width: 1300px) {
    .main_navbar_drop_course > div {
      padding: 0.83333vw 0vw;
      line-height: 1.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_drop_course > div {
      padding: 0.92593vw 0vw;
      line-height: 1.62037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_drop_course > div {
      padding: 0.92593vw 0vw;
      line-height: 1.62037vw; } }
  .main_navbar_drop_course > div:hover {
    color: #00ADE6; }
    .main_navbar_drop_course > div:hover > a {
      -webkit-transform: translateX(0.52083vw);
              transform: translateX(0.52083vw); }

.main_navbar_drop_course div.main_navbar_activeNav {
  background: rgba(230, 247, 253, 0.5);
  color: #00ADE6 !important; }
  @media (min-width: 1300px) {
    .main_navbar_drop_course div.main_navbar_activeNav {
      background: rgba(230, 247, 253, 0.3); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_drop_course div.main_navbar_activeNav {
      background: rgba(230, 247, 253, 0.3); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_drop_course div.main_navbar_activeNav {
      background: rgba(230, 247, 253, 0.3); } }

.main_navbar_thumbnail {
  width: 28.01932vw;
  height: 26.81159vw;
  margin-right: 3.86473vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }
  @media (min-width: 1300px) {
    .main_navbar_thumbnail {
      width: 6.04167vw;
      height: 5.78125vw;
      margin-right: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_thumbnail {
      width: 6.71296vw;
      height: 6.42361vw;
      margin-right: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_thumbnail {
      width: 6.71296vw;
      height: 6.42361vw;
      margin-right: 0.92593vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .main_navbar_thumbnail {
      width: 23.34944vw;
      height: 22.343vw;
      margin-right: 3.22061vw; }
      .main_navbar_thumbnail else {
        width: 116px; }
      .main_navbar_thumbnail else {
        height: 111px; }
      .main_navbar_thumbnail else {
        margin-right: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .main_navbar_thumbnail {
      width: 18.67955vw;
      height: 17.8744vw;
      margin-right: 2.57649vw; }
      .main_navbar_thumbnail else {
        width: 116px; }
      .main_navbar_thumbnail else {
        height: 111px; }
      .main_navbar_thumbnail else {
        margin-right: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .main_navbar_thumbnail {
      width: 16.48196vw;
      height: 15.77153vw;
      margin-right: 2.27337vw; }
      .main_navbar_thumbnail else {
        width: 116px; }
      .main_navbar_thumbnail else {
        height: 111px; }
      .main_navbar_thumbnail else {
        margin-right: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .main_navbar_thumbnail {
      width: 14.74701vw;
      height: 14.11137vw;
      margin-right: 2.03407vw; }
      .main_navbar_thumbnail else {
        width: 116px; }
      .main_navbar_thumbnail else {
        height: 111px; }
      .main_navbar_thumbnail else {
        margin-right: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .main_navbar_thumbnail {
      width: 13.34254vw;
      height: 12.76743vw;
      margin-right: 1.84035vw; }
      .main_navbar_thumbnail else {
        width: 116px; }
      .main_navbar_thumbnail else {
        height: 111px; }
      .main_navbar_thumbnail else {
        margin-right: 16px; } }

.main_navbar_drop_course div.main_navbar_course_title {
  color: #504F4F;
  display: block !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 4.34783vw;
  width: 48.30918vw;
  font-weight: bold; }
  @media (min-width: 1300px) {
    .main_navbar_drop_course div.main_navbar_course_title {
      font-size: 1.35417vw;
      width: 21.875vw;
      font-weight: bold; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_drop_course div.main_navbar_course_title {
      font-size: 1.50463vw;
      width: 24.30556vw;
      font-weight: bold; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_drop_course div.main_navbar_course_title {
      font-size: 1.50463vw;
      width: 24.30556vw;
      font-weight: bold; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .main_navbar_drop_course div.main_navbar_course_title {
      font-size: 3.62319vw;
      width: 40.25765vw; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        font-size: 18px; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        width: 200px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .main_navbar_drop_course div.main_navbar_course_title {
      font-size: 2.89855vw;
      width: 32.20612vw; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        font-size: 18px; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        width: 200px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .main_navbar_drop_course div.main_navbar_course_title {
      font-size: 2.55754vw;
      width: 28.41716vw; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        font-size: 18px; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        width: 200px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .main_navbar_drop_course div.main_navbar_course_title {
      font-size: 2.28833vw;
      width: 25.42588vw; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        font-size: 18px; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        width: 200px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .main_navbar_drop_course div.main_navbar_course_title {
      font-size: 2.07039vw;
      width: 23.00437vw; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        font-size: 18px; }
      .main_navbar_drop_course div.main_navbar_course_title else {
        width: 200px; } }

@media (min-width: 1300px) {
  .main_navbar_noThumbnailText {
    width: 28.64583vw !important; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .main_navbar_noThumbnailText {
    width: 31.8287vw !important; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .main_navbar_noThumbnailText {
    width: 31.8287vw !important; } }

.main_navbar_drop_course div.main_navbar_activeNav div.main_navbar_course_title {
  color: #00ADE6; }

.main_navbar_course_arrow {
  width: 3.38164vw;
  height: 6.03865vw;
  margin-right: 3.38164vw; }
  @media (min-width: 1300px) {
    .main_navbar_course_arrow {
      width: 0.72917vw;
      height: 1.30208vw;
      margin-right: 0.72917vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_course_arrow {
      width: 0.81019vw;
      height: 1.44676vw;
      margin-right: 0.81019vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_course_arrow {
      width: 0.81019vw;
      height: 1.44676vw;
      margin-right: 0.81019vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .main_navbar_course_arrow {
      width: 2.81804vw;
      height: 5.03221vw;
      margin-right: 2.81804vw; }
      .main_navbar_course_arrow else {
        width: 14px; }
      .main_navbar_course_arrow else {
        height: 25px; }
      .main_navbar_course_arrow else {
        margin-right: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .main_navbar_course_arrow {
      width: 2.25443vw;
      height: 4.02576vw;
      margin-right: 2.25443vw; }
      .main_navbar_course_arrow else {
        width: 14px; }
      .main_navbar_course_arrow else {
        height: 25px; }
      .main_navbar_course_arrow else {
        margin-right: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .main_navbar_course_arrow {
      width: 1.9892vw;
      height: 3.55215vw;
      margin-right: 1.9892vw; }
      .main_navbar_course_arrow else {
        width: 14px; }
      .main_navbar_course_arrow else {
        height: 25px; }
      .main_navbar_course_arrow else {
        margin-right: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .main_navbar_course_arrow {
      width: 1.77981vw;
      height: 3.17824vw;
      margin-right: 1.77981vw; }
      .main_navbar_course_arrow else {
        width: 14px; }
      .main_navbar_course_arrow else {
        height: 25px; }
      .main_navbar_course_arrow else {
        margin-right: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .main_navbar_course_arrow {
      width: 1.61031vw;
      height: 2.87555vw;
      margin-right: 1.61031vw; }
      .main_navbar_course_arrow else {
        width: 14px; }
      .main_navbar_course_arrow else {
        height: 25px; }
      .main_navbar_course_arrow else {
        margin-right: 14px; } }
  .main_navbar_course_arrow path {
    stroke: #DCDCDC;
    transition: 0.3s all ease-in-out; }

.main_navbar_course_arrow.active path {
  stroke: #00ADE6; }

.main_navbar_hrLineCourse {
  opacity: 0.2;
  height: 0px !important;
  padding: 0px !important;
  margin-bottom: 2.41546vw;
  margin-top: 2.41546vw;
  border-top: 0.8px solid #000000; }
  @media (min-width: 1300px) {
    .main_navbar_hrLineCourse {
      margin-bottom: 0.83333vw;
      margin-top: 0.83333vw;
      border-top: 0.8px solid #000000; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_hrLineCourse {
      margin-bottom: 0.92593vw;
      margin-top: 0.92593vw;
      border-top: 0.8px solid #000000; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_hrLineCourse {
      margin-bottom: 0.92593vw;
      margin-top: 0.92593vw;
      border-top: 0.8px solid #000000; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .main_navbar_hrLineCourse {
      margin-bottom: 2.01288vw;
      margin-top: 2.01288vw; }
      .main_navbar_hrLineCourse else {
        margin-bottom: 10px; }
      .main_navbar_hrLineCourse else {
        margin-top: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .main_navbar_hrLineCourse {
      margin-bottom: 1.61031vw;
      margin-top: 1.61031vw; }
      .main_navbar_hrLineCourse else {
        margin-bottom: 10px; }
      .main_navbar_hrLineCourse else {
        margin-top: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .main_navbar_hrLineCourse {
      margin-bottom: 1.42086vw;
      margin-top: 1.42086vw; }
      .main_navbar_hrLineCourse else {
        margin-bottom: 10px; }
      .main_navbar_hrLineCourse else {
        margin-top: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .main_navbar_hrLineCourse {
      margin-bottom: 1.27129vw;
      margin-top: 1.27129vw; }
      .main_navbar_hrLineCourse else {
        margin-bottom: 10px; }
      .main_navbar_hrLineCourse else {
        margin-top: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .main_navbar_hrLineCourse {
      margin-bottom: 1.15022vw;
      margin-top: 1.15022vw; }
      .main_navbar_hrLineCourse else {
        margin-bottom: 10px; }
      .main_navbar_hrLineCourse else {
        margin-top: 10px; } }

.main_navbar_active_drop_item:hover .main_navbar_course_title {
  color: #00ADE6; }

.main_navbar_active_drop_item:hover .main_navbar_course_arrow path {
  stroke: #00ADE6; }

.main_navbar_up_next {
  display: flex;
  align-items: center;
  color: rgba(80, 79, 79, 0.5);
  font-size: 2.89855vw;
  margin-top: 1.44928vw; }
  @media (min-width: 1300px) {
    .main_navbar_up_next {
      font-size: 0.625vw;
      margin-top: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_up_next {
      font-size: 0.69444vw;
      margin-top: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_up_next {
      font-size: 0.69444vw;
      margin-top: 0.92593vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .main_navbar_up_next {
      font-size: 2.41546vw;
      margin-top: 1.20773vw; }
      .main_navbar_up_next else {
        font-size: 12px; }
      .main_navbar_up_next else {
        margin-top: 6px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .main_navbar_up_next {
      font-size: 1.93237vw;
      margin-top: 0.96618vw; }
      .main_navbar_up_next else {
        font-size: 12px; }
      .main_navbar_up_next else {
        margin-top: 6px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .main_navbar_up_next {
      font-size: 1.70503vw;
      margin-top: 0.85251vw; }
      .main_navbar_up_next else {
        font-size: 12px; }
      .main_navbar_up_next else {
        margin-top: 6px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .main_navbar_up_next {
      font-size: 1.52555vw;
      margin-top: 0.76278vw; }
      .main_navbar_up_next else {
        font-size: 12px; }
      .main_navbar_up_next else {
        margin-top: 6px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .main_navbar_up_next {
      font-size: 1.38026vw;
      margin-top: 0.69013vw; }
      .main_navbar_up_next else {
        font-size: 12px; }
      .main_navbar_up_next else {
        margin-top: 6px; } }

.main_navbar_up_next_arrow {
  position: relative;
  width: 2.89855vw;
  height: 2.89855vw;
  margin-left: 0.96618vw;
  top: -0.96618vw; }
  @media (min-width: 1300px) {
    .main_navbar_up_next_arrow {
      width: 0.83333vw;
      height: 0.83333vw;
      margin-left: 0.3125vw;
      top: -0.20833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_up_next_arrow {
      width: 0.92593vw;
      height: 0.92593vw;
      margin-left: 0.34722vw;
      top: -0.23148vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_up_next_arrow {
      width: 0.92593vw;
      height: 0.92593vw;
      margin-left: 0.34722vw;
      top: -0.23148vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .main_navbar_up_next_arrow {
      width: 2.41546vw;
      height: 2.41546vw;
      margin-left: 0.80515vw;
      top: -0.80515vw; }
      .main_navbar_up_next_arrow else {
        width: 12px; }
      .main_navbar_up_next_arrow else {
        height: 12px; }
      .main_navbar_up_next_arrow else {
        margin-left: 4px; }
      .main_navbar_up_next_arrow else {
        top: -4px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .main_navbar_up_next_arrow {
      width: 1.93237vw;
      height: 1.93237vw;
      margin-left: 0.64412vw;
      top: -0.64412vw; }
      .main_navbar_up_next_arrow else {
        width: 12px; }
      .main_navbar_up_next_arrow else {
        height: 12px; }
      .main_navbar_up_next_arrow else {
        margin-left: 4px; }
      .main_navbar_up_next_arrow else {
        top: -4px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .main_navbar_up_next_arrow {
      width: 1.70503vw;
      height: 1.70503vw;
      margin-left: 0.56834vw;
      top: -0.56834vw; }
      .main_navbar_up_next_arrow else {
        width: 12px; }
      .main_navbar_up_next_arrow else {
        height: 12px; }
      .main_navbar_up_next_arrow else {
        margin-left: 4px; }
      .main_navbar_up_next_arrow else {
        top: -4px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .main_navbar_up_next_arrow {
      width: 1.52555vw;
      height: 1.52555vw;
      margin-left: 0.50852vw;
      top: -0.50852vw; }
      .main_navbar_up_next_arrow else {
        width: 12px; }
      .main_navbar_up_next_arrow else {
        height: 12px; }
      .main_navbar_up_next_arrow else {
        margin-left: 4px; }
      .main_navbar_up_next_arrow else {
        top: -4px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .main_navbar_up_next_arrow {
      width: 1.38026vw;
      height: 1.38026vw;
      margin-left: 0.46009vw;
      top: -0.46009vw; }
      .main_navbar_up_next_arrow else {
        width: 12px; }
      .main_navbar_up_next_arrow else {
        height: 12px; }
      .main_navbar_up_next_arrow else {
        margin-left: 4px; }
      .main_navbar_up_next_arrow else {
        top: -4px; } }

.main_navbar_topic_title {
  color: #504F4F;
  font-size: 3.86473vw;
  margin-top: 0.48309vw;
  font-weight: 600;
  letter-spacing: -0.02em; }
  @media (min-width: 1300px) {
    .main_navbar_topic_title {
      font-size: 1.04167vw;
      margin-top: 0.15625vw;
      font-weight: bold; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_topic_title {
      font-size: 1.15741vw;
      margin-top: 0.17361vw;
      font-weight: bold; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_topic_title {
      font-size: 1.15741vw;
      margin-top: 0.17361vw;
      font-weight: bold; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .main_navbar_topic_title {
      font-size: 3.22061vw;
      margin-top: 0.40258vw; }
      .main_navbar_topic_title else {
        font-size: 16px; }
      .main_navbar_topic_title else {
        margin-top: 2px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .main_navbar_topic_title {
      font-size: 2.57649vw;
      margin-top: 0.32206vw; }
      .main_navbar_topic_title else {
        font-size: 16px; }
      .main_navbar_topic_title else {
        margin-top: 2px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .main_navbar_topic_title {
      font-size: 2.27337vw;
      margin-top: 0.28417vw; }
      .main_navbar_topic_title else {
        font-size: 16px; }
      .main_navbar_topic_title else {
        margin-top: 2px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .main_navbar_topic_title {
      font-size: 2.03407vw;
      margin-top: 0.25426vw; }
      .main_navbar_topic_title else {
        font-size: 16px; }
      .main_navbar_topic_title else {
        margin-top: 2px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .main_navbar_topic_title {
      font-size: 1.84035vw;
      margin-top: 0.23004vw; }
      .main_navbar_topic_title else {
        font-size: 16px; }
      .main_navbar_topic_title else {
        margin-top: 2px; } }

.main_navbar_course_completed_tag {
  text-transform: uppercase;
  width: -webkit-min-content;
  width: min-content;
  font-weight: bold;
  letter-spacing: 0.03em;
  border-radius: 0.96618vw;
  padding: 0.96618vw 1.20773vw;
  font-size: 2.89855vw;
  margin-top: 1.93237vw;
  background: rgba(101, 218, 122, 0.1);
  color: #65DA7A; }
  @media (min-width: 1300px) {
    .main_navbar_course_completed_tag {
      border-radius: 0.20833vw;
      padding: 0.20833vw 0.26042vw;
      font-size: 0.625vw;
      margin-top: 0.41667vw;
      background: rgba(66, 232, 209, 0.2);
      color: rgba(1, 170, 147, 0.5); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .main_navbar_course_completed_tag {
      border-radius: 0.23148vw;
      padding: 0.23148vw 0.28935vw;
      font-size: 0.69444vw;
      margin-top: 0.46296vw;
      background: rgba(66, 232, 209, 0.2);
      color: rgba(1, 170, 147, 0.5); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .main_navbar_course_completed_tag {
      border-radius: 0.23148vw;
      padding: 0.23148vw 0.28935vw;
      font-size: 0.69444vw;
      margin-top: 0.46296vw;
      background: rgba(66, 232, 209, 0.2);
      color: rgba(1, 170, 147, 0.5); } }
  @media (min-width: 450px) and (max-width: 500px) {
    .main_navbar_course_completed_tag {
      border-radius: 0.80515vw;
      padding: 0.80515vw 1.00644vw;
      font-size: 2.41546vw;
      margin-top: 1.61031vw; }
      .main_navbar_course_completed_tag else {
        border-radius: 4px; }
      .main_navbar_course_completed_tag else {
        font-size: 12px; }
      .main_navbar_course_completed_tag else {
        margin-top: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .main_navbar_course_completed_tag {
      border-radius: 0.64412vw;
      padding: 0.64412vw 0.80515vw;
      font-size: 1.93237vw;
      margin-top: 1.28824vw; }
      .main_navbar_course_completed_tag else {
        border-radius: 4px; }
      .main_navbar_course_completed_tag else {
        font-size: 12px; }
      .main_navbar_course_completed_tag else {
        margin-top: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .main_navbar_course_completed_tag {
      border-radius: 0.56834vw;
      padding: 0.56834vw 0.71043vw;
      font-size: 1.70503vw;
      margin-top: 1.13669vw; }
      .main_navbar_course_completed_tag else {
        border-radius: 4px; }
      .main_navbar_course_completed_tag else {
        font-size: 12px; }
      .main_navbar_course_completed_tag else {
        margin-top: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .main_navbar_course_completed_tag {
      border-radius: 0.50852vw;
      padding: 0.50852vw 0.63565vw;
      font-size: 1.52555vw;
      margin-top: 1.01704vw; }
      .main_navbar_course_completed_tag else {
        border-radius: 4px; }
      .main_navbar_course_completed_tag else {
        font-size: 12px; }
      .main_navbar_course_completed_tag else {
        margin-top: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .main_navbar_course_completed_tag {
      border-radius: 0.46009vw;
      padding: 0.46009vw 0.57511vw;
      font-size: 1.38026vw;
      margin-top: 0.92017vw; }
      .main_navbar_course_completed_tag else {
        border-radius: 4px; }
      .main_navbar_course_completed_tag else {
        font-size: 12px; }
      .main_navbar_course_completed_tag else {
        margin-top: 8px; } }

.side-navbar-container {
  position: relative;
  width: 18.33333vw;
  height: 100%;
  background-image: linear-gradient(to bottom, #c9fcfd, #34e4ea);
  height: calc(100vh - 4.58333vw);
  overflow: overlay;
  display: none; }
  .side-navbar-container::-webkit-scrollbar {
    max-width: 0.41667vw;
    display: flex; }
  @media (min-width: 1300px) {
    .side-navbar-container {
      display: block; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .side-navbar-container {
      display: block; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .side-navbar-container {
      display: block; } }

.side-navbar-navBarCloudsContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 12.34375vw;
  pointer-events: none; }

.side-navbar-topicTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3.48958vw;
  margin-bottom: 1.97917vw;
  cursor: pointer; }

.side-navbar-topicTitle {
  font-size: 1.35417vw;
  color: #0B535F;
  font-weight: bold; }

.side-navbar-backArrow {
  margin-left: 1.04167vw;
  margin-right: 0.9375vw;
  width: 0.54688vw;
  height: 0.9375vw; }

.side-navbar-backIcon {
  position: relative;
  top: -0.17318vw; }

.side-navbar-logoutButton {
  position: absolute;
  bottom: 4.32812vw;
  left: 4.01042vw; }

.side-navbar-settingsSidebar {
  padding-top: 4.16667vw; }

.side-navbar-buttonContainer {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 4.32812vw; }

.side-navbar-disabled {
  opacity: 0.85; }

.side-navbar-emptyDiv {
  width: 100%;
  height: 3.54167vw; }

.SideNavItem_container__JpfIc, .SideNavItem_childContainer__1vkVD {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 3.54167vw;
  position: relative;
  z-index: 1;
  cursor: pointer;
  transition: 0s border ease-in-out 0.3s, 0.3s all ease-in-out;
  border-bottom: 1px solid #ffffff;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.SideNavItem_childHover__2-WDd:hover {
  background: #abffff;
  padding-left: 0.15625vw; }

.SideNavItem_noClick__23vy7 {
  cursor: not-allowed; }

.SideNavItem_parentHover__UNo88:hover {
  background: rgba(255, 255, 255, 0.4);
  padding-left: 0.15625vw; }

.SideNavItem_active__1AgwG {
  background-color: white; }

.SideNavItem_childContainer__1vkVD {
  border-bottom: none;
  transition: 0.3s color ease-in-out, 0.3s height ease-in-out, 0.3s background ease-in-out, 0.3s padding ease-in-out;
  background-color: #dcfafa;
  overflow: hidden; }

.SideNavItem_active__1AgwG {
  background-color: white; }

.SideNavItem_noPointer__2TgNJ {
  cursor: auto; }

.SideNavItem_space__3BIUW {
  justify-content: space-around; }

.SideNavItem_statusContainer__EI4_7 {
  width: 1.30208vw;
  height: 1.30208vw;
  margin-left: 2.5vw;
  margin-right: 1.04167vw; }

.SideNavItem_statusContainerChild__2SNQG {
  margin-left: 4.58333vw; }

.SideNavItem_title__8Lfan {
  font-size: 1.04167vw;
  font-weight: bold;
  color: #504f4f;
  flex: 1 1; }

.SideNavItem_dropdownContainer__f_Acv {
  width: 0.5vw;
  height: 0.85417vw;
  margin-right: 0.9375vw;
  transition: 0.3s all ease-in-out;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }

.SideNavItem_dropdownContainerOpen__34rg9 {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.SideNavItem_dropdownArrow__3GMN- {
  position: relative;
  top: -0.26042vw; }

.SideNavItem_noBorder__qSX4C {
  border: none;
  border-bottom: none; }

.SideNavItem_settingsLeftPadding__1vf6y {
  padding-left: 2.34375vw; }

.NextButton_iconContainer__n5q4G {
  position: relative;
  width: 11.3px;
  height: 11.6px;
  top: -2px;
  left: 2px; }

.NextButton_tickIconContainer__1vF4F {
  position: relative;
  width: 0.74479vw;
  height: 0.8125vw;
  top: -0.18229vw;
  left: -0.26042vw; }

.NextButton_saveIconContainer__3ogPY {
  position: relative;
  width: 16.3px;
  height: 16.6px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .NextButton_saveIconContainer__3ogPY svg {
    fill: #1ac9e8 !important;
    height: 20px;
    width: 18px;
    top: -2px;
    position: relative; }

.ActionButton_buttonContainer__28SJf {
  border-radius: 1.45833vw;
  display: flex;
  align-items: center;
  flex-direction: row;
  pointer-events: auto;
  cursor: pointer;
  background-image: linear-gradient(to top, #00ade6, #34e4ea);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin: 2.41546vw auto;
  height: 46.875vw;
  width: auto; }
  @media (min-width: 1300px) {
    .ActionButton_buttonContainer__28SJf {
      margin: unset;
      height: 2.8125vw;
      width: -webkit-max-content;
      width: max-content; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ActionButton_buttonContainer__28SJf {
      margin: unset;
      height: 2.8125vw;
      width: -webkit-max-content;
      width: max-content; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ActionButton_buttonContainer__28SJf {
      margin: unset;
      height: 2.8125vw;
      width: -webkit-max-content;
      width: max-content; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .ActionButton_buttonContainer__28SJf {
      margin: 2.01288vw auto; }
      .ActionButton_buttonContainer__28SJf else {
        height: 46.875vw; }
      .ActionButton_buttonContainer__28SJf else {
        width: auto; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ActionButton_buttonContainer__28SJf {
      margin: 1.61031vw auto; }
      .ActionButton_buttonContainer__28SJf else {
        height: 46.875vw; }
      .ActionButton_buttonContainer__28SJf else {
        width: auto; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ActionButton_buttonContainer__28SJf {
      margin: 1.42086vw auto; }
      .ActionButton_buttonContainer__28SJf else {
        height: 46.875vw; }
      .ActionButton_buttonContainer__28SJf else {
        width: auto; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ActionButton_buttonContainer__28SJf {
      margin: 1.27129vw auto; }
      .ActionButton_buttonContainer__28SJf else {
        height: 46.875vw; }
      .ActionButton_buttonContainer__28SJf else {
        width: auto; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ActionButton_buttonContainer__28SJf {
      margin: 1.15022vw auto; }
      .ActionButton_buttonContainer__28SJf else {
        height: 46.875vw; }
      .ActionButton_buttonContainer__28SJf else {
        width: auto; } }

.ActionButton_buttonContainerPadding__2z3KY {
  padding: 0.05208vw 0.52083vw 0.05208vw 0; }

.ActionButton_smallButtonContainer__17C6W {
  height: 1.875vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 1.45833vw; }

.ActionButton_smallButtonText__r0sfe {
  height: 100%;
  font-size: 0.83333vw;
  font-weight: bold;
  margin: 0 0.98958vw 0 0.98958vw;
  display: flex;
  align-items: center; }

.ActionButton_buttonText__LE-_9 {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
  font-weight: bold;
  color: #fff;
  margin-right: 0.625vw;
  font-size: 4.44444vw;
  margin-left: 0; }
  @media (min-width: 1300px) {
    .ActionButton_buttonText__LE-_9 {
      font-size: 1.19792vw;
      margin-left: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ActionButton_buttonText__LE-_9 {
      font-size: 1.19792vw;
      margin-left: 1.25vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ActionButton_buttonText__LE-_9 {
      font-size: 1.19792vw;
      margin-left: 1.25vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .ActionButton_buttonText__LE-_9 {
      font-size: 3.7037vw; }
      .ActionButton_buttonText__LE-_9 else {
        font-size: 16px; }
      .ActionButton_buttonText__LE-_9 else {
        margin-left: 0; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ActionButton_buttonText__LE-_9 {
      font-size: 2.96296vw; }
      .ActionButton_buttonText__LE-_9 else {
        font-size: 16px; }
      .ActionButton_buttonText__LE-_9 else {
        margin-left: 0; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ActionButton_buttonText__LE-_9 {
      font-size: 2.61438vw; }
      .ActionButton_buttonText__LE-_9 else {
        font-size: 16px; }
      .ActionButton_buttonText__LE-_9 else {
        margin-left: 0; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ActionButton_buttonText__LE-_9 {
      font-size: 2.33918vw; }
      .ActionButton_buttonText__LE-_9 else {
        font-size: 16px; }
      .ActionButton_buttonText__LE-_9 else {
        margin-left: 0; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ActionButton_buttonText__LE-_9 {
      font-size: 2.1164vw; }
      .ActionButton_buttonText__LE-_9 else {
        font-size: 16px; }
      .ActionButton_buttonText__LE-_9 else {
        margin-left: 0; } }

.ActionButton_chooseProductButtonContainer__3dLs3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #27d6e9;
  background-color: #ffffff;
  height: 5.7971vw;
  border-radius: 3.38164vw; }
  @media (min-width: 450px) and (max-width: 500px) {
    .ActionButton_chooseProductButtonContainer__3dLs3 {
      height: 4.83092vw;
      border-radius: 2.81804vw; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        height: 24px; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        border-radius: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ActionButton_chooseProductButtonContainer__3dLs3 {
      height: 3.86473vw;
      border-radius: 2.25443vw; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        height: 24px; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        border-radius: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ActionButton_chooseProductButtonContainer__3dLs3 {
      height: 3.41006vw;
      border-radius: 1.9892vw; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        height: 24px; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        border-radius: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ActionButton_chooseProductButtonContainer__3dLs3 {
      height: 3.05111vw;
      border-radius: 1.77981vw; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        height: 24px; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        border-radius: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ActionButton_chooseProductButtonContainer__3dLs3 {
      height: 2.76052vw;
      border-radius: 1.61031vw; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        height: 24px; }
      .ActionButton_chooseProductButtonContainer__3dLs3 else {
        border-radius: 14px; } }
  @media (min-width: 1300px) {
    .ActionButton_chooseProductButtonContainer__3dLs3 {
      height: 1.875vw;
      border-radius: 1.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ActionButton_chooseProductButtonContainer__3dLs3 {
      height: 2.08333vw;
      border-radius: 1.62037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ActionButton_chooseProductButtonContainer__3dLs3 {
      height: 2.08333vw;
      border-radius: 1.62037vw; } }

.ActionButton_active__1ZfeY {
  background-color: #cbf7fc;
  color: #2c505f; }

.ActionButton_disabled__3zq5Z {
  background-color: #d1d1d1 !important;
  background-image: none !important;
  cursor: default !important;
  pointer-events: none;
  color: #fff; }

.ActionButton_activeProductButton__1LOWf {
  background-image: linear-gradient(to bottom, #00ade6, #34e4ea); }

.ActionButton_disabledProductButton__2a1j_ {
  background-color: #ffffff !important;
  background-image: none !important;
  cursor: default !important; }

.ActionButton_activeProductButtonText__AOaEN {
  color: #ffffff !important; }

.ActionButton_disabledProductButtonText__YogIU {
  color: #27d6e9 !important; }

.ActionButton_chooseProductButtonText__wf7AD {
  height: 100%;
  font-weight: bold;
  margin: 0 0.98958vw 0 0.98958vw;
  display: flex;
  align-items: center;
  color: #27d6e9;
  font-size: 2.89855vw; }
  @media (min-width: 450px) and (max-width: 500px) {
    .ActionButton_chooseProductButtonText__wf7AD {
      font-size: 2.41546vw; }
      .ActionButton_chooseProductButtonText__wf7AD else {
        font-size: 12px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ActionButton_chooseProductButtonText__wf7AD {
      font-size: 1.93237vw; }
      .ActionButton_chooseProductButtonText__wf7AD else {
        font-size: 12px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ActionButton_chooseProductButtonText__wf7AD {
      font-size: 1.70503vw; }
      .ActionButton_chooseProductButtonText__wf7AD else {
        font-size: 12px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ActionButton_chooseProductButtonText__wf7AD {
      font-size: 1.52555vw; }
      .ActionButton_chooseProductButtonText__wf7AD else {
        font-size: 12px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ActionButton_chooseProductButtonText__wf7AD {
      font-size: 1.38026vw; }
      .ActionButton_chooseProductButtonText__wf7AD else {
        font-size: 12px; } }
  @media (min-width: 1300px) {
    .ActionButton_chooseProductButtonText__wf7AD {
      font-size: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ActionButton_chooseProductButtonText__wf7AD {
      font-size: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ActionButton_chooseProductButtonText__wf7AD {
      font-size: 0.92593vw; } }

.ActionButton_iconCircle__1eCrT {
  background-color: white;
  box-shadow: 0 3px 6px 0 #34e4ea;
  border-radius: 50%;
  margin-right: 0.36458vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 5.72917vw;
  height: 5.72917vw;
  margin-left: 1.20773vw; }
  @media (min-width: 450px) and (max-width: 500px) {
    .ActionButton_iconCircle__1eCrT {
      margin-left: 1.00644vw; }
      .ActionButton_iconCircle__1eCrT else {
        width: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        height: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        margin-left: 5px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .ActionButton_iconCircle__1eCrT {
      margin-left: 0.80515vw; }
      .ActionButton_iconCircle__1eCrT else {
        width: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        height: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        margin-left: 5px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .ActionButton_iconCircle__1eCrT {
      margin-left: 0.71043vw; }
      .ActionButton_iconCircle__1eCrT else {
        width: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        height: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        margin-left: 5px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .ActionButton_iconCircle__1eCrT {
      margin-left: 0.63565vw; }
      .ActionButton_iconCircle__1eCrT else {
        width: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        height: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        margin-left: 5px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .ActionButton_iconCircle__1eCrT {
      margin-left: 0.57511vw; }
      .ActionButton_iconCircle__1eCrT else {
        width: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        height: 5.72917vw; }
      .ActionButton_iconCircle__1eCrT else {
        margin-left: 5px; } }
  @media (min-width: 1300px) {
    .ActionButton_iconCircle__1eCrT {
      width: 2.08333vw;
      height: 2.08333vw;
      margin-left: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .ActionButton_iconCircle__1eCrT {
      width: 2.08333vw;
      height: 2.08333vw;
      margin-left: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .ActionButton_iconCircle__1eCrT {
      width: 2.08333vw;
      height: 2.08333vw;
      margin-left: unset; } }

.ActionButton_hoverButtonContainer__2knR-:hover {
  cursor: pointer !important;
  opacity: 0.93; }

.ActionButton_defaultHover__bkkWM {
  cursor: default !important; }

@media screen and (max-width: 1199px) {
  .ActionButton_buttonContainer__28SJf {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 8px 16px;
    height: 24px;
    background: linear-gradient(180deg, #35E4E9 -8.62%, #00ADE6 109.2%);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    width: auto; } }
  @media screen and (max-width: 1199px) and (min-width: 1300px) {
    .ActionButton_buttonContainer__28SJf {
      width: 5.83333vw; } }
  @media screen and (max-width: 1199px) and (min-width: 900px) and (max-width: 1000px) {
    .ActionButton_buttonContainer__28SJf {
      width: 6.48148vw; } }
  @media screen and (max-width: 1199px) and (min-width: 1000px) and (max-width: 1300px) {
    .ActionButton_buttonContainer__28SJf {
      width: 6.48148vw; } }
  @media screen and (max-width: 1199px) and (min-width: 450px) and (max-width: 500px) {
    .ActionButton_buttonContainer__28SJf else {
      width: auto; } }
  @media screen and (max-width: 1199px) and (min-width: 500px) and (max-width: 600px) {
    .ActionButton_buttonContainer__28SJf else {
      width: auto; } }
  @media screen and (max-width: 1199px) and (min-width: 600px) and (max-width: 700px) {
    .ActionButton_buttonContainer__28SJf else {
      width: auto; } }
  @media screen and (max-width: 1199px) and (min-width: 700px) and (max-width: 800px) {
    .ActionButton_buttonContainer__28SJf else {
      width: auto; } }
  @media screen and (max-width: 1199px) and (min-width: 800px) and (max-width: 899px) {
    .ActionButton_buttonContainer__28SJf else {
      width: auto; } }

@media screen and (max-width: 1199px) {
  .ActionButton_centerAlignedButtonText__LJLZ0 {
    font-size: 3.38164vw; }
  .ActionButton_smallButtonContainer__17C6W {
    height: 5.7971vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 6.76329vw; }
  .ActionButton_smallButtonText__r0sfe {
    height: 100%;
    font-size: 2.89855vw;
    font-weight: bold;
    margin: 0 2.41546vw 0 2.41546vw;
    display: flex;
    align-items: center; } }

@media screen and (min-width: 500px) and (max-width: 600px) {
  .ActionButton_buttonContainer__28SJf {
    height: 5.31401vw;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: space-between; }
  .ActionButton_centerAlignedButtonText__LJLZ0 {
    font-size: 2.89855vw; }
  .ActionButton_smallButtonContainer__17C6W {
    height: 4.83092vw; }
  .ActionButton_smallButtonText__r0sfe {
    font-size: 2.41546vw; } }

@media screen and (min-width: 600px) and (max-width: 700px) {
  .ActionButton_buttonContainer__28SJf {
    height: 4.10628vw;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: center; }
  .ActionButton_centerAlignedButtonText__LJLZ0 {
    font-size: 2.41546vw; }
  .ActionButton_smallButtonContainer__17C6W {
    height: 4.58937vw;
    border-radius: 7.24638vw; }
  .ActionButton_smallButtonText__r0sfe {
    font-size: 2.17391vw; } }

@media screen and (min-width: 700px) and (max-width: 800px) {
  .ActionButton_buttonContainer__28SJf {
    height: 3.64583vw;
    width: 100px;
    justify-content: center; }
  .ActionButton_centerAlignedButtonText__LJLZ0 {
    font-size: 2.17391vw; }
  .ActionButton_smallButtonContainer__17C6W {
    height: 4.10628vw;
    border-radius: 7.24638vw; }
  .ActionButton_smallButtonText__r0sfe {
    font-size: 1.93237vw; } }

@media screen and (min-width: 800px) and (max-width: 1199px) {
  .ActionButton_buttonContainer__28SJf {
    height: 3.90625vw;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: center; }
  .ActionButton_centerAlignedButtonText__LJLZ0 {
    font-size: 1.93237vw; }
  .ActionButton_smallButtonContainer__17C6W {
    height: 3.38164vw;
    border-radius: 7.24638vw; }
  .ActionButton_smallButtonText__r0sfe {
    font-size: 1.44928vw; } }

@media screen and (min-width: 900px) and (max-width: 1199px) {
  .ActionButton_buttonContainer__28SJf {
    height: 1.97917vw;
    padding: 0.72917vw 0.83333vw 0.625vw 0.83333vw; }
  .ActionButton_buttonText__LE-_9 {
    font-size: 1.25vw; }
  .ActionButton_smallButtonText__r0sfe {
    margin: 0 1.5625vw 0 1.5625vw; } }

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .ActionButton_smallButtonContainer__17C6W {
    height: 3.1401vw; }
  .ActionButton_centerAlignedButtonText__LJLZ0 {
    font-size: 1.69082vw; }
  .ActionButton_smallButtonText__r0sfe {
    font-size: 1.3285vw; } }

@media screen and (min-width: 1100px) and (max-width: 1199px) {
  .ActionButton_smallButtonContainer__17C6W {
    height: 2.89855vw; }
  .ActionButton_centerAlignedButtonText__LJLZ0 {
    font-size: 1.44928vw; } }

.ActionButton_mbButtonContainer__1A_KF {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px 8px 16px;
  width: 112px;
  background: linear-gradient(270deg, #35E4E9 -8.62%, #00ADE6 109.2%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  margin: 0; }

.ActionButton_noMarginTop__2uU7m {
  margin-top: 0px !important; }

.ActionButton_mbButtonText__2svuT {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  margin-left: 1px; }

.ActionButton_mbIconCircle__1Ya1C {
  width: 19.5px;
  height: 19.5px; }

@media screen {
  .SimpleButtonLoader_loader__3ihip {
    border: 0.15625vw solid #fff;
    background-image: linear-gradient(to bottom, #00ade6, #34e4ea);
    border-radius: 50%;
    border-top: 0.15625vw solid #bfbfbf;
    width: 1.04167vw;
    height: 1.04167vw;
    -webkit-animation: SimpleButtonLoader_spin__2WlVY 2s linear infinite;
    /* Safari */
    animation: SimpleButtonLoader_spin__2WlVY 2s linear infinite;
    position: relative;
    left: -0.52083vw; }
  .SimpleButtonLoader_loader__3ihip.SimpleButtonLoader_noGradient__1aJ34 {
    background-image: none; }
  .SimpleButtonLoader_loaderRightPositioned__2o54_ {
    left: 0.41667vw !important; }
  .SimpleButtonLoader_visible__18Z93 {
    display: block; }
  .SimpleButtonLoader_hidden__2x6CR {
    display: none; }
  @-webkit-keyframes SimpleButtonLoader_spin__2WlVY {
    0% {
      -webkit-transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg); } }
  @keyframes SimpleButtonLoader_spin__2WlVY {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } } }

@media screen and (max-width: 900px) {
  .SimpleButtonLoader_loader__3ihip {
    border: 0.48309vw solid #fff;
    border-top: 0.48309vw solid #bfbfbf;
    width: 2.41546vw;
    height: 2.41546vw; } }

@media screen and (min-width: 500px) and (max-width: 900px) {
  .SimpleButtonLoader_loader__3ihip {
    width: 1.93237vw;
    height: 1.93237vw; } }

@media screen and (min-width: 600px) and (max-width: 900px) {
  .SimpleButtonLoader_loader__3ihip {
    border: 0.36232vw solid #fff;
    border-top: 0.36232vw solid #bfbfbf;
    height: 1.69082vw;
    width: 1.69082vw; } }

@media screen and (min-width: 700px) and (max-width: 900px) {
  .SimpleButtonLoader_loader__3ihip {
    width: 1.44928vw;
    height: 1.44928vw; } }

@media screen and (min-width: 800px) and (max-width: 900px) {
  .SimpleButtonLoader_loader__3ihip {
    border: 0.28986vw solid #fff;
    border-top: 0.28986vw solid #bfbfbf; } }

.SubmitOverlayMenu_container__3nGIb {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 98;
  background-color: rgba(0, 0, 0, 0.7); }

.SubmitOverlayMenu_modalContainer__kwCVt {
  width: 28.64583vw;
  height: 14.84896vw;
  bottom: 38vh;
  left: 35.67708vw;
  position: absolute;
  border-radius: 1.04167vw;
  display: flex;
  flex-direction: column;
  padding-left: 1.11979vw;
  padding-right: 1.11979vw;
  padding-bottom: 1.52083vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/static/media/submitOverlayBG.1a8924da.svg); }

.SubmitOverlayMenu_modalContainer__kwCVt:focus {
  outline: none; }

.SubmitOverlayMenu_submit__34eiG {
  width: 20.83333vw;
  height: 1.53646vw;
  object-fit: contain;
  font-family: Nunito;
  font-size: 1.35417vw;
  float: left;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #1ac9e8;
  margin-top: 4.27083vw; }

.SubmitOverlayMenu_confirmText__398MI {
  font-family: Nunito;
  font-size: 1.04167vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  margin-top: 0.9375vw; }

.SubmitOverlayMenu_buttonArea__vBW7L {
  margin-top: 2.34375vw;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.SubmitOverlayMenu_cancelBtn__3yEs1 {
  cursor: pointer;
  width: 9.79167vw;
  height: 3.10938vw;
  object-fit: contain;
  border-radius: 1.5625vw;
  border: solid 1px #1ac9e8;
  font-family: Nunito;
  font-size: 1.30208vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1ac9e8;
  display: flex;
  justify-content: center;
  align-items: center; }

.SubmitOverlayMenu_submitBtn__1zWc3 {
  cursor: default;
  width: 9.79167vw;
  height: 3.10938vw;
  border-radius: 1.5625vw;
  background-image: linear-gradient(to bottom, #34e4ea, #00ade6);
  font-family: Nunito;
  font-size: 1.30208vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row; }
  .SubmitOverlayMenu_submitBtn__1zWc3:hover {
    opacity: 0.7; }

.SubmitOverlayMenu_disabled__1VT_5 {
  background-color: #d1d1d1 !important;
  background-image: none !important; }
  .SubmitOverlayMenu_disabled__1VT_5:hover {
    opacity: 1 !important; }

@media screen and (max-width: 1100px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    background-size: contain;
    width: 60.38647vw;
    height: 25vh;
    border-radius: 1.2vh;
    padding: 2%;
    left: 24%;
    bottom: 30%; }
  .SubmitOverlayMenu_submit__34eiG {
    width: 70%;
    object-fit: contain;
    font-family: Nunito;
    font-size: 3vh;
    margin-top: 7vh; }
  .SubmitOverlayMenu_confirmText__398MI {
    font-size: 2.34vh;
    margin-top: 3.6vh; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    width: 100%; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 30%;
    height: 125%;
    border-radius: 3vh;
    font-size: 2.3vh;
    padding-bottom: 0.1vh; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 30%;
    height: 125%;
    border-radius: 3vh;
    font-size: 2.3vh;
    padding-bottom: 0.1vh; }
  .SubmitOverlayMenu_disabled__1VT_5 {
    background-color: #d1d1d1 !important;
    background-image: none !important; }
    .SubmitOverlayMenu_disabled__1VT_5:hover {
      opacity: 1 !important; } }

@media screen and (max-width: 350px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    width: 67.63285vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    margin-top: 7.24638vw !important; } }

@media screen and (max-width: 500px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    left: 13.04348vw;
    bottom: 96.61836vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    position: relative;
    left: -0.96618vw;
    margin-top: 8.45411vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 4.10628vw;
    margin-top: 10.62802vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    font-size: 3.62319vw;
    margin-top: 5.7971vw; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    font-size: 3.38164vw; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    font-size: 3.38164vw; } }

@media screen and (min-width: 460px) and (max-width: 500px) {
  .SubmitOverlayMenu_buttonArea__vBW7L {
    left: -4.83092vw; } }

@media screen and (min-width: 350px) and (max-width: 500px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    width: 72.46377vw;
    bottom: 72.46377vw; } }

@media screen and (min-width: 460px) and (max-width: 480px) {
  .SubmitOverlayMenu_buttonArea__vBW7L {
    margin-top: 7.24638vw; } }

@media screen and (min-width: 480px) and (max-width: 500px) {
  .SubmitOverlayMenu_buttonArea__vBW7L {
    margin-top: 6.03865vw; } }

@media screen and (min-width: 501px) and (max-width: 540px) {
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 3.62319vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    font-size: 2.89855vw; }
  .SubmitOverlayMenu_modalContainer__kwCVt {
    left: 16.90821vw;
    bottom: 60.38647vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    margin-top: 6.76329vw; } }

@media screen and (min-width: 541px) and (max-width: 600px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    left: 18.11594vw;
    bottom: 55.55556vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    margin-top: 6.03865vw;
    position: relative;
    left: -1.44928vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 3.38164vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    font-size: 2.89855vw; } }

@media screen and (min-width: 565px) and (max-width: 600px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    left: 19.32367vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    position: relative;
    left: -2.41546vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 3.1401vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    font-size: 2.657vw; } }

@media screen and (min-width: 600px) and (max-width: 900px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    left: 19.32367vw;
    bottom: 43.47826vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    position: relative;
    left: -4.83092vw;
    margin-top: 6.03865vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 2.89855vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    font-size: 2.41546vw; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 14.49275vw; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 14.49275vw; } }

@media screen and (min-width: 650px) and (max-width: 700px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    bottom: 38.64734vw;
    left: 22.94686vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    position: relative;
    left: -5.7971vw; } }

@media screen and (min-width: 675px) and (max-width: 700px) {
  .SubmitOverlayMenu_buttonArea__vBW7L {
    left: -6.76329vw; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 13.28502vw; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 13.28502vw; } }

@media screen and (min-width: 700px) and (max-width: 800px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    left: 25.36232vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    margin-top: 4.83092vw;
    left: -8.45411vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 2.657vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    font-size: 2.17391vw; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 12.07729vw; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 12.07729vw; } }

@media screen and (min-width: 750px) and (max-width: 800px) {
  .SubmitOverlayMenu_buttonArea__vBW7L {
    width: 50.72464vw;
    left: -3.62319vw;
    margin-top: 5.31401vw; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 13.28502vw; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 13.28502vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 2.41546vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    margin-top: 2.89855vw;
    font-size: 1.93237vw; } }

@media screen and (min-width: 766px) and (max-width: 769px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    width: 48.30918vw !important;
    bottom: 67.63285vw; }
  .SubmitOverlayMenu_submit__34eiG {
    margin-top: 7.24638vw !important; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 15.70048vw !important;
    font-size: 2.17391vw !important; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 15.70048vw !important;
    font-size: 2.17391vw !important; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 2.41546vw !important; }
  .SubmitOverlayMenu_confirmText__398MI {
    font-size: 2.17391vw !important; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    margin-top: 6.03865vw !important;
    width: 55.55556vw !important; } }

@media screen and (min-width: 775px) and (max-width: 800px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    bottom: 36.23188vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    left: -4.83092vw; } }

@media screen and (min-width: 801px) and (max-width: 1000px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    left: 26.57005vw;
    bottom: 31.40097vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    width: 45.89372vw;
    left: -2.89855vw;
    margin-top: 5.31401vw; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 12.07729vw; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 12.07729vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 2.17391vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    margin-top: 2.657vw;
    font-size: 1.69082vw; } }

@media screen and (min-width: 851px) and (max-width: 900px) {
  .SubmitOverlayMenu_buttonArea__vBW7L {
    left: -4.34783vw;
    margin-top: 4.58937vw; } }

@media screen and (min-width: 1020px) and (max-width: 1030px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    width: 40%;
    bottom: 55%;
    left: 28% !important; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    margin-top: 12% !important;
    width: 100% !important; }
  .SubmitOverlayMenu_submit__34eiG {
    margin-top: 4vh !important;
    font-size: 1.7vh; }
  .SubmitOverlayMenu_confirmText__398MI {
    margin-top: 1.5vh !important;
    font-size: 1.2vh; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    height: 90%;
    font-size: 1.6vh;
    padding: 0.3vh 0 0.3vh 0; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    height: 90%;
    font-size: 1.6vh;
    padding: 0.3vh 0 0.3vh 0; } }

@media screen and (min-width: 901px) and (max-width: 1100px) {
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 10.86957vw; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 10.86957vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 1.93237vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    margin-top: 2.41546vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    position: relative;
    margin-top: 4.34783vw;
    left: -4.83092vw; } }

@media screen and (min-width: 951px) and (max-width: 1000px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    bottom: 24.15459vw;
    left: 31.40097vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    width: 41.0628vw;
    left: -3.1401vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 1.93237vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    margin-top: 1.93237vw;
    font-size: 1.61836vw; } }

@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .SubmitOverlayMenu_buttonArea__vBW7L {
    width: 38.64734vw;
    left: -3.1401vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 1.8599vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    margin-top: 1.93237vw;
    font-size: 1.54589vw; } }

@media screen and (min-width: 1023px) and (max-width: 1025px) {
  .SubmitOverlayMenu_buttonArea__vBW7L {
    width: 48.30918vw !important; }
  .SubmitOverlayMenu_submit__34eiG {
    margin-top: 6.03865vw !important;
    font-size: 1.93237vw !important; }
  .SubmitOverlayMenu_confirmText__398MI {
    font-size: 1.69082vw !important;
    margin-top: 2.41546vw !important; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 12.07729vw;
    font-size: 1.69082vw !important; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 12.07729vw;
    font-size: 1.69082vw !important; } }

@media screen and (min-width: 1051px) and (max-width: 1100px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    left: 33.81643vw;
    bottom: 24.15459vw; }
  .SubmitOverlayMenu_buttonArea__vBW7L {
    width: 37.43961vw;
    left: -3.1401vw; } }

@media screen and (min-width: 1101px) and (max-width: 1200px) {
  .SubmitOverlayMenu_modalContainer__kwCVt {
    bottom: 24.15459vw;
    left: 33.81643vw; }
  .SubmitOverlayMenu_submit__34eiG {
    font-size: 1.54589vw; }
  .SubmitOverlayMenu_confirmText__398MI {
    margin-top: 1.69082vw;
    font-size: 1.37681vw; }
  .SubmitOverlayMenu_submitBtn__1zWc3 {
    width: 8.45411vw;
    font-size: 1.20773vw; }
  .SubmitOverlayMenu_cancelBtn__3yEs1 {
    width: 8.45411vw;
    font-size: 1.20773vw; } }

.styles_fullScreenWrapper__2TY5D {
  width: 100%;
  height: calc(100vh - 4.58333vw);
  background-color: rgba(0, 0, 0, 0.27);
  position: absolute;
  left: 0px;
  top: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center; }

.styles_modalContainer__BsPkR {
  width: 28.59375vw;
  height: 87.96296vh;
  border-radius: 0.78125vw;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.06);
  background-image: linear-gradient(to bottom, #00ade5, #34e0ea);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; }

.styles_badgeModalBG__f7h_n {
  width: 100%;
  height: 100%;
  position: absolute; }

.styles_congratsBanner__148CD {
  width: 41.93519vh;
  margin-top: 7.44444vh; }

.styles_unlockText__1ijW9 {
  font-family: Nunito;
  font-size: 1.25vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  margin-top: 8.2963vh;
  margin-bottom: 4.93519vh; }

.styles_badgeContainer__12P6v {
  width: 10.21875vw;
  height: 18.16667vh; }

.styles_thumbnailStyle__p_HES {
  width: 13.59259vh;
  height: 13.59259vh; }

.styles_badgeTextHolder__3dZMZ {
  width: 15.83333vh;
  height: 4.2037vh;
  border-radius: 0.83333vh; }

.styles_badgeText__1zqz0 {
  font-size: 1.19792vw;
  line-height: 1.35; }

.styles_badgeDescription__2T0Qv {
  margin-top: 10.30556vh;
  font-family: Nunito;
  font-size: 0.9375vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  flex: 1 1; }

.styles_nextButton__3TOcP {
  margin-top: 1.5625vw; }

.styles_badgeContainer__2jf7_ {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 7.78646vw;
  height: 7.78646vw;
  position: relative; }

.styles_holderLayout__2CzcW {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }

.styles_thumbnail__1x_ac {
  width: 5.5625vw;
  height: 5.5625vw;
  position: absolute;
  border-radius: 50%; }

.styles_badgeTextHolder__3OLQ8 {
  width: 6.47917vw;
  height: 1.72396vw;
  border-radius: 0.46875vw;
  border: solid 0.05208vw #34e4ea;
  background-image: linear-gradient(to bottom, #00ade6, #2875e5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -0.52083vw; }

.styles_badgeText__22LFe {
  font-family: Nunito;
  font-size: 0.88542vw;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #ffffff; }

.styles_unlockStyle__25gwq {
  cursor: pointer; }

.styles_buttonContainer__2Kf6H {
  position: relative;
  width: 7.61979vw;
  height: 2.85937vw;
  border-radius: 59px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-image: linear-gradient(to bottom, #34e4ea, #00ade6);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 3.125vw; }

.styles_imageBG__2YxGh {
  width: 100%;
  position: absolute; }

.styles_btnText__1pMzb {
  font-family: Nunito;
  font-size: 0.9375vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #ffffff;
  z-index: 1; }

.blockBased-side-navbar-container {
  height: calc(100vh - 4.58333vw);
  z-index: 999;
  pointer-events: none;
  width: 0vw;
  opacity: 0;
  visibility: hidden; }
  @media (min-width: 1300px) {
    .blockBased-side-navbar-container {
      width: 10.41667vw;
      opacity: 1;
      visibility: visible; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .blockBased-side-navbar-container {
      width: 10.41667vw;
      opacity: 1;
      visibility: visible; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .blockBased-side-navbar-container {
      width: 10.41667vw;
      opacity: 1;
      visibility: visible; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .blockBased-side-navbar-container else {
      width: 0vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .blockBased-side-navbar-container else {
      width: 0vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .blockBased-side-navbar-container else {
      width: 0vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .blockBased-side-navbar-container else {
      width: 0vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .blockBased-side-navbar-container else {
      width: 0vw; } }
  .blockBased-side-navbar-container::-webkit-scrollbar {
    max-width: 0.41667vw;
    display: flex; }

.additionalRenderer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box; }

.navbar {
  overflow: hidden;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  padding-left: 12px;
  padding-right: 12px;
  z-index: 998;
  box-sizing: border-box; }

.flashContainer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4); }

.nav-menu {
  background-color: white;
  background-image: url(/663d9a5593e21f23b2360a273412085a-274.png);
  background-position: bottom;
  background-repeat: no-repeat;
  width: 270px;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  z-index: 999;
  left: -100%;
  transition: 850ms;
  display: flex;
  flex-direction: column; }

.nav-menu-items {
  width: 100%;
  margin-top: 20px; }

.nav-menu.active {
  left: 0;
  transition: 350ms; }

.arrow-back {
  height: 30px;
  width: 30px; }

.navbar-toggle {
  background-color: #060b26;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center; }

.updatedSidebar-heading {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: #00ADE6;
  margin-left: 15px; }

.hamburger-icon {
  width: 20px;
  height: 3px;
  background-color: #333333;
  margin-bottom: 3px;
  border-radius: 10px; }
  .hamburger-icon:nth-child(1) {
    margin-top: 3px; }

.front-bar {
  display: flex;
  flex-direction: row;
  align-items: center; }

.sidebar-top {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  align-items: center;
  margin-top: 10px;
  text-decoration: none; }

.blockBased-side-navbar-bgOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  z-index: -1;
  background: white; }

.sidebar-top-heading {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #005773;
  margin-left: 10px;
  text-decoration: none; }

.sidebar-image {
  margin-right: 15px;
  margin-left: 15px; }
  .sidebar-image > svg {
    stroke: #A8A7A7;
    fill: white; }
  .sidebar-image > .docIcon {
    stroke: none;
    fill: #A8A7A7; }

.sidebar-image-available > svg {
  stroke: #005773;
  fill: white; }
  .sidebar-image-available > svg .pQpath:nth-child(3) {
    fill: #005773; }
  .sidebar-image-available > svg.docIcon > path {
    stroke: none;
    fill: #005773; }
  .sidebar-image-available > svg.hintIcon > path {
    stroke: none;
    fill: #005773; }
  .sidebar-image-available > svg.brushIcon > path {
    stroke: none;
    fill: #005773; }

.sidebar-image-active > svg {
  stroke: white;
  fill: #00ADE6; }
  .sidebar-image-active > svg .pQpath:nth-child(3) {
    fill: #FFF; }
  .sidebar-image-active > svg.docIcon > path {
    stroke: none;
    fill: #FFF; }
  .sidebar-image-active > svg.hintIcon > path {
    stroke: none;
    fill: #FFF; }
  .sidebar-image-active > svg.brushIcon > path {
    stroke: none;
    fill: #FFF; }

.active-sidebar-image {
  margin-left: 15px;
  margin-right: 15px; }

.losidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  pointer-events: none; }

.losidebar-available {
  pointer-events: auto; }

.sidebar-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px 5px;
  text-decoration: none;
  -webkit-transform: scale(1);
          transform: scale(1);
  pointer-events: none; }

.sidebar-item-active {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #00ADE6;
  padding-top: 10px;
  color: white;
  width: 100%;
  pointer-events: auto; }
  .sidebar-item-active ~ .forward-arrow svg {
    fill: white; }

.forward-arrow {
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 4px;
  right: 0px;
  cursor: pointer;
  bottom: 50%;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  position: absolute; }

.forward-arrow-rotate {
  padding-left: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  -webkit-transform: rotateZ(90deg);
          transform: rotateZ(90deg); }

.sub-menu {
  position: static;
  display: none;
  transition: ease-in; }

.sub-menu.show {
  display: flex;
  flex-direction: column;
  transition: 320ms; }

.active-sidebar-item {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
  background-color: #00ADE6;
  padding-top: 10px;
  padding-bottom: 10px; }

.options {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #A8A7A7;
  margin-bottom: 7px;
  pointer-events: none;
  width: 100%; }

.losidebar .options {
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.options-available {
  color: #005773;
  pointer-events: auto; }

.options-active {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  pointer-events: auto;
  color: #ffffff; }

.circle-background,
.circle-progress {
  fill: none; }

.circle-background {
  stroke: rgba(168, 167, 167, 0.2); }

.circle-progress {
  stroke: #00C187;
  stroke-linecap: square;
  stroke-linejoin: square; }

.progressIndicator-wh-40 {
  width: 5.31401vw !important;
  height: 5.31401vw !important; }
  @media (min-width: 1300px) {
    .progressIndicator-wh-40 {
      width: 1.82292vw !important;
      height: 1.82292vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .progressIndicator-wh-40 {
      width: 2.02546vw !important;
      height: 2.02546vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .progressIndicator-wh-40 {
      width: 2.02546vw !important;
      height: 2.02546vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .progressIndicator-wh-40 {
      width: 4.42834vw !important;
      height: 4.42834vw !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .progressIndicator-wh-40 {
      width: 3.54267vw !important;
      height: 3.54267vw !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .progressIndicator-wh-40 {
      width: 3.12589vw !important;
      height: 3.12589vw !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .progressIndicator-wh-40 {
      width: 2.79685vw !important;
      height: 2.79685vw !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .progressIndicator-wh-40 {
      width: 2.53048vw !important;
      height: 2.53048vw !important; } }

.progressIndicator-overall-progress {
  margin-top: 0;
  margin-left: 7.72947vw; }
  @media (min-width: 1300px) {
    .progressIndicator-overall-progress {
      margin-top: 3.02083vw;
      margin-left: 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .progressIndicator-overall-progress {
      margin-top: 3.35648vw;
      margin-left: 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .progressIndicator-overall-progress {
      margin-top: 3.35648vw;
      margin-left: 0; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .progressIndicator-overall-progress {
      margin-left: 6.44122vw; }
      .progressIndicator-overall-progress else {
        margin-top: 0; }
      .progressIndicator-overall-progress else {
        margin-left: 32px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .progressIndicator-overall-progress {
      margin-left: 5.15298vw; }
      .progressIndicator-overall-progress else {
        margin-top: 0; }
      .progressIndicator-overall-progress else {
        margin-left: 32px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .progressIndicator-overall-progress {
      margin-left: 4.54675vw; }
      .progressIndicator-overall-progress else {
        margin-top: 0; }
      .progressIndicator-overall-progress else {
        margin-left: 32px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .progressIndicator-overall-progress {
      margin-left: 4.06814vw; }
      .progressIndicator-overall-progress else {
        margin-top: 0; }
      .progressIndicator-overall-progress else {
        margin-left: 32px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .progressIndicator-overall-progress {
      margin-left: 3.6807vw; }
      .progressIndicator-overall-progress else {
        margin-top: 0; }
      .progressIndicator-overall-progress else {
        margin-left: 32px; } }

.progressIndicator-mainContainer {
  box-sizing: border-box;
  display: flex;
  margin: auto;
  top: 0;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 4.83092vw 2.89855vw;
  position: relative;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start; }
  @media (min-width: 1300px) {
    .progressIndicator-mainContainer {
      top: 1.04167vw;
      height: 100%;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 0vw 1.97917vw;
      position: -webkit-sticky;
      position: sticky;
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .progressIndicator-mainContainer {
      top: 1.15741vw;
      height: 100%;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 0vw 2.19907vw;
      position: -webkit-sticky;
      position: sticky;
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .progressIndicator-mainContainer {
      top: 1.15741vw;
      height: 100%;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 0vw 2.19907vw;
      position: -webkit-sticky;
      position: sticky;
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .progressIndicator-mainContainer {
      padding: 4.02576vw 2.41546vw; }
      .progressIndicator-mainContainer else {
        top: 0; }
      .progressIndicator-mainContainer else {
        width: 100%; }
      .progressIndicator-mainContainer else {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .progressIndicator-mainContainer {
      padding: 3.22061vw 1.93237vw; }
      .progressIndicator-mainContainer else {
        top: 0; }
      .progressIndicator-mainContainer else {
        width: 100%; }
      .progressIndicator-mainContainer else {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .progressIndicator-mainContainer {
      padding: 2.84172vw 1.70503vw; }
      .progressIndicator-mainContainer else {
        top: 0; }
      .progressIndicator-mainContainer else {
        width: 100%; }
      .progressIndicator-mainContainer else {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .progressIndicator-mainContainer {
      padding: 2.54259vw 1.52555vw; }
      .progressIndicator-mainContainer else {
        top: 0; }
      .progressIndicator-mainContainer else {
        width: 100%; }
      .progressIndicator-mainContainer else {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .progressIndicator-mainContainer {
      padding: 2.30044vw 1.38026vw; }
      .progressIndicator-mainContainer else {
        top: 0; }
      .progressIndicator-mainContainer else {
        width: 100%; }
      .progressIndicator-mainContainer else {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; } }

.progressIndicator-individual-progress {
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start; }
  @media (min-width: 1300px) {
    .progressIndicator-individual-progress {
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .progressIndicator-individual-progress {
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .progressIndicator-individual-progress {
      flex-direction: column;
      justify-content: center;
      align-items: center; } }

.progressIndicator-progress-bar {
  position: absolute;
  background: #ddd;
  z-index: -1;
  height: 1.93237vw;
  top: 50%;
  width: 100%; }
  @media (min-width: 1300px) {
    .progressIndicator-progress-bar {
      height: 100%;
      top: 0;
      width: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .progressIndicator-progress-bar {
      height: 100%;
      top: 0;
      width: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .progressIndicator-progress-bar {
      height: 100%;
      top: 0;
      width: 0.46296vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .progressIndicator-progress-bar {
      height: 1.61031vw; }
      .progressIndicator-progress-bar else {
        height: 8px; }
      .progressIndicator-progress-bar else {
        top: 50%; }
      .progressIndicator-progress-bar else {
        width: 100%; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .progressIndicator-progress-bar {
      height: 1.28824vw; }
      .progressIndicator-progress-bar else {
        height: 8px; }
      .progressIndicator-progress-bar else {
        top: 50%; }
      .progressIndicator-progress-bar else {
        width: 100%; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .progressIndicator-progress-bar {
      height: 1.13669vw; }
      .progressIndicator-progress-bar else {
        height: 8px; }
      .progressIndicator-progress-bar else {
        top: 50%; }
      .progressIndicator-progress-bar else {
        width: 100%; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .progressIndicator-progress-bar {
      height: 1.01704vw; }
      .progressIndicator-progress-bar else {
        height: 8px; }
      .progressIndicator-progress-bar else {
        top: 50%; }
      .progressIndicator-progress-bar else {
        width: 100%; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .progressIndicator-progress-bar {
      height: 0.92017vw; }
      .progressIndicator-progress-bar else {
        height: 8px; }
      .progressIndicator-progress-bar else {
        top: 50%; }
      .progressIndicator-progress-bar else {
        width: 100%; } }
  .progressIndicator-progress-bar .progressIndicator-progress-bar-active {
    position: absolute;
    background: #00ADE6;
    z-index: 1;
    top: 0;
    width: 0%;
    height: 1.93237vw; }
    @media (min-width: 1300px) {
      .progressIndicator-progress-bar .progressIndicator-progress-bar-active {
        height: 0%;
        width: 0.41667vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .progressIndicator-progress-bar .progressIndicator-progress-bar-active {
        height: 0%;
        width: 0.46296vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .progressIndicator-progress-bar .progressIndicator-progress-bar-active {
        height: 0%;
        width: 0.46296vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .progressIndicator-progress-bar .progressIndicator-progress-bar-active {
        height: 1.61031vw; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          width: 0%; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          height: 8px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .progressIndicator-progress-bar .progressIndicator-progress-bar-active {
        height: 1.28824vw; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          width: 0%; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          height: 8px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .progressIndicator-progress-bar .progressIndicator-progress-bar-active {
        height: 1.13669vw; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          width: 0%; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          height: 8px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .progressIndicator-progress-bar .progressIndicator-progress-bar-active {
        height: 1.01704vw; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          width: 0%; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          height: 8px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .progressIndicator-progress-bar .progressIndicator-progress-bar-active {
        height: 0.92017vw; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          width: 0%; }
        .progressIndicator-progress-bar .progressIndicator-progress-bar-active else {
          height: 8px; } }

.progressIndicator-icon-ProgressCircle {
  background: #eeeded;
  padding: 6px;
  border-radius: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  pointer-events: none;
  z-index: 9;
  transition: 0.1s all ease-in-out;
  margin-bottom: 0;
  margin-right: 6.76329vw; }
  @media (min-width: 1300px) {
    .progressIndicator-icon-ProgressCircle {
      margin-bottom: 1.66667vw;
      margin-right: 0vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .progressIndicator-icon-ProgressCircle {
      margin-bottom: 1.85185vw;
      margin-right: 0vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .progressIndicator-icon-ProgressCircle {
      margin-bottom: 1.85185vw;
      margin-right: 0vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .progressIndicator-icon-ProgressCircle {
      margin-right: 5.63607vw; }
      .progressIndicator-icon-ProgressCircle else {
        margin-bottom: 0; }
      .progressIndicator-icon-ProgressCircle else {
        margin-right: 28px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .progressIndicator-icon-ProgressCircle {
      margin-right: 4.50886vw; }
      .progressIndicator-icon-ProgressCircle else {
        margin-bottom: 0; }
      .progressIndicator-icon-ProgressCircle else {
        margin-right: 28px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .progressIndicator-icon-ProgressCircle {
      margin-right: 3.9784vw; }
      .progressIndicator-icon-ProgressCircle else {
        margin-bottom: 0; }
      .progressIndicator-icon-ProgressCircle else {
        margin-right: 28px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .progressIndicator-icon-ProgressCircle {
      margin-right: 3.55962vw; }
      .progressIndicator-icon-ProgressCircle else {
        margin-bottom: 0; }
      .progressIndicator-icon-ProgressCircle else {
        margin-right: 28px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .progressIndicator-icon-ProgressCircle {
      margin-right: 3.22061vw; }
      .progressIndicator-icon-ProgressCircle else {
        margin-bottom: 0; }
      .progressIndicator-icon-ProgressCircle else {
        margin-right: 28px; } }
  .progressIndicator-icon-ProgressCircle:hover > .loOverlayMenu {
    opacity: 1;
    width: auto;
    visibility: visible; }

.progressIndicator-icon-locked {
  z-index: 9; }

.progressIndicator-icon-ProgressCircle:nth-last-child(2) {
  margin-right: 0; }
  @media (min-width: 1300px) {
    .progressIndicator-icon-ProgressCircle:nth-last-child(2) {
      margin-bottom: 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .progressIndicator-icon-ProgressCircle:nth-last-child(2) {
      margin-bottom: 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .progressIndicator-icon-ProgressCircle:nth-last-child(2) {
      margin-bottom: 0; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .progressIndicator-icon-ProgressCircle:nth-last-child(2) else {
      margin-right: 0; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .progressIndicator-icon-ProgressCircle:nth-last-child(2) else {
      margin-right: 0; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .progressIndicator-icon-ProgressCircle:nth-last-child(2) else {
      margin-right: 0; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .progressIndicator-icon-ProgressCircle:nth-last-child(2) else {
      margin-right: 0; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .progressIndicator-icon-ProgressCircle:nth-last-child(2) else {
      margin-right: 0; } }

.progressIndicator-icon-container {
  background-color: #A8A7A7;
  padding: 10px;
  border-radius: 100%;
  border: 4px solid #FFF;
  z-index: 1; }

.progressIndicator-icon {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  align-self: flex-start;
  transition: all .5s;
  width: 4.34783vw;
  height: 4.34783vw; }
  @media (min-width: 1300px) {
    .progressIndicator-icon {
      width: 1.25vw;
      height: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .progressIndicator-icon {
      width: 1.38889vw;
      height: 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .progressIndicator-icon {
      width: 1.38889vw;
      height: 1.38889vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .progressIndicator-icon {
      width: 3.62319vw;
      height: 3.62319vw; }
      .progressIndicator-icon else {
        width: 18px; }
      .progressIndicator-icon else {
        height: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .progressIndicator-icon {
      width: 2.89855vw;
      height: 2.89855vw; }
      .progressIndicator-icon else {
        width: 18px; }
      .progressIndicator-icon else {
        height: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .progressIndicator-icon {
      width: 2.55754vw;
      height: 2.55754vw; }
      .progressIndicator-icon else {
        width: 18px; }
      .progressIndicator-icon else {
        height: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .progressIndicator-icon {
      width: 2.28833vw;
      height: 2.28833vw; }
      .progressIndicator-icon else {
        width: 18px; }
      .progressIndicator-icon else {
        height: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .progressIndicator-icon {
      width: 2.07039vw;
      height: 2.07039vw; }
      .progressIndicator-icon else {
        width: 18px; }
      .progressIndicator-icon else {
        height: 18px; } }

.progressIndicator-completed {
  background: linear-gradient(180deg, #35e4e9 -8.62%, #00ade6 109.2%);
  pointer-events: all;
  cursor: pointer; }
  .progressIndicator-completed > .progressIndicator-icon-container {
    background-color: #00ADE6; }

.progressIndicator-active {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  pointer-events: all;
  background: #bff4f8; }
  .progressIndicator-active > .lo-component-cicle-stretched-rightArrow {
    background-color: #00ADE6 !important; }
  .progressIndicator-active > .progressIndicator-icon-container {
    background-color: #00ADE6; }

.progressIndicator-active-completed {
  background: linear-gradient(100deg, #35e4e9 -8.62%, #00ade6 109.2%); }
  .progressIndicator-active-completed > .lo-component-cicle-stretched-rightArrow {
    background-color: #FFF !important; }

.overallProgressIndicator-success {
  background-color: #00ADE6 !important; }

.loOverlayMenu {
  background: rgba(0, 173, 230, 0.1);
  border-radius: 8px 50px 50px 8px;
  width: 0%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  opacity: 0;
  visibility: hidden;
  padding: 0px 10px 0px 40px; }

.updated-input-tooltip.cn-tooltip {
  background: #005773 !important;
  box-shadow: 0px 2px 4px rgba(65, 87, 102, 0.12), 0px 4px 10px rgba(65, 87, 102, 0.1);
  color: #FFF !important;
  padding: 8px 16px !important;
  opacity: 1 !important;
  text-align: center !important;
  font-weight: bold !important; }
  @media (min-width: 1300px) {
    .updated-input-tooltip.cn-tooltip {
      font-size: 0.9375vw !important;
      border-radius: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .updated-input-tooltip.cn-tooltip {
      font-size: 1.04167vw !important;
      border-radius: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .updated-input-tooltip.cn-tooltip {
      font-size: 1.04167vw !important;
      border-radius: 0.46296vw; } }

.updated-input-tooltip.cn-sub-tooltip {
  background: #00ADE6 !important;
  white-space: nowrap;
  box-shadow: 0px 2px 4px rgba(65, 87, 102, 0.12), 0px 4px 10px rgba(65, 87, 102, 0.1) !important; }

.lo-component-cicle-stretched {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0px;
  z-index: -10;
  height: 100%;
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
  background: inherit;
  border-radius: 100%;
  border-bottom-right-radius: 40%; }

.lo-component-cicle-stretched-rightArrow {
  -webkit-mask-image: url(/static/media/dropdownArrow.9dab8a2f.svg);
  mask-image: url(/static/media/dropdownArrow.9dab8a2f.svg);
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-size: contain;
          mask-size: contain;
  -webkit-mask-position: center;
          mask-position: center;
  background-color: #FFF;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  position: absolute;
  top: 40%;
  right: -6px; }

.sidebar-loader-card {
  width: 100%;
  height: 100%; }
  @media (min-width: 1300px) {
    .sidebar-loader-card .sidebar-loader-1 {
      y: 13.02083vw;
      x: 2.60417vw;
      height: 4.6875vw;
      width: 4.6875vw;
      rx: 100px; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sidebar-loader-card .sidebar-loader-1 {
      y: 14.46759vw;
      x: 2.89352vw;
      height: 5.20833vw;
      width: 5.20833vw;
      rx: 100px; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sidebar-loader-card .sidebar-loader-1 {
      y: 14.46759vw;
      x: 2.89352vw;
      height: 5.20833vw;
      width: 5.20833vw;
      rx: 100px; } }
  @media (min-width: 1300px) {
    .sidebar-loader-card .sidebar-loader-2 {
      y: 18.75vw;
      x: 2.60417vw;
      height: 4.6875vw;
      width: 4.6875vw;
      rx: 100px; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sidebar-loader-card .sidebar-loader-2 {
      y: 20.83333vw;
      x: 2.89352vw;
      height: 5.20833vw;
      width: 5.20833vw;
      rx: 100px; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sidebar-loader-card .sidebar-loader-2 {
      y: 20.83333vw;
      x: 2.89352vw;
      height: 5.20833vw;
      width: 5.20833vw;
      rx: 100px; } }
  @media (min-width: 1300px) {
    .sidebar-loader-card .sidebar-loader-3 {
      y: 24.47917vw;
      x: 2.60417vw;
      height: 4.6875vw;
      width: 4.6875vw;
      rx: 100px; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sidebar-loader-card .sidebar-loader-3 {
      y: 27.19907vw;
      x: 2.89352vw;
      height: 5.20833vw;
      width: 5.20833vw;
      rx: 100px; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sidebar-loader-card .sidebar-loader-3 {
      y: 27.19907vw;
      x: 2.89352vw;
      height: 5.20833vw;
      width: 5.20833vw;
      rx: 100px; } }

.sidebar-LoTitle {
  background: #005773;
  border-radius: 8px 8px 0px 0px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #EEE;
  max-width: 50%;
  padding: 4px 12px;
  font-size: 8px;
  text-align: center;
  font-weight: bold; }

.photon-label {
  font-family: Nunito;
  color: #504F4F;
  color: rgba(17, 17, 17, 0.48);
  opacity: 0.7;
  font-size: 2.96296vw;
  margin-top: 2.22222vw;
  margin-bottom: 1.85185vw; }
  .photon-label.error {
    opacity: 1;
    color: #FF5744; }
  @media (min-width: 1300px) {
    .photon-label {
      font-size: 0.83333vw;
      margin-bottom: 0.52083vw;
      margin-top: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-label {
      font-size: 0.92593vw;
      margin-bottom: 0.5787vw;
      margin-top: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-label {
      font-size: 0.92593vw;
      margin-bottom: 0.5787vw;
      margin-top: unset; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-label {
      font-size: 2.46914vw;
      margin-top: 1.85185vw;
      margin-bottom: 1.54321vw; }
      .photon-label else {
        font-size: 16px; }
      .photon-label else {
        margin-top: 12px; }
      .photon-label else {
        margin-bottom: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-label {
      font-size: 1.97531vw;
      margin-top: 1.48148vw;
      margin-bottom: 1.23457vw; }
      .photon-label else {
        font-size: 16px; }
      .photon-label else {
        margin-top: 12px; }
      .photon-label else {
        margin-bottom: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-label {
      font-size: 1.74292vw;
      margin-top: 1.30719vw;
      margin-bottom: 1.08932vw; }
      .photon-label else {
        font-size: 16px; }
      .photon-label else {
        margin-top: 12px; }
      .photon-label else {
        margin-bottom: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-label {
      font-size: 1.55945vw;
      margin-top: 1.16959vw;
      margin-bottom: 0.97466vw; }
      .photon-label else {
        font-size: 16px; }
      .photon-label else {
        margin-top: 12px; }
      .photon-label else {
        margin-bottom: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-label {
      font-size: 1.41093vw;
      margin-top: 1.0582vw;
      margin-bottom: 0.88183vw; }
      .photon-label else {
        font-size: 16px; }
      .photon-label else {
        margin-top: 12px; }
      .photon-label else {
        margin-bottom: 10px; } }

.photon-input-tooltip {
  background-color: #bff7f9 !important;
  font-weight: 600 !important; }
  @media (min-width: 1300px) {
    .photon-input-tooltip {
      font-size: 0.83333vw !important;
      padding-left: 0.52083vw !important;
      padding-right: 0.52083vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-input-tooltip {
      font-size: 0.92593vw !important;
      padding-left: 0.5787vw !important;
      padding-right: 0.5787vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-input-tooltip {
      font-size: 0.92593vw !important;
      padding-left: 0.5787vw !important;
      padding-right: 0.5787vw !important; } }

.photon-input {
  width: 100% !important;
  background: #FFFFFF !important;
  font-family: Nunito !important;
  box-sizing: border-box !important;
  font-weight: 600 !important;
  transition: 0.3s all ease-in-out;
  border: 0.18519vw solid #C4C4C4 !important;
  border-radius: 1.11111vw !important;
  height: 10.37037vw !important;
  padding: 2.96296vw !important;
  font-size: 3.7037vw !important;
  width: 100% !important;
  color: #504F4F !important; }
  @media (min-width: 1300px) {
    .photon-input {
      border: 0.05208vw solid #C4C4C4 !important;
      border-radius: 0.3125vw !important;
      height: 2.91667vw !important;
      padding: 0.83333vw !important;
      font-size: 1.04167vw !important;
      width: 100% !important;
      color: #504F4F !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-input {
      border: 0.05787vw solid #C4C4C4 !important;
      border-radius: 0.34722vw !important;
      height: 3.24074vw !important;
      padding: 0.92593vw !important;
      font-size: 1.15741vw !important;
      width: 100% !important;
      color: #504F4F !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-input {
      border: 0.05787vw solid #C4C4C4 !important;
      border-radius: 0.34722vw !important;
      height: 3.24074vw !important;
      padding: 0.92593vw !important;
      font-size: 1.15741vw !important;
      width: 100% !important;
      color: #504F4F !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-input {
      border: 0.15432vw solid #C4C4C4 !important;
      border-radius: 0.92593vw !important;
      height: 8.64198vw !important;
      padding: 2.46914vw !important;
      font-size: 3.08642vw !important;
      width: 100% !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-input {
      border: 0.12346vw solid #C4C4C4 !important;
      border-radius: 0.74074vw !important;
      height: 6.91358vw !important;
      padding: 1.97531vw !important;
      font-size: 2.46914vw !important;
      width: 100% !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-input {
      border: 0.10893vw solid #C4C4C4 !important;
      border-radius: 0.65359vw !important;
      height: 6.10022vw !important;
      padding: 1.74292vw !important;
      font-size: 2.17865vw !important;
      width: 100% !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-input {
      border: 0.09747vw solid #C4C4C4 !important;
      border-radius: 0.5848vw !important;
      height: 5.45809vw !important;
      padding: 1.55945vw !important;
      font-size: 1.94932vw !important;
      width: 100% !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-input {
      border: 0.08818vw solid #C4C4C4 !important;
      border-radius: 0.5291vw !important;
      height: 4.93827vw !important;
      padding: 1.41093vw !important;
      font-size: 1.76367vw !important;
      width: 100% !important; } }
  .photon-input.error {
    border: 0.18519vw solid #FF5744 !important; }
    @media (min-width: 1300px) {
      .photon-input.error {
        border: 0.05208vw solid #FF5744 !important; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .photon-input.error {
        border: 0.05787vw solid #FF5744 !important; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .photon-input.error {
        border: 0.05787vw solid #FF5744 !important; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .photon-input.error {
        border: 0.15432vw solid #FF5744 !important; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .photon-input.error {
        border: 0.12346vw solid #FF5744 !important; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .photon-input.error {
        border: 0.10893vw solid #FF5744 !important; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .photon-input.error {
        border: 0.09747vw solid #FF5744 !important; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .photon-input.error {
        border: 0.08818vw solid #FF5744 !important; } }
  .photon-input.disabled {
    opacity: 0.6;
    cursor: not-allowed; }
  .photon-input:focus {
    box-shadow: 0 0 0 1px #00ADE6 !important;
    border: 1px solid transparent !important;
    outline: none !important; }
  .photon-input:focus-visible {
    box-shadow: 0 0 0 1px #00ADE6 !important;
    border: 1px solid transparent !important;
    outline: none !important; }
  .photon-input::-webkit-input-placeholder {
    color: rgba(17, 17, 17, 0.48) !important;
    opacity: 0.7 !important; }
  .photon-input:-ms-input-placeholder {
    color: rgba(17, 17, 17, 0.48) !important;
    opacity: 0.7 !important; }
  .photon-input::placeholder {
    color: rgba(17, 17, 17, 0.48) !important;
    opacity: 0.7 !important; }

.photon-phone-input {
  margin-left: 11.83575vw !important;
  width: 87% !important;
  border-radius: 0 1.44928vw 1.44928vw 0 !important;
  z-index: 2 !important; }
  @media (min-width: 1300px) {
    .photon-phone-input {
      margin-left: 3.59375vw !important;
      width: 83.5% !important;
      border-radius: 0 0.3125vw 0.3125vw 0 !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-phone-input {
      margin-left: 3.99306vw !important;
      width: 83.5% !important;
      border-radius: 0 0.34722vw 0.34722vw 0 !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-phone-input {
      margin-left: 3.99306vw !important;
      width: 83.5% !important;
      border-radius: 0 0.34722vw 0.34722vw 0 !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-phone-input {
      margin-left: 9.86312vw !important;
      width: 87% !important;
      border-radius: 0 1.20773vw 1.20773vw 0 !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-phone-input {
      margin-left: 7.8905vw !important;
      width: 87% !important;
      border-radius: 0 0.96618vw 0.96618vw 0 !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-phone-input {
      margin-left: 6.96221vw !important;
      width: 87% !important;
      border-radius: 0 0.85251vw 0.85251vw 0 !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-phone-input {
      margin-left: 6.22934vw !important;
      width: 87% !important;
      border-radius: 0 0.76278vw 0.76278vw 0 !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-phone-input {
      margin-left: 5.63607vw !important;
      width: 87% !important;
      border-radius: 0 0.69013vw 0.69013vw 0 !important; } }
  @media (min-width: 1300px) {
    .photon-phone-input:focus, .photon-phone-input:focus-visible {
      border-radius: 0.3125vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-phone-input:focus, .photon-phone-input:focus-visible {
      border-radius: 0.34722vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-phone-input:focus, .photon-phone-input:focus-visible {
      border-radius: 0.34722vw !important; } }

.photon-otp-container .photon-otp-input {
  width: 10.37037vw !important;
  margin-right: 4.44444vw; }
  @media (min-width: 1300px) {
    .photon-otp-container .photon-otp-input {
      width: 2.91667vw !important;
      margin-right: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-otp-container .photon-otp-input {
      width: 3.24074vw !important;
      margin-right: 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-otp-container .photon-otp-input {
      width: 3.24074vw !important;
      margin-right: 1.38889vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-otp-container .photon-otp-input {
      width: 8.64198vw !important;
      margin-right: 3.7037vw; }
      .photon-otp-container .photon-otp-input else {
        margin-right: 24px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-otp-container .photon-otp-input {
      width: 6.91358vw !important;
      margin-right: 2.96296vw; }
      .photon-otp-container .photon-otp-input else {
        margin-right: 24px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-otp-container .photon-otp-input {
      width: 6.10022vw !important;
      margin-right: 2.61438vw; }
      .photon-otp-container .photon-otp-input else {
        margin-right: 24px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-otp-container .photon-otp-input {
      width: 5.45809vw !important;
      margin-right: 2.33918vw; }
      .photon-otp-container .photon-otp-input else {
        margin-right: 24px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-otp-container .photon-otp-input {
      width: 4.93827vw !important;
      margin-right: 2.1164vw; }
      .photon-otp-container .photon-otp-input else {
        margin-right: 24px; } }

@media (min-width: 1300px) {
  .photon-otp-container:last-child .photon-otp-input {
    margin-right: 0vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .photon-otp-container:last-child .photon-otp-input {
    margin-right: 0vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .photon-otp-container:last-child .photon-otp-input {
    margin-right: 0vw; } }

.photon-button {
  background: #00ADE6;
  font-family: Nunito;
  font-weight: 600;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2.96296vw 3.33333vw;
  border-radius: 0.74074vw;
  font-family: 22px;
  color: white;
  transition: 0.3s all ease-in-out; }
  @media (min-width: 1300px) {
    .photon-button {
      padding: 0.83333vw 0.9375vw;
      border-radius: 0.20833vw;
      font-family: 22px; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-button {
      padding: 0.92593vw 1.04167vw;
      border-radius: 0.23148vw;
      font-family: 22px; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-button {
      padding: 0.92593vw 1.04167vw;
      border-radius: 0.23148vw;
      font-family: 22px; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-button {
      padding: 2.46914vw 2.77778vw;
      border-radius: 0.61728vw; }
      .photon-button else {
        border-radius: 4px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-button {
      padding: 1.97531vw 2.22222vw;
      border-radius: 0.49383vw; }
      .photon-button else {
        border-radius: 4px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-button {
      padding: 1.74292vw 1.96078vw;
      border-radius: 0.43573vw; }
      .photon-button else {
        border-radius: 4px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-button {
      padding: 1.55945vw 1.75439vw;
      border-radius: 0.38986vw; }
      .photon-button else {
        border-radius: 4px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-button {
      padding: 1.41093vw 1.5873vw;
      border-radius: 0.35273vw; }
      .photon-button else {
        border-radius: 4px; } }
  .photon-button div:first-child {
    pointer-events: none; }
  .photon-button:hover {
    opacity: 0.8; }
  .photon-button:hover .photon-button-arrowContainer {
    -webkit-transform: scale(-1) translateX(-3px);
            transform: scale(-1) translateX(-3px); }
  .photon-button.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed; }

.photon-button-arrowContainer {
  width: 1.94444vw;
  height: 2.77778vw;
  margin-left: 1.85185vw;
  transition: 0.3s all ease-in-out;
  background: url(/static/media/arrow.fa095bde.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transform: scale(-1);
          transform: scale(-1); }
  @media (min-width: 1300px) {
    .photon-button-arrowContainer {
      width: 0.54688vw;
      height: 0.78125vw;
      margin-left: 0.67708vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-button-arrowContainer {
      width: 0.60764vw;
      height: 0.86806vw;
      margin-left: 0.75231vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-button-arrowContainer {
      width: 0.60764vw;
      height: 0.86806vw;
      margin-left: 0.75231vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-button-arrowContainer {
      width: 1.62037vw;
      height: 2.31481vw;
      margin-left: 1.54321vw; }
      .photon-button-arrowContainer else {
        width: 10.5px; }
      .photon-button-arrowContainer else {
        height: 15px; }
      .photon-button-arrowContainer else {
        margin-left: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-button-arrowContainer {
      width: 1.2963vw;
      height: 1.85185vw;
      margin-left: 1.23457vw; }
      .photon-button-arrowContainer else {
        width: 10.5px; }
      .photon-button-arrowContainer else {
        height: 15px; }
      .photon-button-arrowContainer else {
        margin-left: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-button-arrowContainer {
      width: 1.14379vw;
      height: 1.63399vw;
      margin-left: 1.08932vw; }
      .photon-button-arrowContainer else {
        width: 10.5px; }
      .photon-button-arrowContainer else {
        height: 15px; }
      .photon-button-arrowContainer else {
        margin-left: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-button-arrowContainer {
      width: 1.02339vw;
      height: 1.46199vw;
      margin-left: 0.97466vw; }
      .photon-button-arrowContainer else {
        width: 10.5px; }
      .photon-button-arrowContainer else {
        height: 15px; }
      .photon-button-arrowContainer else {
        margin-left: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-button-arrowContainer {
      width: 0.92593vw;
      height: 1.32275vw;
      margin-left: 0.88183vw; }
      .photon-button-arrowContainer else {
        width: 10.5px; }
      .photon-button-arrowContainer else {
        height: 15px; }
      .photon-button-arrowContainer else {
        margin-left: 10px; } }

.photon-link {
  color: #00ADE6;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  font-family: Nunito;
  cursor: pointer;
  font-size: 3.88889vw;
  font-weight: normal; }
  @media (min-width: 1300px) {
    .photon-link {
      font-size: 0.83333vw;
      font-weight: bold; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-link {
      font-size: 0.92593vw;
      font-weight: bold; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-link {
      font-size: 0.92593vw;
      font-weight: bold; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-link {
      font-size: 3.24074vw; }
      .photon-link else {
        font-size: 21px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-link {
      font-size: 2.59259vw; }
      .photon-link else {
        font-size: 21px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-link {
      font-size: 2.28758vw; }
      .photon-link else {
        font-size: 21px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-link {
      font-size: 2.04678vw; }
      .photon-link else {
        font-size: 21px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-link {
      font-size: 1.85185vw; }
      .photon-link else {
        font-size: 21px; } }

.photon-input-password {
  position: relative;
  letter-spacing: 1.69082vw;
  padding-right: 10.86957vw !important; }
  @media (min-width: 1300px) {
    .photon-input-password {
      letter-spacing: 0.52083vw;
      padding-right: 2.60417vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-input-password {
      letter-spacing: 0.5787vw;
      padding-right: 2.89352vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-input-password {
      letter-spacing: 0.5787vw;
      padding-right: 2.89352vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-input-password {
      letter-spacing: 1.40902vw;
      padding-right: 9.05797vw !important; }
      .photon-input-password else {
        letter-spacing: 7px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-input-password {
      letter-spacing: 1.12721vw;
      padding-right: 7.24638vw !important; }
      .photon-input-password else {
        letter-spacing: 7px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-input-password {
      letter-spacing: 0.9946vw;
      padding-right: 6.39386vw !important; }
      .photon-input-password else {
        letter-spacing: 7px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-input-password {
      letter-spacing: 0.88991vw;
      padding-right: 5.72082vw !important; }
      .photon-input-password else {
        letter-spacing: 7px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-input-password {
      letter-spacing: 0.80515vw;
      padding-right: 5.17598vw !important; }
      .photon-input-password else {
        letter-spacing: 7px; } }

.photon-input-password-eye-icon {
  position: absolute;
  opacity: 0.5;
  right: 0;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  top: 1.93237vw;
  letter-spacing: 2.41546vw;
  width: 6.28019vw;
  height: 6.28019vw;
  right: 2.41546vw; }
  .photon-input-password-eye-icon:hover {
    opacity: 0.8; }
  @media (min-width: 1300px) {
    .photon-input-password-eye-icon {
      top: 0.78125vw;
      letter-spacing: 0.52083vw;
      width: 1.45833vw;
      height: 1.45833vw;
      right: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-input-password-eye-icon {
      top: 0.86806vw;
      letter-spacing: 0.5787vw;
      width: 1.62037vw;
      height: 1.62037vw;
      right: 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-input-password-eye-icon {
      top: 0.86806vw;
      letter-spacing: 0.5787vw;
      width: 1.62037vw;
      height: 1.62037vw;
      right: 0.5787vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-input-password-eye-icon {
      top: 1.61031vw;
      letter-spacing: 2.01288vw;
      width: 5.23349vw;
      height: 5.23349vw;
      right: 2.01288vw; }
      .photon-input-password-eye-icon else {
        top: 8px; }
      .photon-input-password-eye-icon else {
        letter-spacing: 10px; }
      .photon-input-password-eye-icon else {
        width: 26px; }
      .photon-input-password-eye-icon else {
        height: 26px; }
      .photon-input-password-eye-icon else {
        right: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-input-password-eye-icon {
      top: 1.28824vw;
      letter-spacing: 1.61031vw;
      width: 4.1868vw;
      height: 4.1868vw;
      right: 1.61031vw; }
      .photon-input-password-eye-icon else {
        top: 8px; }
      .photon-input-password-eye-icon else {
        letter-spacing: 10px; }
      .photon-input-password-eye-icon else {
        width: 26px; }
      .photon-input-password-eye-icon else {
        height: 26px; }
      .photon-input-password-eye-icon else {
        right: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-input-password-eye-icon {
      top: 1.13669vw;
      letter-spacing: 1.42086vw;
      width: 3.69423vw;
      height: 3.69423vw;
      right: 1.42086vw; }
      .photon-input-password-eye-icon else {
        top: 8px; }
      .photon-input-password-eye-icon else {
        letter-spacing: 10px; }
      .photon-input-password-eye-icon else {
        width: 26px; }
      .photon-input-password-eye-icon else {
        height: 26px; }
      .photon-input-password-eye-icon else {
        right: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-input-password-eye-icon {
      top: 1.01704vw;
      letter-spacing: 1.27129vw;
      width: 3.30536vw;
      height: 3.30536vw;
      right: 1.27129vw; }
      .photon-input-password-eye-icon else {
        top: 8px; }
      .photon-input-password-eye-icon else {
        letter-spacing: 10px; }
      .photon-input-password-eye-icon else {
        width: 26px; }
      .photon-input-password-eye-icon else {
        height: 26px; }
      .photon-input-password-eye-icon else {
        right: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-input-password-eye-icon {
      top: 0.92017vw;
      letter-spacing: 1.15022vw;
      width: 2.99057vw;
      height: 2.99057vw;
      right: 1.15022vw; }
      .photon-input-password-eye-icon else {
        top: 8px; }
      .photon-input-password-eye-icon else {
        letter-spacing: 10px; }
      .photon-input-password-eye-icon else {
        width: 26px; }
      .photon-input-password-eye-icon else {
        height: 26px; }
      .photon-input-password-eye-icon else {
        right: 10px; } }

.photon-phone-button {
  font-family: Nunito !important;
  background-color: white !important;
  transition: 0.3s all ease-in-out;
  width: 11.11111vw;
  font-size: 2.96296vw;
  border-right: 1px solid #C4C4C4; }
  @media (min-width: 1300px) {
    .photon-phone-button {
      width: 3.64583vw;
      font-size: 0.9375vw;
      border-right: 1px solid #C4C4C4; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-phone-button {
      width: 4.05093vw;
      font-size: 1.04167vw;
      border-right: 1px solid #C4C4C4; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-phone-button {
      width: 4.05093vw;
      font-size: 1.04167vw;
      border-right: 1px solid #C4C4C4; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-phone-button {
      width: 9.25926vw;
      font-size: 2.46914vw; }
      .photon-phone-button else {
        width: 60px; }
      .photon-phone-button else {
        font-size: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-phone-button {
      width: 7.40741vw;
      font-size: 1.97531vw; }
      .photon-phone-button else {
        width: 60px; }
      .photon-phone-button else {
        font-size: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-phone-button {
      width: 6.53595vw;
      font-size: 1.74292vw; }
      .photon-phone-button else {
        width: 60px; }
      .photon-phone-button else {
        font-size: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-phone-button {
      width: 5.84795vw;
      font-size: 1.55945vw; }
      .photon-phone-button else {
        width: 60px; }
      .photon-phone-button else {
        font-size: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-phone-button {
      width: 5.29101vw;
      font-size: 1.41093vw; }
      .photon-phone-button else {
        width: 60px; }
      .photon-phone-button else {
        font-size: 16px; } }
  .photon-phone-button.error {
    border: 1px solid #FF5744 !important; }
  .photon-phone-button:hover {
    background-color: rgba(0, 173, 229, 0.2) !important; }
  .photon-phone-button .react-tel-input {
    font-family: 'Lato', sans-serif;
    width: 100% !important; }
  .photon-phone-button .selected-flag {
    width: 100% !important; }
    @media (min-width: 1300px) {
      .photon-phone-button .selected-flag {
        padding-left: 0.9375vw !important; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .photon-phone-button .selected-flag {
        padding-left: 1.04167vw !important; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .photon-phone-button .selected-flag {
        padding-left: 1.04167vw !important; } }
    .photon-phone-button .selected-flag:hover {
      background: transparent !important; }
  .photon-phone-button .arrow {
    opacity: 0.4; }
  .photon-phone-button .selected-flag.open {
    background: transparent !important; }
  .photon-phone-button .country-list {
    background-color: #fff !important;
    overflow-y: auto !important; }
  .photon-phone-button .flag-dropdown:focus {
    outline: none; }
  .photon-phone-button .country {
    color: #222 !important;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .photon-phone-button .country:hover {
      background-color: #fff !important;
      background-color: rgba(6, 179, 230, 0.3) !important; }
    .photon-phone-button .country:focus {
      outline: none; }
  .photon-phone-button .country.highlight {
    background-color: rgba(6, 179, 230, 0.7) !important;
    color: white !important;
    font-weight: 700;
    border: unset !important; }
    .photon-phone-button .country.highlight .dial-code {
      color: white !important; }
  .photon-phone-button .icon, .photon-phone-button .flag.in, .photon-phone-button .flag.ru, .photon-phone-button .flag.af, .photon-phone-button .flag.au, .photon-phone-button .flag.sg, .photon-phone-button .flag.us, .photon-phone-button .flag.gb, .photon-phone-button .flag.ae {
    background-repeat: no-repeat !important; }
  .photon-phone-button .flag.in {
    background-image: url(/static/media/in.bdfaaf97.svg) !important;
    background-position: 0 0 !important; }
  .photon-phone-button .flag.ru {
    background-image: url(/static/media/ru.e2037a0e.svg) !important;
    background-position: 0 0 !important; }
  .photon-phone-button .flag.af {
    background-image: url(/static/media/af.d8fb0d22.svg) !important;
    background-position: 0 0 !important; }
  .photon-phone-button .flag.au {
    background-image: url(/static/media/au.e875a51e.svg) !important;
    background-position: 0 0 !important; }
  .photon-phone-button .flag.sg {
    background-image: url(/static/media/sg.6ae2dc5b.svg) !important;
    background-position: 0 0 !important; }
  .photon-phone-button .flag.us {
    background-image: url(/static/media/us.50162f60.svg) !important;
    background-position: 0 0 !important; }
  .photon-phone-button .flag.gb {
    background-image: url(/static/media/gb.c39480d5.svg) !important;
    background-position: 0 0 !important; }
  .photon-phone-button .flag.ae {
    background-image: url(/static/media/ae.6d497f1c.svg) !important;
    background-position: 0 0 !important; }

.photon-button-3d-shadow {
  box-shadow: inset 0px 0px 8px #34E4EA;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: 0.3s all ease-in-out;
  box-shadow: inset 0vw 0vw 1.0306vw #34E4EA;
  border-radius: 13vw;
  background: #0C718E;
  z-index: 98; }
  .photon-button-3d-shadow.disabled {
    box-shadow: none;
    background: #A8A7A7;
    cursor: default; }
    @media (min-width: 1300px) {
      .photon-button-3d-shadow.disabled {
        box-shadow: none;
        background: #A8A7A7; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .photon-button-3d-shadow.disabled {
        box-shadow: none;
        background: #A8A7A7; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .photon-button-3d-shadow.disabled {
        box-shadow: none;
        background: #A8A7A7; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .photon-button-3d-shadow.disabled else {
        box-shadow: none; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .photon-button-3d-shadow.disabled else {
        box-shadow: none; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .photon-button-3d-shadow.disabled else {
        box-shadow: none; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .photon-button-3d-shadow.disabled else {
        box-shadow: none; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .photon-button-3d-shadow.disabled else {
        box-shadow: none; } }
    .photon-button-3d-shadow.disabled:hover .photon-button-3d-inner-container {
      box-shadow: inset 0px 0px 5.56522px #A8A7A7; }
  .photon-button-3d-shadow:hover .photon-button-3d-inner-container {
    box-shadow: 0px 0px 30px rgba(53, 201, 233, 0.5), inset 0px 0px 8px rgba(0, 255, 240, 0.8), 0px 0px 15px rgba(0, 255, 240, 0.8); }
  .photon-button-3d-shadow:active .photon-button-3d-inner-container {
    -webkit-transform: translateY(-2vw);
            transform: translateY(-2vw); }
    @media (min-width: 1300px) {
      .photon-button-3d-shadow:active .photon-button-3d-inner-container {
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px); } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .photon-button-3d-shadow:active .photon-button-3d-inner-container {
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px); } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .photon-button-3d-shadow:active .photon-button-3d-inner-container {
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px); } }
  @media (min-width: 1300px) {
    .photon-button-3d-shadow {
      border-radius: 2.08333vw;
      height: 3.17708vw;
      margin-top: 0.41667vw;
      background: #0C718E; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-button-3d-shadow {
      border-radius: 2.31481vw;
      height: 3.53009vw;
      margin-top: 0.46296vw;
      background: #0C718E; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-button-3d-shadow {
      border-radius: 2.31481vw;
      height: 3.53009vw;
      margin-top: 0.46296vw;
      background: #0C718E; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-button-3d-shadow {
      box-shadow: inset 0vw 0vw 0.85883vw #34E4EA; }
      .photon-button-3d-shadow else {
        border-radius: 13vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-button-3d-shadow {
      box-shadow: inset 0vw 0vw 0.68706vw #34E4EA; }
      .photon-button-3d-shadow else {
        border-radius: 13vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-button-3d-shadow {
      box-shadow: inset 0vw 0vw 0.60623vw #34E4EA; }
      .photon-button-3d-shadow else {
        border-radius: 13vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-button-3d-shadow {
      box-shadow: inset 0vw 0vw 0.54242vw #34E4EA; }
      .photon-button-3d-shadow else {
        border-radius: 13vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-button-3d-shadow {
      box-shadow: inset 0vw 0vw 0.49076vw #34E4EA; }
      .photon-button-3d-shadow else {
        border-radius: 13vw; } }

.photon-button-3d-inner-container {
  background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
  box-shadow: 0px 0px 20.8696px rgba(53, 201, 233, 0.5), inset 0px 0px 5.56522px rgba(0, 255, 240, 0.8);
  color: #ffffff;
  display: flex;
  align-items: center;
  transition: 0.3s box-shadow ease-in-out, 0.3s width ease-in-out, 0.1s -webkit-transform ease-in-out;
  transition: 0.1s transform ease-in-out, 0.3s box-shadow ease-in-out, 0.3s width ease-in-out;
  transition: 0.1s transform ease-in-out, 0.3s box-shadow ease-in-out, 0.3s width ease-in-out, 0.1s -webkit-transform ease-in-out;
  text-shadow: 0px 1.391px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 13vw;
  padding: 0 6vw;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 12vw;
  background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
  text-align: center;
  font-weight: bold;
  -webkit-transform: translateY(-6px);
          transform: translateY(-6px); }
  .photon-button-3d-inner-container.disabled {
    background: #DCDCDC;
    color: #A8A7A7;
    box-shadow: none;
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    text-shadow: none; }
  .photon-button-3d-inner-container.transform {
    font-size: 3.7vw;
    text-transform: uppercase; }
    @media (min-width: 1300px) {
      .photon-button-3d-inner-container.transform {
        font-size: medium;
        font-size: initial;
        text-transform: none;
        text-transform: initial; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .photon-button-3d-inner-container.transform {
        font-size: medium;
        font-size: initial;
        text-transform: none;
        text-transform: initial; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .photon-button-3d-inner-container.transform {
        font-size: medium;
        font-size: initial;
        text-transform: none;
        text-transform: initial; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .photon-button-3d-inner-container.transform else {
        font-size: 3.7vw; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .photon-button-3d-inner-container.transform else {
        font-size: 3.7vw; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .photon-button-3d-inner-container.transform else {
        font-size: 3.7vw; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .photon-button-3d-inner-container.transform else {
        font-size: 3.7vw; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .photon-button-3d-inner-container.transform else {
        font-size: 3.7vw; } }
  @media (min-width: 1300px) {
    .photon-button-3d-inner-container {
      border-radius: 2.08333vw;
      width: auto;
      width: initial;
      height: 3.17708vw;
      padding: 0.83333vw 1.25vw;
      font-size: 1.09375vw;
      font-weight: 900;
      background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-button-3d-inner-container {
      border-radius: 2.31481vw;
      width: auto;
      width: initial;
      height: 3.53009vw;
      padding: 0.92593vw 1.38889vw;
      font-size: 1.21528vw;
      font-weight: 900;
      background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-button-3d-inner-container {
      border-radius: 2.31481vw;
      width: auto;
      width: initial;
      height: 3.53009vw;
      padding: 0.92593vw 1.38889vw;
      font-size: 1.21528vw;
      font-weight: 900;
      background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px); } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-button-3d-inner-container {
      padding: 0 6vw; }
      .photon-button-3d-inner-container else {
        border-radius: 13vw; }
      .photon-button-3d-inner-container else {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
      .photon-button-3d-inner-container else {
        height: 12vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-button-3d-inner-container {
      padding: 0 6vw; }
      .photon-button-3d-inner-container else {
        border-radius: 13vw; }
      .photon-button-3d-inner-container else {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
      .photon-button-3d-inner-container else {
        height: 12vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-button-3d-inner-container {
      padding: 0 6vw; }
      .photon-button-3d-inner-container else {
        border-radius: 13vw; }
      .photon-button-3d-inner-container else {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
      .photon-button-3d-inner-container else {
        height: 12vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-button-3d-inner-container {
      padding: 0 6vw; }
      .photon-button-3d-inner-container else {
        border-radius: 13vw; }
      .photon-button-3d-inner-container else {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
      .photon-button-3d-inner-container else {
        height: 12vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-button-3d-inner-container {
      padding: 0 6vw; }
      .photon-button-3d-inner-container else {
        border-radius: 13vw; }
      .photon-button-3d-inner-container else {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; }
      .photon-button-3d-inner-container else {
        height: 12vw; } }

.photon-button-loading,
.photon-button-loading:after {
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em; }

.photon-button-loading {
  font-size: 6px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  margin-right: 2vw;
  margin-left: -4vw; }
  @media (min-width: 1300px) {
    .photon-button-loading {
      margin-right: 0.36458vw;
      margin-left: 0;
      margin-left: initial; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .photon-button-loading {
      margin-right: 0.40509vw;
      margin-left: 0;
      margin-left: initial; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .photon-button-loading {
      margin-right: 0.40509vw;
      margin-left: 0;
      margin-left: initial; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .photon-button-loading else {
      margin-right: 2vw; }
    .photon-button-loading else {
      margin-left: -4vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .photon-button-loading else {
      margin-right: 2vw; }
    .photon-button-loading else {
      margin-left: -4vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .photon-button-loading else {
      margin-right: 2vw; }
    .photon-button-loading else {
      margin-left: -4vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .photon-button-loading else {
      margin-right: 2vw; }
    .photon-button-loading else {
      margin-left: -4vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .photon-button-loading else {
      margin-right: 2vw; }
    .photon-button-loading else {
      margin-left: -4vw; } }

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

:root {
  --accent-color:$tekie-amethyst;
  --accent-color-110: $tekie-amethyst-dark; }

:root {
  --accent-color:$tekie-amethyst;
  --accent-color-110: $tekie-amethyst-dark; }

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  color: #282828;
  z-index: 999; }

.Spinner {
  /* Spins external container */
  -webkit-animation: container-rotate 2000ms linear infinite;
          animation: container-rotate 2000ms linear infinite;
  z-index: 999999999999; }

.Spinner-line {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0; }

.Spinner-line--animation {
  border-color: $tekie-amethyst;
  border-color: var(--accent-color);
  -webkit-animation: fill-unfill-rotate 6000ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, line-1-fade-in-out 6000ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
          animation: fill-unfill-rotate 6000ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, line-1-fade-in-out 6000ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.Spinner-line-cog {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit; }

.Spinner-line-cog-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 200%;
  box-sizing: border-box;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent;
  border-radius: 50%;
  -webkit-animation: none;
          animation: none; }

.Spinner-line-cog-inner--left {
  border-right-color: transparent;
  -webkit-transform: rotate(129deg);
          transform: rotate(129deg);
  -webkit-animation: left-spin 1500ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
          animation: left-spin 1500ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.Spinner-line-cog-inner--right {
  left: -100%;
  border-left-color: transparent;
  -webkit-transform: rotate(-129deg);
          transform: rotate(-129deg);
  -webkit-animation: right-spin 1500ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
          animation: right-spin 1500ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.Spinner-line-cog-inner--center {
  width: 1000%;
  left: -450%; }

.Spinner-line-ticker {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit; }

@-webkit-keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  25% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  37.5% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg); }
  50% {
    -webkit-transform: rotate(540deg);
            transform: rotate(540deg); }
  62.5% {
    -webkit-transform: rotate(675deg);
            transform: rotate(675deg); }
  75% {
    -webkit-transform: rotate(810deg);
            transform: rotate(810deg); }
  87.5% {
    -webkit-transform: rotate(945deg);
            transform: rotate(945deg); }
  to {
    -webkit-transform: rotate(1080deg);
            transform: rotate(1080deg); } }

@keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  25% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  37.5% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg); }
  50% {
    -webkit-transform: rotate(540deg);
            transform: rotate(540deg); }
  62.5% {
    -webkit-transform: rotate(675deg);
            transform: rotate(675deg); }
  75% {
    -webkit-transform: rotate(810deg);
            transform: rotate(810deg); }
  87.5% {
    -webkit-transform: rotate(945deg);
            transform: rotate(945deg); }
  to {
    -webkit-transform: rotate(1080deg);
            transform: rotate(1080deg); } }

@-webkit-keyframes left-spin {
  0% {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg); }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  to {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg); } }

@keyframes left-spin {
  0% {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg); }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  to {
    -webkit-transform: rotate(130deg);
            transform: rotate(130deg); } }

@-webkit-keyframes right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg); }
  50% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  to {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg); } }

@keyframes right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg); }
  50% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  to {
    -webkit-transform: rotate(-130deg);
            transform: rotate(-130deg); } }

@-webkit-keyframes line-1-fade-in-out {
  0% {
    opacity: 1; }
  25% {
    opacity: 1; }
  26% {
    opacity: 1; }
  89% {
    opacity: 1; }
  90% {
    opacity: 1; }
  to {
    opacity: 1; } }

@keyframes line-1-fade-in-out {
  0% {
    opacity: 1; }
  25% {
    opacity: 1; }
  26% {
    opacity: 1; }
  89% {
    opacity: 1; }
  90% {
    opacity: 1; }
  to {
    opacity: 1; } }

.spinner {
  -webkit-animation: rotator 1.8s linear infinite;
          animation: rotator 1.8s linear infinite; }

@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); } }

@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); } }

.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
          transform-origin: center;
  stroke: #8C61CB;
  -webkit-animation: dash 1.8s ease-in-out infinite;
          animation: dash 1.8s ease-in-out infinite; }

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); } }

@keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); } }

.cp-spinner {
  width: 48px;
  height: 48px;
  display: inline-block;
  box-sizing: border-box;
  position: relative; }

.cp-round:before {
  border-radius: 50%;
  content: " ";
  width: 48px;
  height: 48px;
  display: inline-block;
  box-sizing: border-box;
  border-top: solid 6px rgba(172, 171, 171, 0.2);
  border-right: solid 6px rgba(172, 171, 171, 0.2);
  border-bottom: solid 6px rgba(172, 171, 171, 0.2);
  border-left: solid 6px rgba(172, 171, 171, 0.2);
  position: absolute;
  top: 0;
  left: 0; }

.cp-round:after {
  border-radius: 50%;
  content: " ";
  width: 48px;
  height: 48px;
  display: inline-block;
  box-sizing: border-box;
  border-top: solid 6px #00ADE6;
  border-right: solid 6px transparent;
  border-bottom: solid 6px transparent;
  border-left: solid 6px transparent;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: cp-round-animate 1s ease-in-out infinite;
          animation: cp-round-animate 1s ease-in-out infinite; }

@-webkit-keyframes cp-round-animate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes cp-round-animate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.UpdatedButton_updatedButton__33UQV {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito';
  cursor: pointer;
  box-sizing: border-box;
  padding: 2.41546vw 3.86473vw;
  border-radius: 4.83092vw;
  border: 0.48309vw solid #00ADE6;
  box-shadow: 0vw 0.96618vw 0vw #00ADE6;
  height: 13.28502vw; }
  .UpdatedButton_updatedButton__33UQV span {
    font-style: normal;
    display: flex;
    align-items: center;
    font-size: 3.86473vw;
    line-height: 5.31401vw;
    font-weight: 700; }
    @media (min-width: 1300px) {
      .UpdatedButton_updatedButton__33UQV span {
        font-size: 1.04167vw;
        line-height: 1.14583vw;
        font-weight: 700; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .UpdatedButton_updatedButton__33UQV span {
        font-size: 1.15741vw;
        line-height: 1.27315vw;
        font-weight: 700; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .UpdatedButton_updatedButton__33UQV span {
        font-size: 1.15741vw;
        line-height: 1.27315vw;
        font-weight: 700; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .UpdatedButton_updatedButton__33UQV span {
        font-size: 3.22061vw;
        line-height: 4.42834vw; }
        .UpdatedButton_updatedButton__33UQV span else {
          font-size: 16px; }
        .UpdatedButton_updatedButton__33UQV span else {
          line-height: 22px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .UpdatedButton_updatedButton__33UQV span {
        font-size: 2.57649vw;
        line-height: 3.54267vw; }
        .UpdatedButton_updatedButton__33UQV span else {
          font-size: 16px; }
        .UpdatedButton_updatedButton__33UQV span else {
          line-height: 22px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .UpdatedButton_updatedButton__33UQV span {
        font-size: 2.27337vw;
        line-height: 3.12589vw; }
        .UpdatedButton_updatedButton__33UQV span else {
          font-size: 16px; }
        .UpdatedButton_updatedButton__33UQV span else {
          line-height: 22px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .UpdatedButton_updatedButton__33UQV span {
        font-size: 2.03407vw;
        line-height: 2.79685vw; }
        .UpdatedButton_updatedButton__33UQV span else {
          font-size: 16px; }
        .UpdatedButton_updatedButton__33UQV span else {
          line-height: 22px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .UpdatedButton_updatedButton__33UQV span {
        font-size: 1.84035vw;
        line-height: 2.53048vw; }
        .UpdatedButton_updatedButton__33UQV span else {
          font-size: 16px; }
        .UpdatedButton_updatedButton__33UQV span else {
          line-height: 22px; } }
  @media (min-width: 1300px) {
    .UpdatedButton_updatedButton__33UQV {
      border-radius: 1.04167vw;
      padding: 0.83333vw 1.25vw;
      border: 0.10417vw solid #00ADE6;
      box-shadow: 0vw 0.20833vw 0vw #00ADE6;
      height: 2.86458vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .UpdatedButton_updatedButton__33UQV {
      border-radius: 1.15741vw;
      padding: 0.92593vw 1.38889vw;
      border: 0.11574vw solid #00ADE6;
      box-shadow: 0vw 0.23148vw 0vw #00ADE6;
      height: 3.18287vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .UpdatedButton_updatedButton__33UQV {
      border-radius: 1.15741vw;
      padding: 0.92593vw 1.38889vw;
      border: 0.11574vw solid #00ADE6;
      box-shadow: 0vw 0.23148vw 0vw #00ADE6;
      height: 3.18287vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .UpdatedButton_updatedButton__33UQV {
      padding: 2.01288vw 3.22061vw;
      border-radius: 4.02576vw;
      border: 0.40258vw solid #00ADE6;
      box-shadow: 0vw 0.80515vw 0vw #00ADE6;
      height: 11.07085vw; }
      .UpdatedButton_updatedButton__33UQV else {
        border-radius: 20px; }
      .UpdatedButton_updatedButton__33UQV else {
        height: 55px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .UpdatedButton_updatedButton__33UQV {
      padding: 1.61031vw 2.57649vw;
      border-radius: 3.22061vw;
      border: 0.32206vw solid #00ADE6;
      box-shadow: 0vw 0.64412vw 0vw #00ADE6;
      height: 8.85668vw; }
      .UpdatedButton_updatedButton__33UQV else {
        border-radius: 20px; }
      .UpdatedButton_updatedButton__33UQV else {
        height: 55px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .UpdatedButton_updatedButton__33UQV {
      padding: 1.42086vw 2.27337vw;
      border-radius: 2.84172vw;
      border: 0.28417vw solid #00ADE6;
      box-shadow: 0vw 0.56834vw 0vw #00ADE6;
      height: 7.81472vw; }
      .UpdatedButton_updatedButton__33UQV else {
        border-radius: 20px; }
      .UpdatedButton_updatedButton__33UQV else {
        height: 55px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .UpdatedButton_updatedButton__33UQV {
      padding: 1.27129vw 2.03407vw;
      border-radius: 2.54259vw;
      border: 0.25426vw solid #00ADE6;
      box-shadow: 0vw 0.50852vw 0vw #00ADE6;
      height: 6.99212vw; }
      .UpdatedButton_updatedButton__33UQV else {
        border-radius: 20px; }
      .UpdatedButton_updatedButton__33UQV else {
        height: 55px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .UpdatedButton_updatedButton__33UQV {
      padding: 1.15022vw 1.84035vw;
      border-radius: 2.30044vw;
      border: 0.23004vw solid #00ADE6;
      box-shadow: 0vw 0.46009vw 0vw #00ADE6;
      height: 6.3262vw; }
      .UpdatedButton_updatedButton__33UQV else {
        border-radius: 20px; }
      .UpdatedButton_updatedButton__33UQV else {
        height: 55px; } }
  .UpdatedButton_updatedButton__33UQV svg {
    width: 5.7971vw;
    height: 5.7971vw; }
    @media (min-width: 1300px) {
      .UpdatedButton_updatedButton__33UQV svg {
        width: 1.25vw;
        height: 1.25vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .UpdatedButton_updatedButton__33UQV svg {
        width: 1.38889vw;
        height: 1.38889vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .UpdatedButton_updatedButton__33UQV svg {
        width: 1.38889vw;
        height: 1.38889vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .UpdatedButton_updatedButton__33UQV svg {
        width: 4.83092vw;
        height: 4.83092vw; }
        .UpdatedButton_updatedButton__33UQV svg else {
          width: 24px; }
        .UpdatedButton_updatedButton__33UQV svg else {
          height: 24px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .UpdatedButton_updatedButton__33UQV svg {
        width: 3.86473vw;
        height: 3.86473vw; }
        .UpdatedButton_updatedButton__33UQV svg else {
          width: 24px; }
        .UpdatedButton_updatedButton__33UQV svg else {
          height: 24px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .UpdatedButton_updatedButton__33UQV svg {
        width: 3.41006vw;
        height: 3.41006vw; }
        .UpdatedButton_updatedButton__33UQV svg else {
          width: 24px; }
        .UpdatedButton_updatedButton__33UQV svg else {
          height: 24px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .UpdatedButton_updatedButton__33UQV svg {
        width: 3.05111vw;
        height: 3.05111vw; }
        .UpdatedButton_updatedButton__33UQV svg else {
          width: 24px; }
        .UpdatedButton_updatedButton__33UQV svg else {
          height: 24px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .UpdatedButton_updatedButton__33UQV svg {
        width: 2.76052vw;
        height: 2.76052vw; }
        .UpdatedButton_updatedButton__33UQV svg else {
          width: 24px; }
        .UpdatedButton_updatedButton__33UQV svg else {
          height: 24px; } }

.UpdatedButton_primary__3LG3_ {
  grid-gap: 16px;
  gap: 16px;
  background: linear-gradient(287.49deg, #00ADE6 38.78%, #29C8FC 98.2%);
  color: white;
  font-family: 'Nunito';
  font-weight: 700;
  cursor: pointer;
  padding: 2.41546vw 3.86473vw;
  box-shadow: 0vw 0.96618vw 0vw #007FA9; }
  .UpdatedButton_primary__3LG3_:active {
    box-shadow: none;
    -webkit-transform: translateY(3px);
            transform: translateY(3px); }
  .UpdatedButton_primary__3LG3_:disabled {
    background: #E2E2E2;
    box-shadow: none;
    color: #A8A7A7; }
  @media (min-width: 1300px) {
    .UpdatedButton_primary__3LG3_ {
      padding: 0.625vw 1.25vw;
      box-shadow: 0vw 0.20833vw 0vw #007FA9; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .UpdatedButton_primary__3LG3_ {
      padding: 0.69444vw 1.38889vw;
      box-shadow: 0vw 0.23148vw 0vw #007FA9; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .UpdatedButton_primary__3LG3_ {
      padding: 0.69444vw 1.38889vw;
      box-shadow: 0vw 0.23148vw 0vw #007FA9; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .UpdatedButton_primary__3LG3_ {
      padding: 2.01288vw 3.22061vw;
      box-shadow: 0vw 0.80515vw 0vw #007FA9; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .UpdatedButton_primary__3LG3_ {
      padding: 1.61031vw 2.57649vw;
      box-shadow: 0vw 0.64412vw 0vw #007FA9; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .UpdatedButton_primary__3LG3_ {
      padding: 1.42086vw 2.27337vw;
      box-shadow: 0vw 0.56834vw 0vw #007FA9; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .UpdatedButton_primary__3LG3_ {
      padding: 1.27129vw 2.03407vw;
      box-shadow: 0vw 0.50852vw 0vw #007FA9; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .UpdatedButton_primary__3LG3_ {
      padding: 1.15022vw 1.84035vw;
      box-shadow: 0vw 0.46009vw 0vw #007FA9; } }

.UpdatedButton_secondary__HUY0_ {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito';
  cursor: pointer;
  box-sizing: border-box;
  background: #FFFFFF; }
  .UpdatedButton_secondary__HUY0_ span {
    color: #00ADE6; }
  .UpdatedButton_secondary__HUY0_:active {
    box-shadow: none;
    -webkit-transform: translateY(3px);
            transform: translateY(3px); }
  .UpdatedButton_secondary__HUY0_:disabled {
    background: #E2E2E2;
    box-shadow: none;
    color: #A8A7A7; }

.UpdatedButton_disabled__1rE62 {
  border: none;
  background: #E2E2E2;
  box-shadow: none;
  cursor: not-allowed; }
  .UpdatedButton_disabled__1rE62 span {
    color: #A8A7A7; }

.UpdatedButton_reportButton__3Pm0q span {
  width: 48.30918vw; }
  @media (min-width: 1300px) {
    .UpdatedButton_reportButton__3Pm0q span {
      width: 10.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .UpdatedButton_reportButton__3Pm0q span {
      width: 11.57407vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .UpdatedButton_reportButton__3Pm0q span {
      width: 11.57407vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .UpdatedButton_reportButton__3Pm0q span {
      width: 40.25765vw; }
      .UpdatedButton_reportButton__3Pm0q span else {
        width: 200px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .UpdatedButton_reportButton__3Pm0q span {
      width: 32.20612vw; }
      .UpdatedButton_reportButton__3Pm0q span else {
        width: 200px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .UpdatedButton_reportButton__3Pm0q span {
      width: 28.41716vw; }
      .UpdatedButton_reportButton__3Pm0q span else {
        width: 200px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .UpdatedButton_reportButton__3Pm0q span {
      width: 25.42588vw; }
      .UpdatedButton_reportButton__3Pm0q span else {
        width: 200px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .UpdatedButton_reportButton__3Pm0q span {
      width: 23.00437vw; }
      .UpdatedButton_reportButton__3Pm0q span else {
        width: 200px; } }

.UpdatedButton_widthFull__1jYYx {
  width: 100%; }
  @media (min-width: 1300px) {
    .UpdatedButton_widthFull__1jYYx {
      width: 100%; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .UpdatedButton_widthFull__1jYYx {
      width: 100%; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .UpdatedButton_widthFull__1jYYx {
      width: 100%; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .UpdatedButton_widthFull__1jYYx else {
      width: 100%; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .UpdatedButton_widthFull__1jYYx else {
      width: 100%; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .UpdatedButton_widthFull__1jYYx else {
      width: 100%; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .UpdatedButton_widthFull__1jYYx else {
      width: 100%; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .UpdatedButton_widthFull__1jYYx else {
      width: 100%; } }

.UpdatedButton_updatedBtn2__3PeuZ {
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito';
  font-weight: 700;
  cursor: pointer;
  box-sizing: border-box;
  color: white;
  border: none; }
  @media (min-width: 1300px) {
    .UpdatedButton_updatedBtn2__3PeuZ {
      padding: 0.72917vw 1.14583vw;
      grid-gap: 0.625vw;
      gap: 0.625vw;
      font-size: 0.9375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .UpdatedButton_updatedBtn2__3PeuZ {
      padding: 0.81019vw 1.27315vw;
      grid-gap: 0.69444vw;
      gap: 0.69444vw;
      font-size: 1.04167vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .UpdatedButton_updatedBtn2__3PeuZ {
      padding: 0.81019vw 1.27315vw;
      grid-gap: 0.69444vw;
      gap: 0.69444vw;
      font-size: 1.04167vw; } }

.UpdatedButton_dangerBtn2__1GhXQ {
  text-shadow: 0px 2px rgba(0, 0, 0, 0.25);
  background: conic-gradient(from -3.29deg at 100% -13%, #FF5744 0deg, #FF6A59 360deg);
  border-radius: 50px;
  box-shadow: 0px 7px 0px #E25B4D; }

.UpdatedButton_dangerBtn2__1GhXQ:active {
  box-shadow: 0px 2px 0px #E25B4D;
  -webkit-transform: translateY(3px);
          transform: translateY(3px); }

.UpdatedButton_dangerBtnWrapper__3yegb {
  border: none;
  background: conic-gradient(from -3.29deg at 100% -13%, #FF5744 0deg, #FF6A59 360deg);
  border-radius: 50px;
  box-shadow: 0px 7px 0px #E25B4D; }

.UpdatedButton_dangerBtnWrapper__3yegb:active {
  box-shadow: 0px 2px 0px #E25B4D;
  -webkit-transform: translateY(3px);
          transform: translateY(3px); }

.UpdatedButton_dangerBtnWrapper__3yegb:hover {
  box-shadow: 0px 5px 14px #F97263; }

.UpdatedButton_noSpace__3b-Y4 {
  padding: 0;
  border: none; }

.UpdatedButton_noSpace__3b-Y4:hover {
  box-shadow: 0px 5px 14px #F97263; }

.UpdatedButton_noSpace__3b-Y4:active {
  box-shadow: 0px 2px 0px #E25B4D;
  -webkit-transform: translateY(3px);
          transform: translateY(3px); }

.UpdatedButton_dangerBtnWrapper__3yegb:disabled {
  background: conic-gradient(from -3.29deg at 100% -13%, #DBDBDB 0deg, #A6A6A6 360deg);
  box-shadow: 0px 7px 0px rgba(194, 194, 194, 0.5);
  pointer-events: none; }

.UpdatedButton_dangerBtn2__1GhXQ:disabled {
  background: conic-gradient(from -3.29deg at 100% -13%, #DBDBDB 0deg, #A6A6A6 360deg);
  box-shadow: 0px 7px 0px #808080; }

.UpdatedButton_noSpace__3b-Y4:disabled {
  background: conic-gradient(from -3.29deg at 100% -13%, #DBDBDB 0deg, #A6A6A6 360deg);
  box-shadow: 0px 7px 0px rgba(194, 194, 194, 0.5);
  pointer-events: none;
  box-shadow: 0px 7px 0px #808080; }

.UpdatedButton_dangerDisabled__2atZG {
  background: conic-gradient(from -3.29deg at 100% -13%, #DBDBDB 0deg, #A6A6A6 360deg);
  box-shadow: 0px 0px 30px rgba(194, 194, 194, 0.5), inset 0px 0px 8px rgba(179, 179, 179, 0.8);
  box-shadow: inset 0px 0px 8px #C2C2C2;
  pointer-events: none; }

.mentor-label {
  font-family: Nunito;
  color: #DCDCDC;
  color: rgba(17, 17, 17, 0.48);
  opacity: 0.7;
  font-size: 2.96296vw;
  margin-top: 2.22222vw;
  margin-bottom: 1.85185vw; }
  .mentor-label.error {
    opacity: 1;
    color: #FF5744; }
  @media (min-width: 1300px) {
    .mentor-label {
      font-size: 0.83333vw;
      margin-bottom: 0.52083vw;
      margin-top: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-label {
      font-size: 0.92593vw;
      margin-bottom: 0.5787vw;
      margin-top: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-label {
      font-size: 0.92593vw;
      margin-bottom: 0.5787vw;
      margin-top: unset; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-label {
      font-size: 2.46914vw;
      margin-top: 1.85185vw;
      margin-bottom: 1.54321vw; }
      .mentor-label else {
        font-size: 16px; }
      .mentor-label else {
        margin-top: 12px; }
      .mentor-label else {
        margin-bottom: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-label {
      font-size: 1.97531vw;
      margin-top: 1.48148vw;
      margin-bottom: 1.23457vw; }
      .mentor-label else {
        font-size: 16px; }
      .mentor-label else {
        margin-top: 12px; }
      .mentor-label else {
        margin-bottom: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-label {
      font-size: 1.74292vw;
      margin-top: 1.30719vw;
      margin-bottom: 1.08932vw; }
      .mentor-label else {
        font-size: 16px; }
      .mentor-label else {
        margin-top: 12px; }
      .mentor-label else {
        margin-bottom: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-label {
      font-size: 1.55945vw;
      margin-top: 1.16959vw;
      margin-bottom: 0.97466vw; }
      .mentor-label else {
        font-size: 16px; }
      .mentor-label else {
        margin-top: 12px; }
      .mentor-label else {
        margin-bottom: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-label {
      font-size: 1.41093vw;
      margin-top: 1.0582vw;
      margin-bottom: 0.88183vw; }
      .mentor-label else {
        font-size: 16px; }
      .mentor-label else {
        margin-top: 12px; }
      .mentor-label else {
        margin-bottom: 10px; } }

.mentor-input-tooltip {
  background-color: #bff7f9 !important;
  font-weight: 600 !important; }
  @media (min-width: 1300px) {
    .mentor-input-tooltip {
      font-size: 0.83333vw !important;
      padding-left: 0.52083vw !important;
      padding-right: 0.52083vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-input-tooltip {
      font-size: 0.92593vw !important;
      padding-left: 0.5787vw !important;
      padding-right: 0.5787vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-input-tooltip {
      font-size: 0.92593vw !important;
      padding-left: 0.5787vw !important;
      padding-right: 0.5787vw !important; } }

.mentor-input {
  width: 100% !important;
  background: #FFFFFF !important;
  font-family: Nunito !important;
  box-sizing: border-box !important;
  font-weight: 600 !important;
  transition: 0.3s all ease-in-out;
  border: 0.18519vw solid #C4C4C4 !important;
  border-radius: 1.11111vw !important;
  height: 10.37037vw !important;
  padding: 2.96296vw !important;
  font-size: 3.7037vw !important;
  width: 100% !important;
  color: #504F4F !important; }
  @media (min-width: 1300px) {
    .mentor-input {
      border: 0.05208vw solid #C4C4C4 !important;
      border-radius: 0.3125vw !important;
      height: 2.91667vw !important;
      padding: 0.83333vw !important;
      font-size: 1.04167vw !important;
      width: 100% !important;
      color: #504F4F !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-input {
      border: 0.05787vw solid #C4C4C4 !important;
      border-radius: 0.34722vw !important;
      height: 3.24074vw !important;
      padding: 0.92593vw !important;
      font-size: 1.15741vw !important;
      width: 100% !important;
      color: #504F4F !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-input {
      border: 0.05787vw solid #C4C4C4 !important;
      border-radius: 0.34722vw !important;
      height: 3.24074vw !important;
      padding: 0.92593vw !important;
      font-size: 1.15741vw !important;
      width: 100% !important;
      color: #504F4F !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-input {
      border: 0.15432vw solid #C4C4C4 !important;
      border-radius: 0.92593vw !important;
      height: 8.64198vw !important;
      padding: 2.46914vw !important;
      font-size: 3.08642vw !important;
      width: 100% !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-input {
      border: 0.12346vw solid #C4C4C4 !important;
      border-radius: 0.74074vw !important;
      height: 6.91358vw !important;
      padding: 1.97531vw !important;
      font-size: 2.46914vw !important;
      width: 100% !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-input {
      border: 0.10893vw solid #C4C4C4 !important;
      border-radius: 0.65359vw !important;
      height: 6.10022vw !important;
      padding: 1.74292vw !important;
      font-size: 2.17865vw !important;
      width: 100% !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-input {
      border: 0.09747vw solid #C4C4C4 !important;
      border-radius: 0.5848vw !important;
      height: 5.45809vw !important;
      padding: 1.55945vw !important;
      font-size: 1.94932vw !important;
      width: 100% !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-input {
      border: 0.08818vw solid #C4C4C4 !important;
      border-radius: 0.5291vw !important;
      height: 4.93827vw !important;
      padding: 1.41093vw !important;
      font-size: 1.76367vw !important;
      width: 100% !important; } }
  .mentor-input.error {
    border: 0.18519vw solid #FF5744 !important; }
    @media (min-width: 1300px) {
      .mentor-input.error {
        border: 0.05208vw solid #FF5744 !important; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .mentor-input.error {
        border: 0.05787vw solid #FF5744 !important; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .mentor-input.error {
        border: 0.05787vw solid #FF5744 !important; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .mentor-input.error {
        border: 0.15432vw solid #FF5744 !important; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .mentor-input.error {
        border: 0.12346vw solid #FF5744 !important; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .mentor-input.error {
        border: 0.10893vw solid #FF5744 !important; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .mentor-input.error {
        border: 0.09747vw solid #FF5744 !important; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .mentor-input.error {
        border: 0.08818vw solid #FF5744 !important; } }
  .mentor-input.disabled {
    opacity: 0.6;
    cursor: not-allowed; }
  .mentor-input:focus {
    box-shadow: 0 0 0 1px #00ADE6 !important;
    border: 1px solid transparent !important;
    outline: none !important; }
  .mentor-input:focus-visible {
    box-shadow: 0 0 0 1px #00ADE6 !important;
    border: 1px solid transparent !important;
    outline: none !important; }
  .mentor-input::-webkit-input-placeholder {
    color: rgba(17, 17, 17, 0.48) !important;
    opacity: 0.7 !important; }
  .mentor-input:-ms-input-placeholder {
    color: rgba(17, 17, 17, 0.48) !important;
    opacity: 0.7 !important; }
  .mentor-input::placeholder {
    color: rgba(17, 17, 17, 0.48) !important;
    opacity: 0.7 !important; }

.mentor-phone-input {
  margin-left: 11.83575vw !important;
  width: 87% !important;
  border-radius: 0 1.44928vw 1.44928vw 0 !important;
  z-index: 2 !important; }
  @media (min-width: 1300px) {
    .mentor-phone-input {
      margin-left: 3.59375vw !important;
      width: 83.5% !important;
      border-radius: 0 0.3125vw 0.3125vw 0 !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-phone-input {
      margin-left: 3.99306vw !important;
      width: 83.5% !important;
      border-radius: 0 0.34722vw 0.34722vw 0 !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-phone-input {
      margin-left: 3.99306vw !important;
      width: 83.5% !important;
      border-radius: 0 0.34722vw 0.34722vw 0 !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-phone-input {
      margin-left: 9.86312vw !important;
      width: 87% !important;
      border-radius: 0 1.20773vw 1.20773vw 0 !important; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-phone-input {
      margin-left: 7.8905vw !important;
      width: 87% !important;
      border-radius: 0 0.96618vw 0.96618vw 0 !important; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-phone-input {
      margin-left: 6.96221vw !important;
      width: 87% !important;
      border-radius: 0 0.85251vw 0.85251vw 0 !important; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-phone-input {
      margin-left: 6.22934vw !important;
      width: 87% !important;
      border-radius: 0 0.76278vw 0.76278vw 0 !important; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-phone-input {
      margin-left: 5.63607vw !important;
      width: 87% !important;
      border-radius: 0 0.69013vw 0.69013vw 0 !important; } }
  @media (min-width: 1300px) {
    .mentor-phone-input:focus, .mentor-phone-input:focus-visible {
      border-radius: 0.3125vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-phone-input:focus, .mentor-phone-input:focus-visible {
      border-radius: 0.34722vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-phone-input:focus, .mentor-phone-input:focus-visible {
      border-radius: 0.34722vw !important; } }

.mentor-otp-container .mentor-otp-input {
  width: 10.37037vw !important;
  margin-right: 4.44444vw; }
  @media (min-width: 1300px) {
    .mentor-otp-container .mentor-otp-input {
      width: 2.91667vw !important;
      margin-right: 1.25vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-otp-container .mentor-otp-input {
      width: 3.24074vw !important;
      margin-right: 1.38889vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-otp-container .mentor-otp-input {
      width: 3.24074vw !important;
      margin-right: 1.38889vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-otp-container .mentor-otp-input {
      width: 8.64198vw !important;
      margin-right: 3.7037vw; }
      .mentor-otp-container .mentor-otp-input else {
        margin-right: 24px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-otp-container .mentor-otp-input {
      width: 6.91358vw !important;
      margin-right: 2.96296vw; }
      .mentor-otp-container .mentor-otp-input else {
        margin-right: 24px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-otp-container .mentor-otp-input {
      width: 6.10022vw !important;
      margin-right: 2.61438vw; }
      .mentor-otp-container .mentor-otp-input else {
        margin-right: 24px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-otp-container .mentor-otp-input {
      width: 5.45809vw !important;
      margin-right: 2.33918vw; }
      .mentor-otp-container .mentor-otp-input else {
        margin-right: 24px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-otp-container .mentor-otp-input {
      width: 4.93827vw !important;
      margin-right: 2.1164vw; }
      .mentor-otp-container .mentor-otp-input else {
        margin-right: 24px; } }

@media (min-width: 1300px) {
  .mentor-otp-container:last-child .mentor-otp-input {
    margin-right: 0vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .mentor-otp-container:last-child .mentor-otp-input {
    margin-right: 0vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .mentor-otp-container:last-child .mentor-otp-input {
    margin-right: 0vw; } }

.mentor-button {
  background: #00ADE6;
  font-family: Nunito;
  font-weight: 600;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2.96296vw 3.33333vw;
  border-radius: 0.74074vw;
  font-family: 22px;
  color: white;
  transition: 0.3s all ease-in-out; }
  @media (min-width: 1300px) {
    .mentor-button {
      padding: 0.83333vw 0.9375vw;
      border-radius: 0.20833vw;
      font-family: 22px; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-button {
      padding: 0.92593vw 1.04167vw;
      border-radius: 0.23148vw;
      font-family: 22px; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-button {
      padding: 0.92593vw 1.04167vw;
      border-radius: 0.23148vw;
      font-family: 22px; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-button {
      padding: 2.46914vw 2.77778vw;
      border-radius: 0.61728vw; }
      .mentor-button else {
        border-radius: 4px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-button {
      padding: 1.97531vw 2.22222vw;
      border-radius: 0.49383vw; }
      .mentor-button else {
        border-radius: 4px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-button {
      padding: 1.74292vw 1.96078vw;
      border-radius: 0.43573vw; }
      .mentor-button else {
        border-radius: 4px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-button {
      padding: 1.55945vw 1.75439vw;
      border-radius: 0.38986vw; }
      .mentor-button else {
        border-radius: 4px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-button {
      padding: 1.41093vw 1.5873vw;
      border-radius: 0.35273vw; }
      .mentor-button else {
        border-radius: 4px; } }
  .mentor-button div:first-child {
    pointer-events: none; }
  .mentor-button:hover {
    opacity: 0.8; }
  .mentor-button:hover .mentor-button-arrowContainer {
    -webkit-transform: scale(-1) translateX(-3px);
            transform: scale(-1) translateX(-3px); }
  .mentor-button.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed; }

.mentor-button-arrowContainer {
  width: 1.94444vw;
  height: 2.77778vw;
  margin-left: 1.85185vw;
  transition: 0.3s all ease-in-out;
  background: url(/static/media/arrow.fa095bde.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transform: scale(-1);
          transform: scale(-1); }
  @media (min-width: 1300px) {
    .mentor-button-arrowContainer {
      width: 0.54688vw;
      height: 0.78125vw;
      margin-left: 0.67708vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-button-arrowContainer {
      width: 0.60764vw;
      height: 0.86806vw;
      margin-left: 0.75231vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-button-arrowContainer {
      width: 0.60764vw;
      height: 0.86806vw;
      margin-left: 0.75231vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-button-arrowContainer {
      width: 1.62037vw;
      height: 2.31481vw;
      margin-left: 1.54321vw; }
      .mentor-button-arrowContainer else {
        width: 10.5px; }
      .mentor-button-arrowContainer else {
        height: 15px; }
      .mentor-button-arrowContainer else {
        margin-left: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-button-arrowContainer {
      width: 1.2963vw;
      height: 1.85185vw;
      margin-left: 1.23457vw; }
      .mentor-button-arrowContainer else {
        width: 10.5px; }
      .mentor-button-arrowContainer else {
        height: 15px; }
      .mentor-button-arrowContainer else {
        margin-left: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-button-arrowContainer {
      width: 1.14379vw;
      height: 1.63399vw;
      margin-left: 1.08932vw; }
      .mentor-button-arrowContainer else {
        width: 10.5px; }
      .mentor-button-arrowContainer else {
        height: 15px; }
      .mentor-button-arrowContainer else {
        margin-left: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-button-arrowContainer {
      width: 1.02339vw;
      height: 1.46199vw;
      margin-left: 0.97466vw; }
      .mentor-button-arrowContainer else {
        width: 10.5px; }
      .mentor-button-arrowContainer else {
        height: 15px; }
      .mentor-button-arrowContainer else {
        margin-left: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-button-arrowContainer {
      width: 0.92593vw;
      height: 1.32275vw;
      margin-left: 0.88183vw; }
      .mentor-button-arrowContainer else {
        width: 10.5px; }
      .mentor-button-arrowContainer else {
        height: 15px; }
      .mentor-button-arrowContainer else {
        margin-left: 10px; } }

.mentor-link {
  color: #00ADE6;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  font-family: Nunito;
  cursor: pointer;
  font-size: 3.88889vw;
  font-weight: normal; }
  @media (min-width: 1300px) {
    .mentor-link {
      font-size: 0.83333vw;
      font-weight: bold; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-link {
      font-size: 0.92593vw;
      font-weight: bold; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-link {
      font-size: 0.92593vw;
      font-weight: bold; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-link {
      font-size: 3.24074vw; }
      .mentor-link else {
        font-size: 21px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-link {
      font-size: 2.59259vw; }
      .mentor-link else {
        font-size: 21px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-link {
      font-size: 2.28758vw; }
      .mentor-link else {
        font-size: 21px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-link {
      font-size: 2.04678vw; }
      .mentor-link else {
        font-size: 21px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-link {
      font-size: 1.85185vw; }
      .mentor-link else {
        font-size: 21px; } }

.mentor-input-password {
  position: relative;
  letter-spacing: 1.69082vw;
  padding-right: 10.86957vw !important; }
  @media (min-width: 1300px) {
    .mentor-input-password {
      letter-spacing: 0.52083vw;
      padding-right: 2.60417vw !important; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-input-password {
      letter-spacing: 0.5787vw;
      padding-right: 2.89352vw !important; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-input-password {
      letter-spacing: 0.5787vw;
      padding-right: 2.89352vw !important; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-input-password {
      letter-spacing: 1.40902vw;
      padding-right: 9.05797vw !important; }
      .mentor-input-password else {
        letter-spacing: 7px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-input-password {
      letter-spacing: 1.12721vw;
      padding-right: 7.24638vw !important; }
      .mentor-input-password else {
        letter-spacing: 7px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-input-password {
      letter-spacing: 0.9946vw;
      padding-right: 6.39386vw !important; }
      .mentor-input-password else {
        letter-spacing: 7px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-input-password {
      letter-spacing: 0.88991vw;
      padding-right: 5.72082vw !important; }
      .mentor-input-password else {
        letter-spacing: 7px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-input-password {
      letter-spacing: 0.80515vw;
      padding-right: 5.17598vw !important; }
      .mentor-input-password else {
        letter-spacing: 7px; } }

.mentor-input-password-eye-icon {
  position: absolute;
  opacity: 0.5;
  right: 0;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  top: 1.93237vw;
  letter-spacing: 2.41546vw;
  width: 6.28019vw;
  height: 6.28019vw;
  right: 2.41546vw; }
  .mentor-input-password-eye-icon:hover {
    opacity: 0.8; }
  @media (min-width: 1300px) {
    .mentor-input-password-eye-icon {
      top: 0.78125vw;
      letter-spacing: 0.52083vw;
      width: 1.45833vw;
      height: 1.45833vw;
      right: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-input-password-eye-icon {
      top: 0.86806vw;
      letter-spacing: 0.5787vw;
      width: 1.62037vw;
      height: 1.62037vw;
      right: 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-input-password-eye-icon {
      top: 0.86806vw;
      letter-spacing: 0.5787vw;
      width: 1.62037vw;
      height: 1.62037vw;
      right: 0.5787vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-input-password-eye-icon {
      top: 1.61031vw;
      letter-spacing: 2.01288vw;
      width: 5.23349vw;
      height: 5.23349vw;
      right: 2.01288vw; }
      .mentor-input-password-eye-icon else {
        top: 8px; }
      .mentor-input-password-eye-icon else {
        letter-spacing: 10px; }
      .mentor-input-password-eye-icon else {
        width: 26px; }
      .mentor-input-password-eye-icon else {
        height: 26px; }
      .mentor-input-password-eye-icon else {
        right: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-input-password-eye-icon {
      top: 1.28824vw;
      letter-spacing: 1.61031vw;
      width: 4.1868vw;
      height: 4.1868vw;
      right: 1.61031vw; }
      .mentor-input-password-eye-icon else {
        top: 8px; }
      .mentor-input-password-eye-icon else {
        letter-spacing: 10px; }
      .mentor-input-password-eye-icon else {
        width: 26px; }
      .mentor-input-password-eye-icon else {
        height: 26px; }
      .mentor-input-password-eye-icon else {
        right: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-input-password-eye-icon {
      top: 1.13669vw;
      letter-spacing: 1.42086vw;
      width: 3.69423vw;
      height: 3.69423vw;
      right: 1.42086vw; }
      .mentor-input-password-eye-icon else {
        top: 8px; }
      .mentor-input-password-eye-icon else {
        letter-spacing: 10px; }
      .mentor-input-password-eye-icon else {
        width: 26px; }
      .mentor-input-password-eye-icon else {
        height: 26px; }
      .mentor-input-password-eye-icon else {
        right: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-input-password-eye-icon {
      top: 1.01704vw;
      letter-spacing: 1.27129vw;
      width: 3.30536vw;
      height: 3.30536vw;
      right: 1.27129vw; }
      .mentor-input-password-eye-icon else {
        top: 8px; }
      .mentor-input-password-eye-icon else {
        letter-spacing: 10px; }
      .mentor-input-password-eye-icon else {
        width: 26px; }
      .mentor-input-password-eye-icon else {
        height: 26px; }
      .mentor-input-password-eye-icon else {
        right: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-input-password-eye-icon {
      top: 0.92017vw;
      letter-spacing: 1.15022vw;
      width: 2.99057vw;
      height: 2.99057vw;
      right: 1.15022vw; }
      .mentor-input-password-eye-icon else {
        top: 8px; }
      .mentor-input-password-eye-icon else {
        letter-spacing: 10px; }
      .mentor-input-password-eye-icon else {
        width: 26px; }
      .mentor-input-password-eye-icon else {
        height: 26px; }
      .mentor-input-password-eye-icon else {
        right: 10px; } }

.mentor-phone-button {
  font-family: Nunito !important;
  background-color: white !important;
  transition: 0.3s all ease-in-out;
  width: 11.11111vw;
  font-size: 2.96296vw;
  border-right: 1px solid #C4C4C4; }
  @media (min-width: 1300px) {
    .mentor-phone-button {
      width: 3.64583vw;
      font-size: 0.9375vw;
      border-right: 1px solid #C4C4C4; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-phone-button {
      width: 4.05093vw;
      font-size: 1.04167vw;
      border-right: 1px solid #C4C4C4; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-phone-button {
      width: 4.05093vw;
      font-size: 1.04167vw;
      border-right: 1px solid #C4C4C4; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-phone-button {
      width: 9.25926vw;
      font-size: 2.46914vw; }
      .mentor-phone-button else {
        width: 60px; }
      .mentor-phone-button else {
        font-size: 16px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-phone-button {
      width: 7.40741vw;
      font-size: 1.97531vw; }
      .mentor-phone-button else {
        width: 60px; }
      .mentor-phone-button else {
        font-size: 16px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-phone-button {
      width: 6.53595vw;
      font-size: 1.74292vw; }
      .mentor-phone-button else {
        width: 60px; }
      .mentor-phone-button else {
        font-size: 16px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-phone-button {
      width: 5.84795vw;
      font-size: 1.55945vw; }
      .mentor-phone-button else {
        width: 60px; }
      .mentor-phone-button else {
        font-size: 16px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-phone-button {
      width: 5.29101vw;
      font-size: 1.41093vw; }
      .mentor-phone-button else {
        width: 60px; }
      .mentor-phone-button else {
        font-size: 16px; } }
  .mentor-phone-button.error {
    border: 1px solid #FF5744 !important; }
  .mentor-phone-button:hover {
    background-color: rgba(0, 173, 229, 0.2) !important; }
  .mentor-phone-button .react-tel-input {
    font-family: 'Lato', sans-serif;
    width: 100% !important; }
  .mentor-phone-button .selected-flag {
    width: 100% !important; }
    @media (min-width: 1300px) {
      .mentor-phone-button .selected-flag {
        padding-left: 0.9375vw !important; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .mentor-phone-button .selected-flag {
        padding-left: 1.04167vw !important; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .mentor-phone-button .selected-flag {
        padding-left: 1.04167vw !important; } }
    .mentor-phone-button .selected-flag:hover {
      background: transparent !important; }
  .mentor-phone-button .arrow {
    opacity: 0.4; }
  .mentor-phone-button .selected-flag.open {
    background: transparent !important; }
  .mentor-phone-button .country-list {
    background-color: #fff !important;
    overflow-y: auto !important; }
  .mentor-phone-button .flag-dropdown:focus {
    outline: none; }
  .mentor-phone-button .country {
    color: #222 !important;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .mentor-phone-button .country:hover {
      background-color: #fff !important;
      background-color: rgba(6, 179, 230, 0.3) !important; }
    .mentor-phone-button .country:focus {
      outline: none; }
  .mentor-phone-button .country.highlight {
    background-color: rgba(6, 179, 230, 0.7) !important;
    color: white !important;
    font-weight: 700;
    border: unset !important; }
    .mentor-phone-button .country.highlight .dial-code {
      color: white !important; }
  .mentor-phone-button .icon, .mentor-phone-button .flag.in, .mentor-phone-button .flag.ru, .mentor-phone-button .flag.af, .mentor-phone-button .flag.au, .mentor-phone-button .flag.sg, .mentor-phone-button .flag.us, .mentor-phone-button .flag.gb, .mentor-phone-button .flag.ae {
    background-repeat: no-repeat !important; }
  .mentor-phone-button .flag.in {
    background-image: url(/static/media/in.bdfaaf97.svg) !important;
    background-position: 0 0 !important; }
  .mentor-phone-button .flag.ru {
    background-image: url(/static/media/ru.e2037a0e.svg) !important;
    background-position: 0 0 !important; }
  .mentor-phone-button .flag.af {
    background-image: url(/static/media/af.d8fb0d22.svg) !important;
    background-position: 0 0 !important; }
  .mentor-phone-button .flag.au {
    background-image: url(/static/media/au.e875a51e.svg) !important;
    background-position: 0 0 !important; }
  .mentor-phone-button .flag.sg {
    background-image: url(/static/media/sg.6ae2dc5b.svg) !important;
    background-position: 0 0 !important; }
  .mentor-phone-button .flag.us {
    background-image: url(/static/media/us.50162f60.svg) !important;
    background-position: 0 0 !important; }
  .mentor-phone-button .flag.gb {
    background-image: url(/static/media/gb.c39480d5.svg) !important;
    background-position: 0 0 !important; }
  .mentor-phone-button .flag.ae {
    background-image: url(/static/media/ae.6d497f1c.svg) !important;
    background-position: 0 0 !important; }

.mentor-button-3d-shadow {
  box-shadow: inset 0px 0px 8px #34E4EA;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: 0.3s all ease-in-out;
  border-radius: 7.40741vw;
  height: 11.2963vw;
  margin-top: 1.48148vw;
  background: #0C718E; }
  .mentor-button-3d-shadow.disabled {
    box-shadow: none;
    background: #A8A7A7;
    cursor: default; }
    @media (min-width: 1300px) {
      .mentor-button-3d-shadow.disabled {
        box-shadow: none;
        background: #A8A7A7; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .mentor-button-3d-shadow.disabled {
        box-shadow: none;
        background: #A8A7A7; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .mentor-button-3d-shadow.disabled {
        box-shadow: none;
        background: #A8A7A7; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .mentor-button-3d-shadow.disabled else {
        box-shadow: none; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .mentor-button-3d-shadow.disabled else {
        box-shadow: none; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .mentor-button-3d-shadow.disabled else {
        box-shadow: none; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .mentor-button-3d-shadow.disabled else {
        box-shadow: none; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .mentor-button-3d-shadow.disabled else {
        box-shadow: none; } }
    .mentor-button-3d-shadow.disabled:hover .mentor-button-3d-inner-container {
      box-shadow: none; }
  .mentor-button-3d-shadow:hover .mentor-button-3d-inner-container {
    box-shadow: 0px 0px 30px rgba(53, 201, 233, 0.5), inset 0px 0px 8px rgba(0, 255, 240, 0.8), 0px 0px 15px rgba(0, 255, 240, 0.8); }
  .mentor-button-3d-shadow:active .mentor-button-3d-inner-container {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px); }
    @media (min-width: 1300px) {
      .mentor-button-3d-shadow:active .mentor-button-3d-inner-container {
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px); } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .mentor-button-3d-shadow:active .mentor-button-3d-inner-container {
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px); } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .mentor-button-3d-shadow:active .mentor-button-3d-inner-container {
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px); } }
  @media (min-width: 1300px) {
    .mentor-button-3d-shadow {
      border-radius: 2.08333vw;
      height: 3.17708vw;
      margin-top: 0.41667vw;
      background: #0C718E; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-button-3d-shadow {
      border-radius: 2.31481vw;
      height: 3.53009vw;
      margin-top: 0.46296vw;
      background: #0C718E; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-button-3d-shadow {
      border-radius: 2.31481vw;
      height: 3.53009vw;
      margin-top: 0.46296vw;
      background: #0C718E; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-button-3d-shadow {
      border-radius: 6.17284vw;
      height: 9.41358vw;
      margin-top: 1.23457vw; }
      .mentor-button-3d-shadow else {
        border-radius: 40px; }
      .mentor-button-3d-shadow else {
        height: 61px; }
      .mentor-button-3d-shadow else {
        margin-top: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-button-3d-shadow {
      border-radius: 4.93827vw;
      height: 7.53086vw;
      margin-top: 0.98765vw; }
      .mentor-button-3d-shadow else {
        border-radius: 40px; }
      .mentor-button-3d-shadow else {
        height: 61px; }
      .mentor-button-3d-shadow else {
        margin-top: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-button-3d-shadow {
      border-radius: 4.3573vw;
      height: 6.64488vw;
      margin-top: 0.87146vw; }
      .mentor-button-3d-shadow else {
        border-radius: 40px; }
      .mentor-button-3d-shadow else {
        height: 61px; }
      .mentor-button-3d-shadow else {
        margin-top: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-button-3d-shadow {
      border-radius: 3.89864vw;
      height: 5.94542vw;
      margin-top: 0.77973vw; }
      .mentor-button-3d-shadow else {
        border-radius: 40px; }
      .mentor-button-3d-shadow else {
        height: 61px; }
      .mentor-button-3d-shadow else {
        margin-top: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-button-3d-shadow {
      border-radius: 3.52734vw;
      height: 5.37919vw;
      margin-top: 0.70547vw; }
      .mentor-button-3d-shadow else {
        border-radius: 40px; }
      .mentor-button-3d-shadow else {
        height: 61px; }
      .mentor-button-3d-shadow else {
        margin-top: 8px; } }

.mentor-button-3d-inner-container {
  background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
  box-shadow: 0px 0px 30px rgba(53, 201, 233, 0.5), inset 0px 0px 8px rgba(0, 255, 240, 0.8), 0px 0px 8px rgba(0, 255, 240, 0);
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  transition: 0.3s box-shadow ease-in-out, 0.3s width ease-in-out, 0.1s -webkit-transform ease-in-out;
  transition: 0.1s transform ease-in-out, 0.3s box-shadow ease-in-out, 0.3s width ease-in-out;
  transition: 0.1s transform ease-in-out, 0.3s box-shadow ease-in-out, 0.3s width ease-in-out, 0.1s -webkit-transform ease-in-out;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 7.40741vw;
  height: 11.2963vw;
  padding: 2.96296vw 4.44444vw;
  font-size: 3.88889vw;
  background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px); }
  .mentor-button-3d-inner-container.disabled {
    background: #DCDCDC;
    color: #A8A7A7;
    box-shadow: none;
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    text-shadow: none; }
  @media (min-width: 1300px) {
    .mentor-button-3d-inner-container {
      border-radius: 2.08333vw;
      height: 3.17708vw;
      padding: 0.83333vw 1.25vw;
      font-size: 1.09375vw;
      background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-button-3d-inner-container {
      border-radius: 2.31481vw;
      height: 3.53009vw;
      padding: 0.92593vw 1.38889vw;
      font-size: 1.21528vw;
      background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-button-3d-inner-container {
      border-radius: 2.31481vw;
      height: 3.53009vw;
      padding: 0.92593vw 1.38889vw;
      font-size: 1.21528vw;
      background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px); } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-button-3d-inner-container {
      border-radius: 6.17284vw;
      height: 9.41358vw;
      padding: 2.46914vw 3.7037vw;
      font-size: 3.24074vw; }
      .mentor-button-3d-inner-container else {
        border-radius: 40px; }
      .mentor-button-3d-inner-container else {
        height: 61px; }
      .mentor-button-3d-inner-container else {
        font-size: 21px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-button-3d-inner-container {
      border-radius: 4.93827vw;
      height: 7.53086vw;
      padding: 1.97531vw 2.96296vw;
      font-size: 2.59259vw; }
      .mentor-button-3d-inner-container else {
        border-radius: 40px; }
      .mentor-button-3d-inner-container else {
        height: 61px; }
      .mentor-button-3d-inner-container else {
        font-size: 21px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-button-3d-inner-container {
      border-radius: 4.3573vw;
      height: 6.64488vw;
      padding: 1.74292vw 2.61438vw;
      font-size: 2.28758vw; }
      .mentor-button-3d-inner-container else {
        border-radius: 40px; }
      .mentor-button-3d-inner-container else {
        height: 61px; }
      .mentor-button-3d-inner-container else {
        font-size: 21px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-button-3d-inner-container {
      border-radius: 3.89864vw;
      height: 5.94542vw;
      padding: 1.55945vw 2.33918vw;
      font-size: 2.04678vw; }
      .mentor-button-3d-inner-container else {
        border-radius: 40px; }
      .mentor-button-3d-inner-container else {
        height: 61px; }
      .mentor-button-3d-inner-container else {
        font-size: 21px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-button-3d-inner-container {
      border-radius: 3.52734vw;
      height: 5.37919vw;
      padding: 1.41093vw 2.1164vw;
      font-size: 1.85185vw; }
      .mentor-button-3d-inner-container else {
        border-radius: 40px; }
      .mentor-button-3d-inner-container else {
        height: 61px; }
      .mentor-button-3d-inner-container else {
        font-size: 21px; } }

.mentor-button-loading,
.mentor-button-loading:after {
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em; }

.mentor-button-loading {
  font-size: 6px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  margin-right: 1.94444vw; }
  @media (min-width: 1300px) {
    .mentor-button-loading {
      margin-right: 0.36458vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .mentor-button-loading {
      margin-right: 0.40509vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .mentor-button-loading {
      margin-right: 0.40509vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .mentor-button-loading {
      margin-right: 1.62037vw; }
      .mentor-button-loading else {
        margin-right: 7px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .mentor-button-loading {
      margin-right: 1.2963vw; }
      .mentor-button-loading else {
        margin-right: 7px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .mentor-button-loading {
      margin-right: 1.14379vw; }
      .mentor-button-loading else {
        margin-right: 7px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .mentor-button-loading {
      margin-right: 1.02339vw; }
      .mentor-button-loading else {
        margin-right: 7px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .mentor-button-loading {
      margin-right: 0.92593vw; }
      .mentor-button-loading else {
        margin-right: 7px; } }

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.SubmitOverlayMenu_container__f_5Ky {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.7); }

.SubmitOverlayMenu_modalContainer__fFHYP {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 24px;
  display: flex;
  background: #FFFFFF;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 9999999;
  box-sizing: border-box;
  min-width: 90%;
  padding-bottom: 4.83092vw;
  max-height: auto; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_modalContainer__fFHYP {
      min-width: 31.25vw;
      padding-bottom: 1.52083vw;
      max-height: 95%; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_modalContainer__fFHYP {
      min-width: 31.25vw;
      padding-bottom: 1.52083vw;
      max-height: 95%; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_modalContainer__fFHYP {
      min-width: 31.25vw;
      padding-bottom: 1.52083vw;
      max-height: 95%; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_modalContainer__fFHYP {
      padding-bottom: 4.02576vw; }
      .SubmitOverlayMenu_modalContainer__fFHYP else {
        min-width: 90%; }
      .SubmitOverlayMenu_modalContainer__fFHYP else {
        padding-bottom: 20px; }
      .SubmitOverlayMenu_modalContainer__fFHYP else {
        max-height: auto; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_modalContainer__fFHYP {
      padding-bottom: 3.22061vw; }
      .SubmitOverlayMenu_modalContainer__fFHYP else {
        min-width: 90%; }
      .SubmitOverlayMenu_modalContainer__fFHYP else {
        padding-bottom: 20px; }
      .SubmitOverlayMenu_modalContainer__fFHYP else {
        max-height: auto; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_modalContainer__fFHYP {
      padding-bottom: 2.84172vw; }
      .SubmitOverlayMenu_modalContainer__fFHYP else {
        min-width: 90%; }
      .SubmitOverlayMenu_modalContainer__fFHYP else {
        padding-bottom: 20px; }
      .SubmitOverlayMenu_modalContainer__fFHYP else {
        max-height: auto; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_modalContainer__fFHYP {
      padding-bottom: 2.54259vw; }
      .SubmitOverlayMenu_modalContainer__fFHYP else {
        min-width: 90%; }
      .SubmitOverlayMenu_modalContainer__fFHYP else {
        padding-bottom: 20px; }
      .SubmitOverlayMenu_modalContainer__fFHYP else {
        max-height: auto; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_modalContainer__fFHYP {
      padding-bottom: 2.30044vw; }
      .SubmitOverlayMenu_modalContainer__fFHYP else {
        min-width: 90%; }
      .SubmitOverlayMenu_modalContainer__fFHYP else {
        padding-bottom: 20px; }
      .SubmitOverlayMenu_modalContainer__fFHYP else {
        max-height: auto; } }

.SubmitOverlayMenu_containerPadding__1ONCi {
  padding-left: 4.83092vw;
  padding-right: 4.83092vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_containerPadding__1ONCi {
      padding-left: 1.11979vw;
      padding-right: 1.11979vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_containerPadding__1ONCi {
      padding-left: 1.11979vw;
      padding-right: 1.11979vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_containerPadding__1ONCi {
      padding-left: 1.11979vw;
      padding-right: 1.11979vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_containerPadding__1ONCi {
      padding-left: 4.02576vw;
      padding-right: 4.02576vw; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-left: 20px; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-right: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_containerPadding__1ONCi {
      padding-left: 3.22061vw;
      padding-right: 3.22061vw; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-left: 20px; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-right: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_containerPadding__1ONCi {
      padding-left: 2.84172vw;
      padding-right: 2.84172vw; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-left: 20px; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-right: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_containerPadding__1ONCi {
      padding-left: 2.54259vw;
      padding-right: 2.54259vw; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-left: 20px; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-right: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_containerPadding__1ONCi {
      padding-left: 2.30044vw;
      padding-right: 2.30044vw; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-left: 20px; }
      .SubmitOverlayMenu_containerPadding__1ONCi else {
        padding-right: 20px; } }

.SubmitOverlayMenu_modalContainerWidth__3j_tH {
  min-width: 90%;
  width: 90%; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_modalContainerWidth__3j_tH {
      min-width: 52.08333vw;
      width: auto; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_modalContainerWidth__3j_tH {
      min-width: 52.08333vw;
      width: auto; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_modalContainerWidth__3j_tH {
      min-width: 52.08333vw;
      width: auto; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      min-width: 90%; }
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      width: 90%; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      min-width: 90%; }
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      width: 90%; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      min-width: 90%; }
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      width: 90%; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      min-width: 90%; }
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      width: 90%; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      min-width: 90%; }
    .SubmitOverlayMenu_modalContainerWidth__3j_tH else {
      width: 90%; } }

.SubmitOverlayMenu_modalContainerBgImage__2adUd {
  background-image: url(/053db8f3389b5ca5dd52722e2271d464-653.png); }

.SubmitOverlayMenu_overlayCloseIcon__3fzTX {
  position: relative;
  width: 2.89855vw;
  height: 2.89855vw;
  top: -1.93237vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_overlayCloseIcon__3fzTX {
      width: 1.04167vw;
      height: 1.04167vw;
      top: -0.05208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_overlayCloseIcon__3fzTX {
      width: 1.15741vw;
      height: 1.15741vw;
      top: -0.05787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_overlayCloseIcon__3fzTX {
      width: 1.15741vw;
      height: 1.15741vw;
      top: -0.05787vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_overlayCloseIcon__3fzTX {
      width: 2.41546vw;
      height: 2.41546vw;
      top: -1.61031vw; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        width: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        height: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        top: -8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_overlayCloseIcon__3fzTX {
      width: 1.93237vw;
      height: 1.93237vw;
      top: -1.28824vw; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        width: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        height: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        top: -8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_overlayCloseIcon__3fzTX {
      width: 1.70503vw;
      height: 1.70503vw;
      top: -1.13669vw; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        width: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        height: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        top: -8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_overlayCloseIcon__3fzTX {
      width: 1.52555vw;
      height: 1.52555vw;
      top: -1.01704vw; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        width: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        height: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        top: -8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_overlayCloseIcon__3fzTX {
      width: 1.38026vw;
      height: 1.38026vw;
      top: -0.92017vw; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        width: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        height: 12px; }
      .SubmitOverlayMenu_overlayCloseIcon__3fzTX else {
        top: -8px; } }
  .SubmitOverlayMenu_overlayCloseIcon__3fzTX path {
    fill: #FFF;
    opacity: 1; }

.SubmitOverlayMenu_overlayClose__3x6Fj {
  position: absolute;
  background: #00ADE6;
  -webkit-filter: drop-shadow(-2px 2px 4px #026282);
          filter: drop-shadow(-2px 2px 4px #026282);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08);
  width: 9.66184vw;
  height: 9.66184vw;
  top: -3.01932vw;
  right: -1.81159vw;
  transition: 0.3s all ease-in-out; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_overlayClose__3x6Fj {
      width: 2.60417vw;
      height: 2.60417vw;
      top: -0.91146vw;
      right: -0.91146vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_overlayClose__3x6Fj {
      width: 2.89352vw;
      height: 2.89352vw;
      top: -1.01273vw;
      right: -1.01273vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_overlayClose__3x6Fj {
      width: 2.89352vw;
      height: 2.89352vw;
      top: -1.01273vw;
      right: -1.01273vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_overlayClose__3x6Fj {
      width: 8.05153vw;
      height: 8.05153vw;
      top: -2.5161vw;
      right: -1.50966vw; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        width: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        height: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        top: -12.5px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        right: -7.5px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_overlayClose__3x6Fj {
      width: 6.44122vw;
      height: 6.44122vw;
      top: -2.01288vw;
      right: -1.20773vw; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        width: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        height: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        top: -12.5px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        right: -7.5px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_overlayClose__3x6Fj {
      width: 5.68343vw;
      height: 5.68343vw;
      top: -1.77607vw;
      right: -1.06564vw; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        width: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        height: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        top: -12.5px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        right: -7.5px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_overlayClose__3x6Fj {
      width: 5.08518vw;
      height: 5.08518vw;
      top: -1.58912vw;
      right: -0.95347vw; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        width: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        height: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        top: -12.5px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        right: -7.5px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_overlayClose__3x6Fj {
      width: 4.60087vw;
      height: 4.60087vw;
      top: -1.43777vw;
      right: -0.86266vw; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        width: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        height: 40px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        top: -12.5px; }
      .SubmitOverlayMenu_overlayClose__3x6Fj else {
        right: -7.5px; } }
  .SubmitOverlayMenu_overlayClose__3x6Fj:hover {
    box-shadow: 0px 6px 6px rgba(51, 51, 51, 0.2), 0px 5px 20px rgba(51, 51, 51, 0.08); }

.SubmitOverlayMenu_modalTitle__3sEAE {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: rgba(0, 173, 230, 0.1);
  border-radius: 0px 0px 20px 20px;
  font-family: Nunito;
  align-self: center;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #00ADE6;
  font-size: 4.34783vw;
  padding: 1.44928vw 14.00966vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_modalTitle__3sEAE {
      font-size: 0.9375vw;
      padding: 0.41667vw 3.54167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_modalTitle__3sEAE {
      font-size: 1.04167vw;
      padding: 0.46296vw 3.93519vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_modalTitle__3sEAE {
      font-size: 1.04167vw;
      padding: 0.46296vw 3.93519vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_modalTitle__3sEAE {
      font-size: 3.62319vw;
      padding: 1.20773vw 11.67472vw; }
      .SubmitOverlayMenu_modalTitle__3sEAE else {
        font-size: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_modalTitle__3sEAE {
      font-size: 2.89855vw;
      padding: 0.96618vw 9.33977vw; }
      .SubmitOverlayMenu_modalTitle__3sEAE else {
        font-size: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_modalTitle__3sEAE {
      font-size: 2.55754vw;
      padding: 0.85251vw 8.24098vw; }
      .SubmitOverlayMenu_modalTitle__3sEAE else {
        font-size: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_modalTitle__3sEAE {
      font-size: 2.28833vw;
      padding: 0.76278vw 7.37351vw; }
      .SubmitOverlayMenu_modalTitle__3sEAE else {
        font-size: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_modalTitle__3sEAE {
      font-size: 2.07039vw;
      padding: 0.69013vw 6.67127vw; }
      .SubmitOverlayMenu_modalTitle__3sEAE else {
        font-size: 18px; } }

.SubmitOverlayMenu_modalIconContainer__eAOIY {
  background: linear-gradient(44.71deg, #7CFFED -8.43%, #01AA93 114.08%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 100%;
  margin-top: 4.83092vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_modalIconContainer__eAOIY {
      margin-top: 1.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_modalIconContainer__eAOIY {
      margin-top: 1.62037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_modalIconContainer__eAOIY {
      margin-top: 1.62037vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_modalIconContainer__eAOIY {
      margin-top: 4.02576vw; }
      .SubmitOverlayMenu_modalIconContainer__eAOIY else {
        margin-top: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_modalIconContainer__eAOIY {
      margin-top: 3.22061vw; }
      .SubmitOverlayMenu_modalIconContainer__eAOIY else {
        margin-top: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_modalIconContainer__eAOIY {
      margin-top: 2.84172vw; }
      .SubmitOverlayMenu_modalIconContainer__eAOIY else {
        margin-top: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_modalIconContainer__eAOIY {
      margin-top: 2.54259vw; }
      .SubmitOverlayMenu_modalIconContainer__eAOIY else {
        margin-top: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_modalIconContainer__eAOIY {
      margin-top: 2.30044vw; }
      .SubmitOverlayMenu_modalIconContainer__eAOIY else {
        margin-top: 20px; } }
  .SubmitOverlayMenu_modalIconContainer__eAOIY > .SubmitOverlayMenu_saveIcon__27tFb {
    width: 32px;
    height: 32px;
    background-image: url(/static/media/save-outline.6976b85d.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; }

.SubmitOverlayMenu_modalContainer__fFHYP:focus {
  outline: none; }

.SubmitOverlayMenu_submit__3rU81 {
  width: 20.83333vw;
  height: 1.53646vw;
  object-fit: contain;
  font-family: Nunito;
  font-size: 1.35417vw;
  float: left;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #1ac9e8;
  margin-top: 4.27083vw; }

.SubmitOverlayMenu_confirmText__2p0HW {
  font-family: Nunito;
  font-weight: bold;
  letter-spacing: -0.02em;
  color: #504F4F;
  text-align: center;
  font-size: 4.83092vw;
  margin-top: 4.83092vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_confirmText__2p0HW {
      font-size: 1.5625vw;
      margin-top: 1.30208vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_confirmText__2p0HW {
      font-size: 1.73611vw;
      margin-top: 1.30208vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_confirmText__2p0HW {
      font-size: 1.73611vw;
      margin-top: 1.30208vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_confirmText__2p0HW {
      font-size: 4.02576vw;
      margin-top: 4.02576vw; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        font-size: 20px; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        margin-top: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_confirmText__2p0HW {
      font-size: 3.22061vw;
      margin-top: 3.22061vw; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        font-size: 20px; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        margin-top: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_confirmText__2p0HW {
      font-size: 2.84172vw;
      margin-top: 2.84172vw; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        font-size: 20px; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        margin-top: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_confirmText__2p0HW {
      font-size: 2.54259vw;
      margin-top: 2.54259vw; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        font-size: 20px; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        margin-top: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_confirmText__2p0HW {
      font-size: 2.30044vw;
      margin-top: 2.30044vw; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        font-size: 20px; }
      .SubmitOverlayMenu_confirmText__2p0HW else {
        margin-top: 20px; } }
  .SubmitOverlayMenu_confirmText__2p0HW > span {
    display: block;
    text-align: center;
    font-weight: normal;
    color: #504F4F;
    opacity: 0.7;
    margin-top: 12px;
    font-size: 4.34783vw; }
    @media (min-width: 1300px) {
      .SubmitOverlayMenu_confirmText__2p0HW > span {
        font-size: 1.14583vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .SubmitOverlayMenu_confirmText__2p0HW > span {
        font-size: 1.27315vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .SubmitOverlayMenu_confirmText__2p0HW > span {
        font-size: 1.27315vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .SubmitOverlayMenu_confirmText__2p0HW > span {
        font-size: 3.62319vw; }
        .SubmitOverlayMenu_confirmText__2p0HW > span else {
          font-size: 18px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .SubmitOverlayMenu_confirmText__2p0HW > span {
        font-size: 2.89855vw; }
        .SubmitOverlayMenu_confirmText__2p0HW > span else {
          font-size: 18px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .SubmitOverlayMenu_confirmText__2p0HW > span {
        font-size: 2.55754vw; }
        .SubmitOverlayMenu_confirmText__2p0HW > span else {
          font-size: 18px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .SubmitOverlayMenu_confirmText__2p0HW > span {
        font-size: 2.28833vw; }
        .SubmitOverlayMenu_confirmText__2p0HW > span else {
          font-size: 18px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .SubmitOverlayMenu_confirmText__2p0HW > span {
        font-size: 2.07039vw; }
        .SubmitOverlayMenu_confirmText__2p0HW > span else {
          font-size: 18px; } }

.SubmitOverlayMenu_buttonArea__1aFHK {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 5.31401vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_buttonArea__1aFHK {
      margin-top: 2.34375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_buttonArea__1aFHK {
      margin-top: 2.34375vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_buttonArea__1aFHK {
      margin-top: 2.34375vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_buttonArea__1aFHK {
      margin-top: 4.42834vw; }
      .SubmitOverlayMenu_buttonArea__1aFHK else {
        margin-top: 22px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_buttonArea__1aFHK {
      margin-top: 3.54267vw; }
      .SubmitOverlayMenu_buttonArea__1aFHK else {
        margin-top: 22px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_buttonArea__1aFHK {
      margin-top: 3.12589vw; }
      .SubmitOverlayMenu_buttonArea__1aFHK else {
        margin-top: 22px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_buttonArea__1aFHK {
      margin-top: 2.79685vw; }
      .SubmitOverlayMenu_buttonArea__1aFHK else {
        margin-top: 22px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_buttonArea__1aFHK {
      margin-top: 2.53048vw; }
      .SubmitOverlayMenu_buttonArea__1aFHK else {
        margin-top: 22px; } }

.SubmitOverlayMenu_cancelBtn__235Nc {
  cursor: pointer;
  object-fit: contain;
  border: solid 2px #1ac9e8;
  font-family: Nunito;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1ac9e8;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  align-items: center;
  font-size: 3.86473vw;
  padding: 1.93237vw 3.86473vw;
  border-radius: 9.66184vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_cancelBtn__235Nc {
      font-size: 0.9375vw;
      padding: 0.52083vw 0.9375vw;
      border-radius: 1.5625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_cancelBtn__235Nc {
      font-size: 1.04167vw;
      padding: 0.5787vw 1.04167vw;
      border-radius: 1.5625vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_cancelBtn__235Nc {
      font-size: 1.04167vw;
      padding: 0.5787vw 1.04167vw;
      border-radius: 1.5625vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_cancelBtn__235Nc {
      font-size: 3.22061vw;
      padding: 1.61031vw 3.22061vw;
      border-radius: 8.05153vw; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        font-size: 16px; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        border-radius: 40px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_cancelBtn__235Nc {
      font-size: 2.57649vw;
      padding: 1.28824vw 2.57649vw;
      border-radius: 6.44122vw; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        font-size: 16px; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        border-radius: 40px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_cancelBtn__235Nc {
      font-size: 2.27337vw;
      padding: 1.13669vw 2.27337vw;
      border-radius: 5.68343vw; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        font-size: 16px; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        border-radius: 40px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_cancelBtn__235Nc {
      font-size: 2.03407vw;
      padding: 1.01704vw 2.03407vw;
      border-radius: 5.08518vw; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        font-size: 16px; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        border-radius: 40px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_cancelBtn__235Nc {
      font-size: 1.84035vw;
      padding: 0.92017vw 1.84035vw;
      border-radius: 4.60087vw; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        font-size: 16px; }
      .SubmitOverlayMenu_cancelBtn__235Nc else {
        border-radius: 40px; } }

.SubmitOverlayMenu_submitBtn__2o_xm {
  cursor: pointer;
  background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
  border-radius: 40px;
  font-family: Nunito;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-transform: uppercase;
  font-size: 3.86473vw;
  padding: 2.41546vw 3.86473vw;
  border-radius: 9.66184vw;
  margin-left: 4.83092vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_submitBtn__2o_xm {
      font-size: 0.9375vw;
      padding: 0.625vw 0.9375vw;
      margin-left: 0.625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_submitBtn__2o_xm {
      font-size: 1.04167vw;
      padding: 0.69444vw 1.04167vw;
      margin-left: 0.69444vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_submitBtn__2o_xm {
      font-size: 1.04167vw;
      padding: 0.69444vw 1.04167vw;
      margin-left: 0.69444vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_submitBtn__2o_xm {
      font-size: 3.22061vw;
      padding: 2.01288vw 3.22061vw;
      border-radius: 8.05153vw;
      margin-left: 4.02576vw; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        font-size: 16px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        border-radius: 40px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        margin-left: 20px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_submitBtn__2o_xm {
      font-size: 2.57649vw;
      padding: 1.61031vw 2.57649vw;
      border-radius: 6.44122vw;
      margin-left: 3.22061vw; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        font-size: 16px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        border-radius: 40px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        margin-left: 20px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_submitBtn__2o_xm {
      font-size: 2.27337vw;
      padding: 1.42086vw 2.27337vw;
      border-radius: 5.68343vw;
      margin-left: 2.84172vw; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        font-size: 16px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        border-radius: 40px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        margin-left: 20px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_submitBtn__2o_xm {
      font-size: 2.03407vw;
      padding: 1.27129vw 2.03407vw;
      border-radius: 5.08518vw;
      margin-left: 2.54259vw; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        font-size: 16px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        border-radius: 40px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        margin-left: 20px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_submitBtn__2o_xm {
      font-size: 1.84035vw;
      padding: 1.15022vw 1.84035vw;
      border-radius: 4.60087vw;
      margin-left: 2.30044vw; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        font-size: 16px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        border-radius: 40px; }
      .SubmitOverlayMenu_submitBtn__2o_xm else {
        margin-left: 20px; } }
  .SubmitOverlayMenu_submitBtn__2o_xm:hover {
    opacity: 0.7; }

.SubmitOverlayMenu_disabled__2nNK5 {
  background-color: #d1d1d1 !important;
  background-image: none !important; }
  .SubmitOverlayMenu_disabled__2nNK5:hover {
    opacity: 1 !important; }

.SubmitOverlayMenu_confirmSubmissionLabel__r31hX {
  background: rgba(0, 173, 230, 0.1);
  border: 2px solid #DCDCDC;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.96354vw 0px;
  font-family: Nunito;
  font-weight: bold;
  letter-spacing: -0.02em;
  color: #00ADE6;
  font-size: 4.34783vw;
  border-radius: 5.7971vw 5.7971vw 0vw 0vw;
  margin: 0vw 0vw 3.86473vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_confirmSubmissionLabel__r31hX {
      font-size: 1.35417vw;
      border-radius: 0;
      margin: 1.45833vw 0vw 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_confirmSubmissionLabel__r31hX {
      font-size: 1.50463vw;
      border-radius: 0;
      margin: 1.45833vw 0vw 1.04167vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_confirmSubmissionLabel__r31hX {
      font-size: 1.50463vw;
      border-radius: 0;
      margin: 1.45833vw 0vw 1.04167vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_confirmSubmissionLabel__r31hX {
      font-size: 3.62319vw;
      border-radius: 4.83092vw 4.83092vw 0vw 0vw;
      margin: 0vw 0vw 3.22061vw; }
      .SubmitOverlayMenu_confirmSubmissionLabel__r31hX else {
        font-size: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_confirmSubmissionLabel__r31hX {
      font-size: 2.89855vw;
      border-radius: 3.86473vw 3.86473vw 0vw 0vw;
      margin: 0vw 0vw 2.57649vw; }
      .SubmitOverlayMenu_confirmSubmissionLabel__r31hX else {
        font-size: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_confirmSubmissionLabel__r31hX {
      font-size: 2.55754vw;
      border-radius: 3.41006vw 3.41006vw 0vw 0vw;
      margin: 0vw 0vw 2.27337vw; }
      .SubmitOverlayMenu_confirmSubmissionLabel__r31hX else {
        font-size: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_confirmSubmissionLabel__r31hX {
      font-size: 2.28833vw;
      border-radius: 3.05111vw 3.05111vw 0vw 0vw;
      margin: 0vw 0vw 2.03407vw; }
      .SubmitOverlayMenu_confirmSubmissionLabel__r31hX else {
        font-size: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_confirmSubmissionLabel__r31hX {
      font-size: 2.07039vw;
      border-radius: 2.76052vw 2.76052vw 0vw 0vw;
      margin: 0vw 0vw 1.84035vw; }
      .SubmitOverlayMenu_confirmSubmissionLabel__r31hX else {
        font-size: 18px; } }

.SubmitOverlayMenu_summaryContainer__1w00A {
  border: 2px solid #DCDCDC;
  box-sizing: border-box;
  border-radius: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 36.45833vw;
  padding: 2.89855vw;
  margin: 0vw 0.96618vw;
  min-width: 95%; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_summaryContainer__1w00A {
      padding: 1.14583vw 2.60417vw 1.14583vw 1.5625vw;
      margin: 0vw 0.9375vw;
      min-width: 90%; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_summaryContainer__1w00A {
      padding: 1.27315vw 2.89352vw 1.27315vw 1.73611vw;
      margin: 0vw 1.04167vw;
      min-width: 90%; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_summaryContainer__1w00A {
      padding: 1.27315vw 2.89352vw 1.27315vw 1.73611vw;
      margin: 0vw 1.04167vw;
      min-width: 90%; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_summaryContainer__1w00A {
      padding: 2.41546vw;
      margin: 0vw 0.80515vw; }
      .SubmitOverlayMenu_summaryContainer__1w00A else {
        padding: 12px; }
      .SubmitOverlayMenu_summaryContainer__1w00A else {
        min-width: 95%; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_summaryContainer__1w00A {
      padding: 1.93237vw;
      margin: 0vw 0.64412vw; }
      .SubmitOverlayMenu_summaryContainer__1w00A else {
        padding: 12px; }
      .SubmitOverlayMenu_summaryContainer__1w00A else {
        min-width: 95%; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_summaryContainer__1w00A {
      padding: 1.70503vw;
      margin: 0vw 0.56834vw; }
      .SubmitOverlayMenu_summaryContainer__1w00A else {
        padding: 12px; }
      .SubmitOverlayMenu_summaryContainer__1w00A else {
        min-width: 95%; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_summaryContainer__1w00A {
      padding: 1.52555vw;
      margin: 0vw 0.50852vw; }
      .SubmitOverlayMenu_summaryContainer__1w00A else {
        padding: 12px; }
      .SubmitOverlayMenu_summaryContainer__1w00A else {
        min-width: 95%; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_summaryContainer__1w00A {
      padding: 1.38026vw;
      margin: 0vw 0.46009vw; }
      .SubmitOverlayMenu_summaryContainer__1w00A else {
        padding: 12px; }
      .SubmitOverlayMenu_summaryContainer__1w00A else {
        min-width: 95%; } }
  .SubmitOverlayMenu_summaryContainer__1w00A::-webkit-scrollbar {
    background: #D7FAFB;
    border-radius: 10px; }

.SubmitOverlayMenu_summaryTitle__Kztd9 {
  font-family: Nunito;
  font-weight: bold;
  letter-spacing: -0.02em;
  color: #00ADE6;
  display: block;
  margin-bottom: 8px;
  font-size: 4.34783vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_summaryTitle__Kztd9 {
      font-size: 1.35417vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_summaryTitle__Kztd9 {
      font-size: 1.50463vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_summaryTitle__Kztd9 {
      font-size: 1.50463vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_summaryTitle__Kztd9 {
      font-size: 3.62319vw; }
      .SubmitOverlayMenu_summaryTitle__Kztd9 else {
        font-size: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_summaryTitle__Kztd9 {
      font-size: 2.89855vw; }
      .SubmitOverlayMenu_summaryTitle__Kztd9 else {
        font-size: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_summaryTitle__Kztd9 {
      font-size: 2.55754vw; }
      .SubmitOverlayMenu_summaryTitle__Kztd9 else {
        font-size: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_summaryTitle__Kztd9 {
      font-size: 2.28833vw; }
      .SubmitOverlayMenu_summaryTitle__Kztd9 else {
        font-size: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_summaryTitle__Kztd9 {
      font-size: 2.07039vw; }
      .SubmitOverlayMenu_summaryTitle__Kztd9 else {
        font-size: 18px; } }

.SubmitOverlayMenu_questionContainer__3yf0c {
  width: 100%;
  font-family: Nunito;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #403F3F;
  padding: 12px 4px;
  border-bottom: 1px solid #DCDCDC;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 4.34783vw; }
  .SubmitOverlayMenu_questionContainer__3yf0c > span:nth-child(1) {
    flex: 1 1 70%; }
  .SubmitOverlayMenu_questionContainer__3yf0c > span:nth-child(2) {
    flex: 1 1 20%;
    position: relative; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_questionContainer__3yf0c {
      font-size: 1.14583vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_questionContainer__3yf0c {
      font-size: 1.27315vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_questionContainer__3yf0c {
      font-size: 1.27315vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_questionContainer__3yf0c {
      font-size: 3.62319vw; }
      .SubmitOverlayMenu_questionContainer__3yf0c else {
        font-size: 18px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_questionContainer__3yf0c {
      font-size: 2.89855vw; }
      .SubmitOverlayMenu_questionContainer__3yf0c else {
        font-size: 18px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_questionContainer__3yf0c {
      font-size: 2.55754vw; }
      .SubmitOverlayMenu_questionContainer__3yf0c else {
        font-size: 18px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_questionContainer__3yf0c {
      font-size: 2.28833vw; }
      .SubmitOverlayMenu_questionContainer__3yf0c else {
        font-size: 18px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_questionContainer__3yf0c {
      font-size: 2.07039vw; }
      .SubmitOverlayMenu_questionContainer__3yf0c else {
        font-size: 18px; } }

.SubmitOverlayMenu_questionText__R9XWy {
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  white-space: nowrap;
  width: 100%;
  max-width: 43.47826vw; }
  .SubmitOverlayMenu_questionText__R9XWy p {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_questionText__R9XWy {
      max-width: "unset"; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_questionText__R9XWy {
      max-width: "unset"; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_questionText__R9XWy {
      max-width: "unset"; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_questionText__R9XWy {
      max-width: 36.23188vw; }
      .SubmitOverlayMenu_questionText__R9XWy else {
        max-width: 180px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_questionText__R9XWy {
      max-width: 28.98551vw; }
      .SubmitOverlayMenu_questionText__R9XWy else {
        max-width: 180px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_questionText__R9XWy {
      max-width: 25.57545vw; }
      .SubmitOverlayMenu_questionText__R9XWy else {
        max-width: 180px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_questionText__R9XWy {
      max-width: 22.8833vw; }
      .SubmitOverlayMenu_questionText__R9XWy else {
        max-width: 180px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_questionText__R9XWy {
      max-width: 20.70393vw; }
      .SubmitOverlayMenu_questionText__R9XWy else {
        max-width: 180px; } }

.SubmitOverlayMenu_tagContainer__1XBZP {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_tagContainer__1XBZP {
      justify-content: center; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_tagContainer__1XBZP {
      justify-content: center; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_tagContainer__1XBZP {
      justify-content: center; } }

.SubmitOverlayMenu_answeredTag__2Dpq5 {
  background: #65DA7A;
  border-radius: 18px;
  font-weight: bold;
  letter-spacing: 0.03em;
  color: #FFFFFF;
  padding: 6px 12px;
  font-size: 3.38164vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_answeredTag__2Dpq5 {
      font-size: 0.83333vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_answeredTag__2Dpq5 {
      font-size: 0.92593vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_answeredTag__2Dpq5 {
      font-size: 0.92593vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_answeredTag__2Dpq5 {
      font-size: 2.81804vw; }
      .SubmitOverlayMenu_answeredTag__2Dpq5 else {
        font-size: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_answeredTag__2Dpq5 {
      font-size: 2.25443vw; }
      .SubmitOverlayMenu_answeredTag__2Dpq5 else {
        font-size: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_answeredTag__2Dpq5 {
      font-size: 1.9892vw; }
      .SubmitOverlayMenu_answeredTag__2Dpq5 else {
        font-size: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_answeredTag__2Dpq5 {
      font-size: 1.77981vw; }
      .SubmitOverlayMenu_answeredTag__2Dpq5 else {
        font-size: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_answeredTag__2Dpq5 {
      font-size: 1.61031vw; }
      .SubmitOverlayMenu_answeredTag__2Dpq5 else {
        font-size: 14px; } }

.SubmitOverlayMenu_unAnsweredTag__1AoTF {
  background: #FF5744 !important; }

.SubmitOverlayMenu_headerText__2PAw1 {
  font-family: Nunito;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #A8A7A7;
  font-size: 3.38164vw; }
  @media (min-width: 1300px) {
    .SubmitOverlayMenu_headerText__2PAw1 {
      font-size: 0.88542vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .SubmitOverlayMenu_headerText__2PAw1 {
      font-size: 0.9838vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .SubmitOverlayMenu_headerText__2PAw1 {
      font-size: 0.9838vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .SubmitOverlayMenu_headerText__2PAw1 {
      font-size: 2.81804vw; }
      .SubmitOverlayMenu_headerText__2PAw1 else {
        font-size: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .SubmitOverlayMenu_headerText__2PAw1 {
      font-size: 2.25443vw; }
      .SubmitOverlayMenu_headerText__2PAw1 else {
        font-size: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .SubmitOverlayMenu_headerText__2PAw1 {
      font-size: 1.9892vw; }
      .SubmitOverlayMenu_headerText__2PAw1 else {
        font-size: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .SubmitOverlayMenu_headerText__2PAw1 {
      font-size: 1.77981vw; }
      .SubmitOverlayMenu_headerText__2PAw1 else {
        font-size: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .SubmitOverlayMenu_headerText__2PAw1 {
      font-size: 1.61031vw; }
      .SubmitOverlayMenu_headerText__2PAw1 else {
        font-size: 14px; } }
  .SubmitOverlayMenu_headerText__2PAw1:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center; }

.SubmitOverlayMenu_editIcon__2WXuk {
  width: 20px;
  height: 20px;
  background-image: url(/static/media/confirmEditIcon.45a960f3.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  box-sizing: border-box;
  position: absolute;
  right: -10px;
  cursor: pointer; }

.SubmitOverlayMenu_qrLoaderCard__3Y6XM {
  width: 100%;
  height: 100%; }
  .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH {
    y: 11.11111vw;
    x: 42.77778vw;
    height: 19.44444vw;
    width: 125vw;
    rx: 10px; }
    @media (min-width: 1300px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH {
        y: 0.52083vw;
        x: 2.60417vw;
        height: 3.125vw;
        width: 90%;
        rx: 6px; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH {
        y: 0.5787vw;
        x: 2.89352vw;
        height: 3.47222vw;
        width: 90%;
        rx: 6px; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH {
        y: 0.5787vw;
        x: 2.89352vw;
        height: 3.47222vw;
        width: 90%;
        rx: 6px; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH {
        y: 9.25926vw;
        x: 35.64815vw;
        height: 16.2037vw;
        width: 104.16667vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          width: 450px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH {
        y: 7.40741vw;
        x: 28.51852vw;
        height: 12.96296vw;
        width: 83.33333vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          width: 450px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH {
        y: 6.53595vw;
        x: 25.1634vw;
        height: 11.43791vw;
        width: 73.52941vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          width: 450px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH {
        y: 5.84795vw;
        x: 22.51462vw;
        height: 10.23392vw;
        width: 65.78947vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          width: 450px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH {
        y: 5.29101vw;
        x: 20.37037vw;
        height: 9.25926vw;
        width: 59.52381vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1__1FCaH else {
          width: 450px; } }
  .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ {
    y: 3.33333vw;
    x: 3.33333vw;
    height: 19.44444vw;
    width: 83.33333vw;
    rx: 10px; }
    @media (min-width: 450px) and (max-width: 500px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ {
        y: 2.77778vw;
        x: 2.77778vw;
        height: 16.2037vw;
        width: 69.44444vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          y: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          x: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          width: 300px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ {
        y: 2.22222vw;
        x: 2.22222vw;
        height: 12.96296vw;
        width: 55.55556vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          y: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          x: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          width: 300px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ {
        y: 1.96078vw;
        x: 1.96078vw;
        height: 11.43791vw;
        width: 49.01961vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          y: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          x: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          width: 300px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ {
        y: 1.75439vw;
        x: 1.75439vw;
        height: 10.23392vw;
        width: 43.85965vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          y: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          x: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          width: 300px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ {
        y: 1.5873vw;
        x: 1.5873vw;
        height: 9.25926vw;
        width: 39.68254vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          y: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          x: 12px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader1Mobile__u9OO_ else {
          width: 300px; } }
  .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA {
    y: 11.11111vw;
    x: 42.77778vw;
    height: 19.44444vw;
    width: 125vw;
    rx: 10px; }
    @media (min-width: 1300px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA {
        y: 4.16667vw;
        x: 2.60417vw;
        height: 3.125vw;
        width: 90%;
        rx: 6px; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA {
        y: 4.62963vw;
        x: 2.89352vw;
        height: 3.47222vw;
        width: 90%;
        rx: 6px; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA {
        y: 4.62963vw;
        x: 2.89352vw;
        height: 3.47222vw;
        width: 90%;
        rx: 6px; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA {
        y: 9.25926vw;
        x: 35.64815vw;
        height: 16.2037vw;
        width: 104.16667vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          width: 450px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA {
        y: 7.40741vw;
        x: 28.51852vw;
        height: 12.96296vw;
        width: 83.33333vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          width: 450px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA {
        y: 6.53595vw;
        x: 25.1634vw;
        height: 11.43791vw;
        width: 73.52941vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          width: 450px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA {
        y: 5.84795vw;
        x: 22.51462vw;
        height: 10.23392vw;
        width: 65.78947vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          width: 450px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA {
        y: 5.29101vw;
        x: 20.37037vw;
        height: 9.25926vw;
        width: 59.52381vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader2__sdoIA else {
          width: 450px; } }
  .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j {
    y: 11.11111vw;
    x: 42.77778vw;
    height: 19.44444vw;
    width: 125vw;
    rx: 10px; }
    @media (min-width: 1300px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j {
        y: 7.8125vw;
        x: 2.60417vw;
        height: 3.125vw;
        width: 90%;
        rx: 6px; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j {
        y: 8.68056vw;
        x: 2.89352vw;
        height: 3.47222vw;
        width: 90%;
        rx: 6px; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j {
        y: 8.68056vw;
        x: 2.89352vw;
        height: 3.47222vw;
        width: 90%;
        rx: 6px; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j {
        y: 9.25926vw;
        x: 35.64815vw;
        height: 16.2037vw;
        width: 104.16667vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          width: 450px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j {
        y: 7.40741vw;
        x: 28.51852vw;
        height: 12.96296vw;
        width: 83.33333vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          width: 450px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j {
        y: 6.53595vw;
        x: 25.1634vw;
        height: 11.43791vw;
        width: 73.52941vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          width: 450px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j {
        y: 5.84795vw;
        x: 22.51462vw;
        height: 10.23392vw;
        width: 65.78947vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          width: 450px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j {
        y: 5.29101vw;
        x: 20.37037vw;
        height: 9.25926vw;
        width: 59.52381vw; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          y: 40px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          x: 154px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          height: 70px; }
        .SubmitOverlayMenu_qrLoaderCard__3Y6XM .SubmitOverlayMenu_qrLoader3__39Y9j else {
          width: 450px; } }

.SubmitOverlayMenu_overlayCloseIconForEmbed__3Wt0y {
  display: flex;
  top: unset; }

.previewComponent {
  position: relative;
  width: 100%;
  background: inherit; }
  .previewComponent .tox-tinymce {
    box-shadow: none;
    border: none;
    background-color: transparent; }
  .previewComponent iframe {
    background: inherit !important;
    background-color: inherit !important; }
  .previewComponent::-webkit-scrollbar {
    width: 4px;
    background-color: transparent; }
  .previewComponent::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #00ADE6; }

.route-container {
  display: flex;
  flex-direction: row; }

.route-bodyContainer {
  flex: 1 1;
  height: calc(100vh - 4.58333vw);
  position: relative;
  overflow-x: hidden; }

@media only screen and (max-width: 768px) {
  .route-bodyContainer {
    height: 100vh; } }

.rotateDevice {
  width: 100vw;
  height: 100vh;
  background: #FFF;
  align-items: center;
  justify-content: center;
  display: none;
  overflow: hidden;
  z-index: 99999999999;
  position: fixed;
  background-image: url(/2e16bdec34a738a4864fb023b0057f1c-360.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: Nunito;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  flex-direction: column; }
  .rotateDevice > .rotateDeviceIcon {
    background-image: url(/a1467419b5f082f8e87f5261e560c633-46.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 45px;
    height: 45px;
    display: block;
    margin-bottom: 18px; }

@media screen and (orientation: portrait) and (max-device-width: 900px) {
  .__IPad__ .rotateDevice {
    display: flex; } }

.route-bodyPositionUnset {
  position: unset !important; }

.route-bodyContainerFull {
  flex: 1 1;
  max-width: 100vw;
  position: relative; }

.session-embed-full {
  overflow: visible !important; }

.route-bodyOverflowOverlay {
  overflow: overlay; }

.route-sideBar {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #c9fcfd, #34e4ea);
  width: 120%;
  height: 100%; }

@media screen and (max-width: 500px) {
  .routesideBar {
    width: 48.30918vw; } }

@media screen and (min-width: 500px) and (max-width: 600px) {
  .route-sideBar {
    width: 33.81643vw; } }

@media screen and (min-width: 600px) and (max-width: 700px) {
  .route-sideBar {
    width: 31.40097vw; } }

@media screen and (min-width: 700px) and (max-width: 800px) {
  .route-sideBar {
    width: 28.98551vw; } }

@media screen and (min-width: 800px) and (max-width: 900px) {
  .route-sideBar {
    width: 26.57005vw; } }

@media screen and (min-width: 900px) and (max-width: 1000px) {
  .route-sideBar {
    width: 24.15459vw; } }

.route-menuItems {
  width: 100%;
  height: 8%;
  font-weight: bold;
  font-size: 2.5vh;
  font-family: Nunito;
  border-bottom: 1px solid #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: capitalize;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.75); }

.route-text {
  margin-left: 3vh; }

.route-activeBackground {
  background-color: #fff; }

.lato, .lato-light, .lato-regular, .lato-hairline {
  font-family: 'Lato', sans-serif; }

.lato-light {
  font-weight: 300; }

.lato-regular {
  font-weight: 400; }

.lato-hairline {
  font-weight: 100; }

.popup-component-popUpContainer {
  position: absolute;
  width: calc(100vw - 5px);
  display: flex;
  top: 0; }

.popup-component-popUpBackground {
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(3, 3, 3, 0.6);
  overflow: auto;
  position: fixed; }

.popup-component-paymentPopUpBackground {
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(3, 3, 3, 0.6);
  overflow-y: auto;
  overflow-x: hidden; }
  @media (min-width: 1300px) {
    .popup-component-paymentPopUpBackground {
      background-color: rgba(3, 3, 3, 0.6);
      overflow: auto;
      position: fixed; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .popup-component-paymentPopUpBackground {
      background-color: rgba(3, 3, 3, 0.6);
      overflow: auto;
      position: fixed; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .popup-component-paymentPopUpBackground {
      background-color: rgba(3, 3, 3, 0.6);
      overflow: auto;
      position: fixed; } }

.popup-component-childrenContainer {
  display: flex; }

.popup-component-leftAlignedChildren {
  justify-content: flex-start !important; }

.popup-component-fadeIn {
  -webkit-animation: fadeInAnimation ease 0.4s;
          animation: fadeInAnimation ease 0.4s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  display: flex;
  justify-content: center;
  visibility: visible; }

.popup-component-fadeOut {
  -webkit-animation: fadeOutAnimation ease 0.4s;
          animation: fadeOutAnimation ease 0.4s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  visibility: hidden; }

.popup-component-modal {
  width: 5.20833vw;
  height: 5.20833vw; }

@-webkit-keyframes fadeInAnimation {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeInAnimation {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeOutAnimation {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOutAnimation {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.Inter, .Inter-regular, .Inter-SemiBold, .Inter-Bold {
  font-family: 'Inter', 'Nunito', sans-serif; }

.Inter-regular {
  font-weight: 400; }

.Inter-SemiBold {
  font-weight: 600; }

.Inter-Bold {
  font-weight: 800; }

:root {
  --accent-color:$tekie-amethyst;
  --accent-color-110: $tekie-amethyst-dark; }

.splitScreen {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: #f9f9f9;
  box-sizing: border-box !important; }
  .splitScreen .popup-component-popUpBackground {
    z-index: 9999;
    background-color: transparent !important; }
  @media (min-width: 1300px) {
    .splitScreen ::-webkit-scrollbar {
      width: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .splitScreen ::-webkit-scrollbar {
      width: 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .splitScreen ::-webkit-scrollbar {
      width: 0.5787vw; } }
  .splitScreen ::-webkit-scrollbar-thumb {
    background: $tekie-amethyst;
    background: var(--accent-color); }
  .splitScreen.notAllowedScreen {
    justify-content: center;
    align-items: center;
    text-align: center; }

.splitScreen-withCRT-overlay {
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  z-index: 1001;
  top: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.3); }
  .splitScreen-withCRT-overlay.open {
    display: block; }

.splitScreen-sidenav {
  box-sizing: border-box;
  background: #FFFF;
  height: 100vh;
  font-family: 'Inter';
  z-index: 99;
  position: relative;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  transition: 0.3s all ease-in-out;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1111;
  margin-left: 0;
  box-shadow: 2px 2px 4px 0px #0000001A;
  width: 84.54106vw;
  padding: 5.7971vw 4.83092vw; }
  @media (min-width: 1300px) {
    .splitScreen-sidenav {
      width: 17.1875vw;
      padding: 1.25vw 1.45833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .splitScreen-sidenav {
      width: 19.09722vw;
      padding: 1.38889vw 1.62037vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .splitScreen-sidenav {
      width: 19.09722vw;
      padding: 1.38889vw 1.62037vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .splitScreen-sidenav {
      width: 70.45089vw;
      padding: 4.83092vw 4.02576vw; }
      .splitScreen-sidenav else {
        width: 350px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .splitScreen-sidenav {
      width: 56.36071vw;
      padding: 3.86473vw 3.22061vw; }
      .splitScreen-sidenav else {
        width: 350px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .splitScreen-sidenav {
      width: 49.73004vw;
      padding: 3.41006vw 2.84172vw; }
      .splitScreen-sidenav else {
        width: 350px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .splitScreen-sidenav {
      width: 44.4953vw;
      padding: 3.05111vw 2.54259vw; }
      .splitScreen-sidenav else {
        width: 350px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .splitScreen-sidenav {
      width: 40.25765vw;
      padding: 2.76052vw 2.30044vw; }
      .splitScreen-sidenav else {
        width: 350px; } }
  .splitScreen-sidenav .sidenav {
    flex: 1 1; }
  .splitScreen-sidenav.hamburger {
    width: 0; }
    @media (min-width: 1300px) {
      .splitScreen-sidenav.hamburger {
        padding: 1.25vw 0vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .splitScreen-sidenav.hamburger {
        padding: 1.38889vw 0vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .splitScreen-sidenav.hamburger {
        padding: 1.38889vw 0vw; } }

.splitScreen-sidenav-withCRT {
  position: fixed; }

@media (min-width: 1000px) {
  .splitScreen-main-component-withCRT .menu__icon__container.open {
    left: 14.0625vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .splitScreen-main-component-withCRT .menu__icon__container.open {
    left: 15.625vw; } }

@media (min-width: 1001px) and (max-width: 1600px) {
  .splitScreen-main-component-withCRT .menu__icon__container.open {
    left: 14.0625vw; } }

@media (min-width: 1601px) and (max-width: 2000px) {
  .splitScreen-main-component-withCRT .menu__icon__container.open {
    left: 11.25vw; } }

.splitScreen ::-webkit-scrollbar {
  background-color: #EEEEEE; }

@media screen and (max-width: 700px) {
  .splitScreen-sidenav.hamburger {
    position: fixed;
    top: 0;
    left: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

.hamburger-icon-teacherApp {
  position: absolute;
  top: 1rem;
  left: 1rem; }

.splitScreen-main-component {
  position: relative;
  min-height: 100%;
  height: 100vh;
  width: 100%;
  overflow: scroll;
  transition: 0.3s all ease-in-out; }
  @media (min-width: 1000px) {
    .splitScreen-main-component {
      padding-left: 1.5625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .splitScreen-main-component {
      padding-left: 1.73611vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .splitScreen-main-component {
      padding-left: 1.5625vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .splitScreen-main-component {
      padding-left: 1.25vw; } }
  @media (min-width: 1000px) {
    .splitScreen-main-component.hamburger, .splitScreen-main-component.splitScreen-main-component-withCRT {
      padding-left: 3.75vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .splitScreen-main-component.hamburger, .splitScreen-main-component.splitScreen-main-component-withCRT {
      padding-left: 4.16667vw; } }
  @media (min-width: 1001px) and (max-width: 1600px) {
    .splitScreen-main-component.hamburger, .splitScreen-main-component.splitScreen-main-component-withCRT {
      padding-left: 3.75vw; } }
  @media (min-width: 1601px) and (max-width: 2000px) {
    .splitScreen-main-component.hamburger, .splitScreen-main-component.splitScreen-main-component-withCRT {
      padding-left: 3vw; } }

.profile-container {
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-direction: row;
  font-weight: 540;
  align-items: center;
  transition: 0.2s all ease-in-out;
  text-decoration: none;
  font-size: 3.38164vw;
  padding: 1.93237vw 2.89855vw;
  border-radius: 2.41546vw;
  margin: 1.5rem auto; }
  @media (min-width: 1300px) {
    .profile-container {
      font-size: 1.14583vw;
      padding: 0.52083vw 0.72917vw;
      border-radius: 0.72917vw;
      margin: 1.5rem auto; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .profile-container {
      font-size: 1.27315vw;
      padding: 0.5787vw 0.81019vw;
      border-radius: 0.81019vw;
      margin: 1.5rem auto; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .profile-container {
      font-size: 1.27315vw;
      padding: 0.5787vw 0.81019vw;
      border-radius: 0.81019vw;
      margin: 1.5rem auto; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .profile-container {
      font-size: 2.81804vw;
      padding: 1.61031vw 2.41546vw;
      border-radius: 2.01288vw;
      margin: 1.5rem auto; }
      .profile-container else {
        font-size: 14px; }
      .profile-container else {
        border-radius: 10px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .profile-container {
      font-size: 2.25443vw;
      padding: 1.28824vw 1.93237vw;
      border-radius: 1.61031vw;
      margin: 1.5rem auto; }
      .profile-container else {
        font-size: 14px; }
      .profile-container else {
        border-radius: 10px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .profile-container {
      font-size: 1.9892vw;
      padding: 1.13669vw 1.70503vw;
      border-radius: 1.42086vw;
      margin: 1.5rem auto; }
      .profile-container else {
        font-size: 14px; }
      .profile-container else {
        border-radius: 10px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .profile-container {
      font-size: 1.77981vw;
      padding: 1.01704vw 1.52555vw;
      border-radius: 1.27129vw;
      margin: 1.5rem auto; }
      .profile-container else {
        font-size: 14px; }
      .profile-container else {
        border-radius: 10px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .profile-container {
      font-size: 1.61031vw;
      padding: 0.92017vw 1.38026vw;
      border-radius: 1.15022vw;
      margin: 1.5rem auto; }
      .profile-container else {
        font-size: 14px; }
      .profile-container else {
        border-radius: 10px; } }
  .profile-container svg {
    height: 4.83092vw;
    width: 4.83092vw; }
    @media (min-width: 1300px) {
      .profile-container svg {
        height: 1.5625vw;
        width: 1.5625vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .profile-container svg {
        height: 1.73611vw;
        width: 1.73611vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .profile-container svg {
        height: 1.73611vw;
        width: 1.73611vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .profile-container svg {
        height: 4.02576vw;
        width: 4.02576vw; }
        .profile-container svg else {
          height: 20px; }
        .profile-container svg else {
          width: 20px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .profile-container svg {
        height: 3.22061vw;
        width: 3.22061vw; }
        .profile-container svg else {
          height: 20px; }
        .profile-container svg else {
          width: 20px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .profile-container svg {
        height: 2.84172vw;
        width: 2.84172vw; }
        .profile-container svg else {
          height: 20px; }
        .profile-container svg else {
          width: 20px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .profile-container svg {
        height: 2.54259vw;
        width: 2.54259vw; }
        .profile-container svg else {
          height: 20px; }
        .profile-container svg else {
          width: 20px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .profile-container svg {
        height: 2.30044vw;
        width: 2.30044vw; }
        .profile-container svg else {
          height: 20px; }
        .profile-container svg else {
          width: 20px; } }

.flex-container {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.user-image {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: $tekie-amethyst;
  background-color: var(--accent-color);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 9.66184vw;
  width: 9.66184vw;
  border-radius: 1.93237vw; }
  .user-image img {
    height: 100%;
    width: 100%;
    border-radius: 1.93237vw; }
    @media (min-width: 1300px) {
      .user-image img {
        height: 100%;
        width: 100%;
        border-radius: 0.41667vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .user-image img {
        height: 100%;
        width: 100%;
        border-radius: 0.46296vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .user-image img {
        height: 100%;
        width: 100%;
        border-radius: 0.46296vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .user-image img {
        border-radius: 1.61031vw; }
        .user-image img else {
          height: 100%; }
        .user-image img else {
          width: 100%; }
        .user-image img else {
          border-radius: 8px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .user-image img {
        border-radius: 1.28824vw; }
        .user-image img else {
          height: 100%; }
        .user-image img else {
          width: 100%; }
        .user-image img else {
          border-radius: 8px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .user-image img {
        border-radius: 1.13669vw; }
        .user-image img else {
          height: 100%; }
        .user-image img else {
          width: 100%; }
        .user-image img else {
          border-radius: 8px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .user-image img {
        border-radius: 1.01704vw; }
        .user-image img else {
          height: 100%; }
        .user-image img else {
          width: 100%; }
        .user-image img else {
          border-radius: 8px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .user-image img {
        border-radius: 0.92017vw; }
        .user-image img else {
          height: 100%; }
        .user-image img else {
          width: 100%; }
        .user-image img else {
          border-radius: 8px; } }
  @media (min-width: 1300px) {
    .user-image {
      height: 2.70833vw;
      width: 2.70833vw;
      border-radius: 0.625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .user-image {
      height: 3.00926vw;
      width: 3.00926vw;
      border-radius: 0.69444vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .user-image {
      height: 3.00926vw;
      width: 3.00926vw;
      border-radius: 0.69444vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .user-image {
      height: 8.05153vw;
      width: 8.05153vw;
      border-radius: 1.61031vw; }
      .user-image else {
        height: 40px; }
      .user-image else {
        width: 40px; }
      .user-image else {
        border-radius: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .user-image {
      height: 6.44122vw;
      width: 6.44122vw;
      border-radius: 1.28824vw; }
      .user-image else {
        height: 40px; }
      .user-image else {
        width: 40px; }
      .user-image else {
        border-radius: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .user-image {
      height: 5.68343vw;
      width: 5.68343vw;
      border-radius: 1.13669vw; }
      .user-image else {
        height: 40px; }
      .user-image else {
        width: 40px; }
      .user-image else {
        border-radius: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .user-image {
      height: 5.08518vw;
      width: 5.08518vw;
      border-radius: 1.01704vw; }
      .user-image else {
        height: 40px; }
      .user-image else {
        width: 40px; }
      .user-image else {
        border-radius: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .user-image {
      height: 4.60087vw;
      width: 4.60087vw;
      border-radius: 0.92017vw; }
      .user-image else {
        height: 40px; }
      .user-image else {
        width: 40px; }
      .user-image else {
        border-radius: 8px; } }

.user-details {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  letter-spacing: .5px;
  display: block;
  padding: 0vw 2.89855vw 0.96618vw; }
  @media (min-width: 1300px) {
    .user-details {
      padding: 0.3125vw 0.83333vw 0.3125vw;
      width: 7.8125vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .user-details {
      padding: 0.34722vw 0.92593vw 0.34722vw;
      width: 8.68056vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .user-details {
      padding: 0.34722vw 0.92593vw 0.34722vw;
      width: 8.68056vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .user-details {
      padding: 0vw 2.41546vw 0.80515vw; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .user-details {
      padding: 0vw 1.93237vw 0.64412vw; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .user-details {
      padding: 0vw 1.70503vw 0.56834vw; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .user-details {
      padding: 0vw 1.52555vw 0.50852vw; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .user-details {
      padding: 0vw 1.38026vw 0.46009vw; } }

.user-name {
  font-weight: 550;
  position: relative;
  display: block;
  font-size: 3.62319vw;
  margin-bottom: 0.48309vw; }
  @media (min-width: 1300px) {
    .user-name {
      font-size: 0.9375vw;
      margin-bottom: 0.20833vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .user-name {
      font-size: 1.04167vw;
      margin-bottom: 0.23148vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .user-name {
      font-size: 1.04167vw;
      margin-bottom: 0.23148vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .user-name {
      font-size: 3.01932vw;
      margin-bottom: 0.40258vw; }
      .user-name else {
        font-size: 15px; }
      .user-name else {
        margin-bottom: 2px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .user-name {
      font-size: 2.41546vw;
      margin-bottom: 0.32206vw; }
      .user-name else {
        font-size: 15px; }
      .user-name else {
        margin-bottom: 2px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .user-name {
      font-size: 2.13129vw;
      margin-bottom: 0.28417vw; }
      .user-name else {
        font-size: 15px; }
      .user-name else {
        margin-bottom: 2px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .user-name {
      font-size: 1.90694vw;
      margin-bottom: 0.25426vw; }
      .user-name else {
        font-size: 15px; }
      .user-name else {
        margin-bottom: 2px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .user-name {
      font-size: 1.72533vw;
      margin-bottom: 0.23004vw; }
      .user-name else {
        font-size: 15px; }
      .user-name else {
        margin-bottom: 2px; } }

.sidebar-role {
  font-size: 1.93237vw;
  font-weight: 500;
  color: #666666;
  letter-spacing: 0.1px; }
  @media (min-width: 1300px) {
    .sidebar-role {
      font-size: 0.72917vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .sidebar-role {
      font-size: 0.81019vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .sidebar-role {
      font-size: 0.81019vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .sidebar-role {
      font-size: 1.61031vw; }
      .sidebar-role else {
        font-size: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .sidebar-role {
      font-size: 1.28824vw; }
      .sidebar-role else {
        font-size: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .sidebar-role {
      font-size: 1.13669vw; }
      .sidebar-role else {
        font-size: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .sidebar-role {
      font-size: 1.01704vw; }
      .sidebar-role else {
        font-size: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .sidebar-role {
      font-size: 0.92017vw; }
      .sidebar-role else {
        font-size: 8px; } }
  .sidebar-role span {
    font-size: 2.657vw; }
    @media (min-width: 1300px) {
      .sidebar-role span {
        font-size: 0.88542vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .sidebar-role span {
        font-size: 0.9838vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .sidebar-role span {
        font-size: 0.9838vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .sidebar-role span {
        font-size: 2.21417vw; }
        .sidebar-role span else {
          font-size: 11px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .sidebar-role span {
        font-size: 1.77134vw; }
        .sidebar-role span else {
          font-size: 11px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .sidebar-role span {
        font-size: 1.56294vw; }
        .sidebar-role span else {
          font-size: 11px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .sidebar-role span {
        font-size: 1.39842vw; }
        .sidebar-role span else {
          font-size: 11px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .sidebar-role span {
        font-size: 1.26524vw; }
        .sidebar-role span else {
          font-size: 11px; } }

.logo-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: default !important;
  width: 100%;
  height: 5.7971vw;
  margin-bottom: 2.89855vw;
  cursor: pointer; }
  @media (min-width: 1300px) {
    .logo-link {
      width: 100%;
      height: 2.34375vw;
      margin-bottom: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .logo-link {
      width: 100%;
      height: 2.60417vw;
      margin-bottom: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .logo-link {
      width: 100%;
      height: 2.60417vw;
      margin-bottom: 1.15741vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .logo-link {
      height: 4.83092vw;
      margin-bottom: 2.41546vw; }
      .logo-link else {
        width: 100%; }
      .logo-link else {
        height: 24px; }
      .logo-link else {
        margin-bottom: 12px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .logo-link {
      height: 3.86473vw;
      margin-bottom: 1.93237vw; }
      .logo-link else {
        width: 100%; }
      .logo-link else {
        height: 24px; }
      .logo-link else {
        margin-bottom: 12px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .logo-link {
      height: 3.41006vw;
      margin-bottom: 1.70503vw; }
      .logo-link else {
        width: 100%; }
      .logo-link else {
        height: 24px; }
      .logo-link else {
        margin-bottom: 12px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .logo-link {
      height: 3.05111vw;
      margin-bottom: 1.52555vw; }
      .logo-link else {
        width: 100%; }
      .logo-link else {
        height: 24px; }
      .logo-link else {
        margin-bottom: 12px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .logo-link {
      height: 2.76052vw;
      margin-bottom: 1.38026vw; }
      .logo-link else {
        width: 100%; }
      .logo-link else {
        height: 24px; }
      .logo-link else {
        margin-bottom: 12px; } }
  .logo-link img {
    width: auto;
    height: 5.7971vw; }
    @media (min-width: 1300px) {
      .logo-link img {
        width: auto;
        height: 2.34375vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .logo-link img {
        width: auto;
        height: 2.60417vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .logo-link img {
        width: auto;
        height: 2.60417vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .logo-link img {
        height: 4.83092vw; }
        .logo-link img else {
          width: auto; }
        .logo-link img else {
          height: 24px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .logo-link img {
        height: 3.86473vw; }
        .logo-link img else {
          width: auto; }
        .logo-link img else {
          height: 24px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .logo-link img {
        height: 3.41006vw; }
        .logo-link img else {
          width: auto; }
        .logo-link img else {
          height: 24px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .logo-link img {
        height: 3.05111vw; }
        .logo-link img else {
          width: auto; }
        .logo-link img else {
          height: 24px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .logo-link img {
        height: 2.76052vw; }
        .logo-link img else {
          width: auto; }
        .logo-link img else {
          height: 24px; } }

.navitem-link {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Inter';
  font-weight: 520;
  align-items: center;
  height: 7.97101vw;
  padding: 0vw 2.41546vw;
  margin: 1.93237vw 0vw;
  border-radius: 1.93237vw;
  color: #333333;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  letter-spacing: 0.3px;
  position: relative;
  text-decoration: none; }
  @media (min-width: 1300px) {
    .navitem-link {
      height: 2.70833vw;
      padding: 0vw 0.83333vw;
      margin: 0.57292vw 0vw;
      border-radius: 0.67708vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .navitem-link {
      height: 3.00926vw;
      padding: 0vw 0.92593vw;
      margin: 0.63657vw 0vw;
      border-radius: 0.75231vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .navitem-link {
      height: 3.00926vw;
      padding: 0vw 0.92593vw;
      margin: 0.63657vw 0vw;
      border-radius: 0.75231vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .navitem-link {
      height: 6.64251vw;
      padding: 0vw 2.01288vw;
      margin: 1.61031vw 0vw;
      border-radius: 1.61031vw; }
      .navitem-link else {
        height: 33px; }
      .navitem-link else {
        border-radius: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .navitem-link {
      height: 5.31401vw;
      padding: 0vw 1.61031vw;
      margin: 1.28824vw 0vw;
      border-radius: 1.28824vw; }
      .navitem-link else {
        height: 33px; }
      .navitem-link else {
        border-radius: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .navitem-link {
      height: 4.68883vw;
      padding: 0vw 1.42086vw;
      margin: 1.13669vw 0vw;
      border-radius: 1.13669vw; }
      .navitem-link else {
        height: 33px; }
      .navitem-link else {
        border-radius: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .navitem-link {
      height: 4.19527vw;
      padding: 0vw 1.27129vw;
      margin: 1.01704vw 0vw;
      border-radius: 1.01704vw; }
      .navitem-link else {
        height: 33px; }
      .navitem-link else {
        border-radius: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .navitem-link {
      height: 3.79572vw;
      padding: 0vw 1.15022vw;
      margin: 0.92017vw 0vw;
      border-radius: 0.92017vw; }
      .navitem-link else {
        height: 33px; }
      .navitem-link else {
        border-radius: 8px; } }
  .navitem-link:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    background: #F5F5F5;
    text-decoration: none; }
  .navitem-link:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }

.navitem-link-image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.96618vw; }
  @media (min-width: 1300px) {
    .navitem-link-image {
      margin-right: 0.67708vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .navitem-link-image {
      margin-right: 0.75231vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .navitem-link-image {
      margin-right: 0.75231vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .navitem-link-image {
      margin-right: 0.80515vw; }
      .navitem-link-image else {
        margin-right: 4px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .navitem-link-image {
      margin-right: 0.64412vw; }
      .navitem-link-image else {
        margin-right: 4px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .navitem-link-image {
      margin-right: 0.56834vw; }
      .navitem-link-image else {
        margin-right: 4px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .navitem-link-image {
      margin-right: 0.50852vw; }
      .navitem-link-image else {
        margin-right: 4px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .navitem-link-image {
      margin-right: 0.46009vw; }
      .navitem-link-image else {
        margin-right: 4px; } }
  .navitem-link-image svg {
    width: 5.7971vw;
    height: 5.7971vw; }
    @media (min-width: 1300px) {
      .navitem-link-image svg {
        width: 1.66667vw;
        height: 1.66667vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .navitem-link-image svg {
        width: 1.85185vw;
        height: 1.85185vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .navitem-link-image svg {
        width: 1.85185vw;
        height: 1.85185vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .navitem-link-image svg {
        width: 4.83092vw;
        height: 4.83092vw; }
        .navitem-link-image svg else {
          width: 24px; }
        .navitem-link-image svg else {
          height: 24px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .navitem-link-image svg {
        width: 3.86473vw;
        height: 3.86473vw; }
        .navitem-link-image svg else {
          width: 24px; }
        .navitem-link-image svg else {
          height: 24px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .navitem-link-image svg {
        width: 3.41006vw;
        height: 3.41006vw; }
        .navitem-link-image svg else {
          width: 24px; }
        .navitem-link-image svg else {
          height: 24px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .navitem-link-image svg {
        width: 3.05111vw;
        height: 3.05111vw; }
        .navitem-link-image svg else {
          width: 24px; }
        .navitem-link-image svg else {
          height: 24px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .navitem-link-image svg {
        width: 2.76052vw;
        height: 2.76052vw; }
        .navitem-link-image svg else {
          width: 24px; }
        .navitem-link-image svg else {
          height: 24px; } }

.navitem-link-title {
  font-size: 2.89855vw; }
  @media (min-width: 1300px) {
    .navitem-link-title {
      font-size: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .navitem-link-title {
      font-size: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .navitem-link-title {
      font-size: 1.15741vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .navitem-link-title {
      font-size: 2.41546vw; }
      .navitem-link-title else {
        font-size: 12px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .navitem-link-title {
      font-size: 1.93237vw; }
      .navitem-link-title else {
        font-size: 12px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .navitem-link-title {
      font-size: 1.70503vw; }
      .navitem-link-title else {
        font-size: 12px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .navitem-link-title {
      font-size: 1.52555vw; }
      .navitem-link-title else {
        font-size: 12px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .navitem-link-title {
      font-size: 1.38026vw; }
      .navitem-link-title else {
        font-size: 12px; } }

.navitem-link-active {
  color: $tekie-amethyst;
  color: var(--accent-color);
  background-color: #FAF6FF; }
  .navitem-link-active:hover {
    color: $tekie-amethyst;
    color: var(--accent-color);
    background: #FAF6FF;
    -webkit-transform: none;
            transform: none; }

.divider {
  padding: 6px 0px;
  border-bottom: 1.5px solid #EEEEEE;
  width: 95%;
  margin: auto; }

.logout-btn {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  color: #FF5744;
  border: none;
  background-color: #F6F6F6;
  display: flex;
  align-items: center;
  font-size: 3.38164vw; }
  @media (min-width: 1300px) {
    .logout-btn {
      font-size: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .logout-btn {
      font-size: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .logout-btn {
      font-size: 1.15741vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .logout-btn {
      font-size: 2.81804vw; }
      .logout-btn else {
        font-size: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .logout-btn {
      font-size: 2.25443vw; }
      .logout-btn else {
        font-size: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .logout-btn {
      font-size: 1.9892vw; }
      .logout-btn else {
        font-size: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .logout-btn {
      font-size: 1.77981vw; }
      .logout-btn else {
        font-size: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .logout-btn {
      font-size: 1.61031vw; }
      .logout-btn else {
        font-size: 14px; } }
  .logout-btn img {
    width: 3.38164vw;
    height: 3.38164vw;
    margin-right: 2.17391vw; }
    @media (min-width: 1300px) {
      .logout-btn img {
        width: 1.25vw;
        height: 1.25vw;
        margin-right: 0.625vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .logout-btn img {
        width: 1.38889vw;
        height: 1.38889vw;
        margin-right: 0.69444vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .logout-btn img {
        width: 1.38889vw;
        height: 1.38889vw;
        margin-right: 0.69444vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .logout-btn img {
        width: 2.81804vw;
        height: 2.81804vw;
        margin-right: 1.81159vw; }
        .logout-btn img else {
          width: 14px; }
        .logout-btn img else {
          height: 14px; }
        .logout-btn img else {
          margin-right: 9px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .logout-btn img {
        width: 2.25443vw;
        height: 2.25443vw;
        margin-right: 1.44928vw; }
        .logout-btn img else {
          width: 14px; }
        .logout-btn img else {
          height: 14px; }
        .logout-btn img else {
          margin-right: 9px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .logout-btn img {
        width: 1.9892vw;
        height: 1.9892vw;
        margin-right: 1.27877vw; }
        .logout-btn img else {
          width: 14px; }
        .logout-btn img else {
          height: 14px; }
        .logout-btn img else {
          margin-right: 9px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .logout-btn img {
        width: 1.77981vw;
        height: 1.77981vw;
        margin-right: 1.14416vw; }
        .logout-btn img else {
          width: 14px; }
        .logout-btn img else {
          height: 14px; }
        .logout-btn img else {
          margin-right: 9px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .logout-btn img {
        width: 1.61031vw;
        height: 1.61031vw;
        margin-right: 1.0352vw; }
        .logout-btn img else {
          width: 14px; }
        .logout-btn img else {
          height: 14px; }
        .logout-btn img else {
          margin-right: 9px; } }

.navitem-link-secondary {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  color: #605959;
  border: none;
  font-size: 3.38164vw; }
  @media (min-width: 1300px) {
    .navitem-link-secondary {
      font-size: 1.04167vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .navitem-link-secondary {
      font-size: 1.15741vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .navitem-link-secondary {
      font-size: 1.15741vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .navitem-link-secondary {
      font-size: 2.81804vw; }
      .navitem-link-secondary else {
        font-size: 14px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .navitem-link-secondary {
      font-size: 2.25443vw; }
      .navitem-link-secondary else {
        font-size: 14px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .navitem-link-secondary {
      font-size: 1.9892vw; }
      .navitem-link-secondary else {
        font-size: 14px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .navitem-link-secondary {
      font-size: 1.77981vw; }
      .navitem-link-secondary else {
        font-size: 14px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .navitem-link-secondary {
      font-size: 1.61031vw; }
      .navitem-link-secondary else {
        font-size: 14px; } }

.switch-btn {
  position: absolute;
  bottom: 4rem; }

.menu__icon__container {
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: 0.3s left ease-in-out;
  height: 9.66184vw;
  width: 9.66184vw;
  z-index: 1112; }
  @media (min-width: 1300px) {
    .menu__icon__container {
      top: 1.04167vw;
      border-radius: 0 0.78125vw 0.78125vw 0;
      height: 2.86458vw;
      width: 2.86458vw;
      left: 10.9375vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .menu__icon__container {
      top: 1.15741vw;
      border-radius: 0 0.86806vw 0.86806vw 0;
      height: 3.18287vw;
      width: 3.18287vw;
      left: 12.15278vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .menu__icon__container {
      top: 1.15741vw;
      border-radius: 0 0.86806vw 0.86806vw 0;
      height: 3.18287vw;
      width: 3.18287vw;
      left: 12.15278vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .menu__icon__container {
      height: 8.05153vw;
      width: 8.05153vw; }
      .menu__icon__container else {
        height: 40px; }
      .menu__icon__container else {
        width: 40px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .menu__icon__container {
      height: 6.44122vw;
      width: 6.44122vw; }
      .menu__icon__container else {
        height: 40px; }
      .menu__icon__container else {
        width: 40px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .menu__icon__container {
      height: 5.68343vw;
      width: 5.68343vw; }
      .menu__icon__container else {
        height: 40px; }
      .menu__icon__container else {
        width: 40px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .menu__icon__container {
      height: 5.08518vw;
      width: 5.08518vw; }
      .menu__icon__container else {
        height: 40px; }
      .menu__icon__container else {
        width: 40px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .menu__icon__container {
      height: 4.60087vw;
      width: 4.60087vw; }
      .menu__icon__container else {
        height: 40px; }
      .menu__icon__container else {
        width: 40px; } }
  .menu__icon__container svg {
    width: 5.7971vw;
    height: 5.7971vw; }
    .menu__icon__container svg path {
      fill: #a8a5a5; }
    .menu__icon__container svg:hover path {
      fill: $tekie-amethyst;
      fill: var(--accent-color); }
    @media (min-width: 1300px) {
      .menu__icon__container svg {
        width: 2.08333vw;
        height: 2.08333vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .menu__icon__container svg {
        width: 2.31481vw;
        height: 2.31481vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .menu__icon__container svg {
        width: 2.31481vw;
        height: 2.31481vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .menu__icon__container svg {
        width: 4.83092vw;
        height: 4.83092vw; }
        .menu__icon__container svg else {
          width: 24px; }
        .menu__icon__container svg else {
          height: 24px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .menu__icon__container svg {
        width: 3.86473vw;
        height: 3.86473vw; }
        .menu__icon__container svg else {
          width: 24px; }
        .menu__icon__container svg else {
          height: 24px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .menu__icon__container svg {
        width: 3.41006vw;
        height: 3.41006vw; }
        .menu__icon__container svg else {
          width: 24px; }
        .menu__icon__container svg else {
          height: 24px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .menu__icon__container svg {
        width: 3.05111vw;
        height: 3.05111vw; }
        .menu__icon__container svg else {
          width: 24px; }
        .menu__icon__container svg else {
          height: 24px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .menu__icon__container svg {
        width: 2.76052vw;
        height: 2.76052vw; }
        .menu__icon__container svg else {
          width: 24px; }
        .menu__icon__container svg else {
          height: 24px; } }

.open.menu__icon__container:hover {
  border-radius: 50%;
  background-color: rgba(228, 228, 228, 0.5); }
  .open.menu__icon__container:hover svg path {
    fill: $tekie-amethyst;
    fill: var(--accent-color); }

.teacher-app-hamburger {
  position: relative;
  z-index: 999;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .teacher-app-hamburger span {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    background: $tekie-amethyst;
    background: var(--accent-color);
    z-index: 999; }
    @media (min-width: 1000px) {
      .teacher-app-hamburger span {
        width: 75%;
        height: 0.27344vw;
        margin-bottom: 0.39062vw;
        border-radius: 0.23438vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .teacher-app-hamburger span {
        width: 75%;
        height: 0.30382vw;
        margin-bottom: 0.43403vw;
        border-radius: 0.26042vw; } }
    @media (min-width: 1001px) and (max-width: 1600px) {
      .teacher-app-hamburger span {
        width: 75%;
        height: 0.27344vw;
        margin-bottom: 0.39062vw;
        border-radius: 0.23438vw; } }
    @media (min-width: 1601px) and (max-width: 2000px) {
      .teacher-app-hamburger span {
        width: 75%;
        height: 0.21875vw;
        margin-bottom: 0.3125vw;
        border-radius: 0.1875vw; } }
    .teacher-app-hamburger span:last-of-type {
      margin-bottom: 0px; }
      @media (min-width: 1000px) {
        .teacher-app-hamburger span:last-of-type {
          height: 0.3125vw; } }
      @media (min-width: 900px) and (max-width: 1000px) {
        .teacher-app-hamburger span:last-of-type {
          height: 0.34722vw; } }
      @media (min-width: 1001px) and (max-width: 1600px) {
        .teacher-app-hamburger span:last-of-type {
          height: 0.3125vw; } }
      @media (min-width: 1601px) and (max-width: 2000px) {
        .teacher-app-hamburger span:last-of-type {
          height: 0.25vw; } }

@media (min-width: 1300px) {
  .close.menu__icon__container {
    left: 0;
    height: 2.60417vw;
    width: 2.60417vw; } }

@media (min-width: 900px) and (max-width: 1000px) {
  .close.menu__icon__container {
    left: 0;
    height: 2.89352vw;
    width: 2.89352vw; } }

@media (min-width: 1000px) and (max-width: 1300px) {
  .close.menu__icon__container {
    left: 0;
    height: 2.89352vw;
    width: 2.89352vw; } }

.close.menu__icon__container .teacher-app-hamburger span {
  width: 75%; }

.teacher-app-cn-navbar {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  z-index: 9999;
  -webkit-filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15)); }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(230, 247, 253, 0);
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      -webkit-filter: unset;
              filter: unset; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(230, 247, 253, 0);
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      -webkit-filter: unset;
              filter: unset; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(230, 247, 253, 0);
      transition: 0.3s all ease-in-out;
      cursor: pointer;
      -webkit-filter: unset;
              filter: unset; } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.open {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.open {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.open {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); } }
  .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 {
    margin-top: 1.80556vw;
    border-radius: 13.88889vw;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
    @media (min-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 {
        margin-top: 0vw;
        border-radius: 2.60417vw;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 {
        margin-top: 0vw;
        border-radius: 2.89352vw;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 {
        margin-top: 0vw;
        border-radius: 2.89352vw;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }
    @media (min-width: 450px) and (max-width: 500px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 {
        margin-top: 1.50463vw;
        border-radius: 11.57407vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          margin-top: 6.5px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 {
        margin-top: 1.2037vw;
        border-radius: 9.25926vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          margin-top: 6.5px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 {
        margin-top: 1.06209vw;
        border-radius: 8.16993vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          margin-top: 6.5px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 {
        margin-top: 0.95029vw;
        border-radius: 7.30994vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          margin-top: 6.5px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 {
        margin-top: 0.85979vw;
        border-radius: 6.61376vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          margin-top: 6.5px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
  .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 {
    margin-top: 1.66667vw;
    border-radius: 13.88889vw;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
    @media (min-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 {
        margin-top: 0vw;
        border-radius: 2.60417vw;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 {
        margin-top: 0vw;
        border-radius: 2.89352vw;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 {
        margin-top: 0vw;
        border-radius: 2.89352vw;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); } }
    @media (min-width: 450px) and (max-width: 500px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 {
        margin-top: 1.38889vw;
        border-radius: 11.57407vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          margin-top: 6px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 {
        margin-top: 1.11111vw;
        border-radius: 9.25926vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          margin-top: 6px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 {
        margin-top: 0.98039vw;
        border-radius: 8.16993vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          margin-top: 6px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 {
        margin-top: 0.87719vw;
        border-radius: 7.30994vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          margin-top: 6px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 {
        margin-top: 0.79365vw;
        border-radius: 6.61376vw; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          margin-top: 6px; }
        .teacher-app-cn-navbar.open .lines-1 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
  .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 {
    border-radius: 13.88889vw;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
    @media (min-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 {
        border-radius: 2.60417vw;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 {
        border-radius: 2.89352vw;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 {
        border-radius: 2.89352vw;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }
    @media (min-width: 450px) and (max-width: 500px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 {
        border-radius: 11.57407vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 {
        border-radius: 9.25926vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 {
        border-radius: 8.16993vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 {
        border-radius: 7.30994vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 {
        border-radius: 6.61376vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
  .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 {
    border-radius: 13.88889vw;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
    @media (min-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 {
        border-radius: 2.60417vw;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 {
        border-radius: 2.89352vw;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 {
        border-radius: 2.89352vw;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); } }
    @media (min-width: 450px) and (max-width: 500px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 {
        border-radius: 11.57407vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 {
        border-radius: 9.25926vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 {
        border-radius: 8.16993vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 {
        border-radius: 7.30994vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 {
        border-radius: 6.61376vw; }
        .teacher-app-cn-navbar.open .lines-3 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
  .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 {
    margin-top: -0.02778vw;
    border-radius: 13.88889vw;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
    @media (min-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 {
        border-radius: 2.60417vw;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 {
        border-radius: 2.89352vw;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 {
        border-radius: 2.89352vw;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); } }
    @media (min-width: 450px) and (max-width: 500px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 {
        margin-top: -0.02315vw;
        border-radius: 11.57407vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 {
        margin-top: -0.01852vw;
        border-radius: 9.25926vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 {
        margin-top: -0.01634vw;
        border-radius: 8.16993vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 {
        margin-top: -0.01462vw;
        border-radius: 7.30994vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 {
        margin-top: -0.01323vw;
        border-radius: 6.61376vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-1 else {
          border-radius: 50px; } }
  .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 {
    margin-top: -0.02778vw;
    border-radius: 13.88889vw;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
    @media (min-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 {
        border-radius: 2.60417vw;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 {
        border-radius: 2.89352vw;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 {
        border-radius: 2.89352vw;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); } }
    @media (min-width: 450px) and (max-width: 500px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 {
        margin-top: -0.02315vw;
        border-radius: 11.57407vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 {
        margin-top: -0.01852vw;
        border-radius: 9.25926vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 {
        margin-top: -0.01634vw;
        border-radius: 8.16993vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 {
        margin-top: -0.01462vw;
        border-radius: 7.30994vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 {
        margin-top: -0.01323vw;
        border-radius: 6.61376vw; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          margin-top: -0.1px; }
        .teacher-app-cn-navbar.open .lines-2 .teacher-app-cn-navbar-line-2 else {
          border-radius: 50px; } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.open .teacher-app-cn-navbar-line {
      -webkit-transform: translateY(200%);
              transform: translateY(200%); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.open .teacher-app-cn-navbar-line {
      -webkit-transform: translateY(200%);
              transform: translateY(200%); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.open .teacher-app-cn-navbar-line {
      -webkit-transform: translateY(200%);
              transform: translateY(200%); } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.open .lines-2 {
      opacity: 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.open .lines-2 {
      opacity: 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.open .lines-2 {
      opacity: 0; } }
  .teacher-app-cn-navbar.open .lines-3 {
    opacity: 0; }
    @media (min-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-3 {
        opacity: 1; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .teacher-app-cn-navbar.open .lines-3 {
        opacity: 1; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .teacher-app-cn-navbar.open .lines-3 {
        opacity: 1; } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-2 {
      transition: 0s ease-in-out;
      opacity: 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.close:hover .lines-2 {
      transition: 0s ease-in-out;
      opacity: 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-2 {
      transition: 0s ease-in-out;
      opacity: 0; } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-1 .teacher-app-cn-navbar-line-1 {
      border-radius: 2.60417vw;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.close:hover .lines-1 .teacher-app-cn-navbar-line-1 {
      border-radius: 2.89352vw;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-1 .teacher-app-cn-navbar-line-1 {
      border-radius: 2.89352vw;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-1 .teacher-app-cn-navbar-line-2 {
      border-radius: 2.60417vw;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.close:hover .lines-1 .teacher-app-cn-navbar-line-2 {
      border-radius: 2.89352vw;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-1 .teacher-app-cn-navbar-line-2 {
      border-radius: 2.89352vw;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-3 .teacher-app-cn-navbar-line-1 {
      border-radius: 2.60417vw;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.close:hover .lines-3 .teacher-app-cn-navbar-line-1 {
      border-radius: 2.89352vw;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-3 .teacher-app-cn-navbar-line-1 {
      border-radius: 2.89352vw;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-3 .teacher-app-cn-navbar-line-2 {
      border-radius: 2.60417vw;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.close:hover .lines-3 .teacher-app-cn-navbar-line-2 {
      border-radius: 2.89352vw;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .lines-3 .teacher-app-cn-navbar-line-2 {
      border-radius: 2.89352vw;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .teacher-app-cn-navbar-line {
      -webkit-transform: translateY(200%);
              transform: translateY(200%); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.close:hover .teacher-app-cn-navbar-line {
      -webkit-transform: translateY(200%);
              transform: translateY(200%); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.close:hover .teacher-app-cn-navbar-line {
      -webkit-transform: translateY(200%);
              transform: translateY(200%); } }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar.close:hover {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar.close:hover {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar.close:hover {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg); } }

.teacher-app-cn-navbar-line {
  display: flex;
  transition: 0.3s ease-in-out;
  flex-direction: row;
  width: 7.77778vw;
  margin-top: 1.11111vw;
  height: 0.83333vw; }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar-line {
      width: 1.45833vw;
      margin-top: 0.20833vw;
      height: 0.15625vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar-line {
      width: 1.62037vw;
      margin-top: 0.23148vw;
      height: 0.17361vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar-line {
      width: 1.62037vw;
      margin-top: 0.23148vw;
      height: 0.17361vw; } }
  .teacher-app-cn-navbar-line:first-child {
    margin-top: 0vw; }
    @media (min-width: 1300px) {
      .teacher-app-cn-navbar-line:first-child {
        margin-top: 0vw; } }
    @media (min-width: 900px) and (max-width: 1000px) {
      .teacher-app-cn-navbar-line:first-child {
        margin-top: 0vw; } }
    @media (min-width: 1000px) and (max-width: 1300px) {
      .teacher-app-cn-navbar-line:first-child {
        margin-top: 0vw; } }
    @media (min-width: 450px) and (max-width: 500px) {
      .teacher-app-cn-navbar-line:first-child {
        margin-top: 0vw; }
        .teacher-app-cn-navbar-line:first-child else {
          margin-top: 0px; } }
    @media (min-width: 500px) and (max-width: 600px) {
      .teacher-app-cn-navbar-line:first-child {
        margin-top: 0vw; }
        .teacher-app-cn-navbar-line:first-child else {
          margin-top: 0px; } }
    @media (min-width: 600px) and (max-width: 700px) {
      .teacher-app-cn-navbar-line:first-child {
        margin-top: 0vw; }
        .teacher-app-cn-navbar-line:first-child else {
          margin-top: 0px; } }
    @media (min-width: 700px) and (max-width: 800px) {
      .teacher-app-cn-navbar-line:first-child {
        margin-top: 0vw; }
        .teacher-app-cn-navbar-line:first-child else {
          margin-top: 0px; } }
    @media (min-width: 800px) and (max-width: 899px) {
      .teacher-app-cn-navbar-line:first-child {
        margin-top: 0vw; }
        .teacher-app-cn-navbar-line:first-child else {
          margin-top: 0px; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .teacher-app-cn-navbar-line {
      width: 6.48148vw;
      margin-top: 0.92593vw;
      height: 0.69444vw; }
      .teacher-app-cn-navbar-line else {
        width: 28px; }
      .teacher-app-cn-navbar-line else {
        margin-top: 4px; }
      .teacher-app-cn-navbar-line else {
        height: 3px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .teacher-app-cn-navbar-line {
      width: 5.18519vw;
      margin-top: 0.74074vw;
      height: 0.55556vw; }
      .teacher-app-cn-navbar-line else {
        width: 28px; }
      .teacher-app-cn-navbar-line else {
        margin-top: 4px; }
      .teacher-app-cn-navbar-line else {
        height: 3px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .teacher-app-cn-navbar-line {
      width: 4.57516vw;
      margin-top: 0.65359vw;
      height: 0.4902vw; }
      .teacher-app-cn-navbar-line else {
        width: 28px; }
      .teacher-app-cn-navbar-line else {
        margin-top: 4px; }
      .teacher-app-cn-navbar-line else {
        height: 3px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .teacher-app-cn-navbar-line {
      width: 4.09357vw;
      margin-top: 0.5848vw;
      height: 0.4386vw; }
      .teacher-app-cn-navbar-line else {
        width: 28px; }
      .teacher-app-cn-navbar-line else {
        margin-top: 4px; }
      .teacher-app-cn-navbar-line else {
        height: 3px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .teacher-app-cn-navbar-line {
      width: 3.7037vw;
      margin-top: 0.5291vw;
      height: 0.39683vw; }
      .teacher-app-cn-navbar-line else {
        width: 28px; }
      .teacher-app-cn-navbar-line else {
        margin-top: 4px; }
      .teacher-app-cn-navbar-line else {
        height: 3px; } }

.teacher-app-cn-navbar-line-1 {
  height: 100%;
  width: 50%;
  -webkit-transform-origin: right;
          transform-origin: right;
  transition: 0.3s all ease-in-out;
  background: #fff; }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar-line-1 {
      background: $tekie-amethyst;
      background: var(--accent-color); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar-line-1 {
      background: $tekie-amethyst;
      background: var(--accent-color); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar-line-1 {
      background: $tekie-amethyst;
      background: var(--accent-color); } }

.teacher-app-cn-navbar-line-2 {
  height: 100%;
  width: 50%;
  -webkit-transform-origin: left;
          transform-origin: left;
  transition: 0.3s all ease-in-out;
  background: #fff; }
  @media (min-width: 1300px) {
    .teacher-app-cn-navbar-line-2 {
      background: $tekie-amethyst;
      background: var(--accent-color); } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .teacher-app-cn-navbar-line-2 {
      background: $tekie-amethyst;
      background: var(--accent-color); } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .teacher-app-cn-navbar-line-2 {
      background: $tekie-amethyst;
      background: var(--accent-color); } }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #FF9084; }
  70% {
    -webkit-box-shadow: 0 0 0 8px rgba(204, 169, 44, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #FF9084; }
  70% {
    box-shadow: 0 0 0 8px rgba(204, 169, 44, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

:root {
  --accent-color:$tekie-amethyst;
  --accent-color-110: $tekie-amethyst-dark; }

.Button_Inter__2lfyJ, .Button_Inter-regular__3GLIb, .Button_Inter-SemiBold__1BDr4, .Button_Inter-Bold__2hWZX {
  font-family: 'Inter', 'Nunito', sans-serif; }

.Button_Inter-regular__3GLIb {
  font-weight: 400; }

.Button_Inter-SemiBold__1BDr4 {
  font-weight: 600; }

.Button_Inter-Bold__2hWZX {
  font-weight: 800; }

.Button_lato__3gwJ6, .Button_lato-light__99wh-, .Button_lato-regular__25ESV, .Button_lato-hairline__92jvU {
  font-family: 'Lato', sans-serif; }

.Button_lato-light__99wh- {
  font-weight: 300; }

.Button_lato-regular__25ESV {
  font-weight: 400; }

.Button_lato-hairline__92jvU {
  font-weight: 100; }

.Button_Inter__2lfyJ, .Button_Inter-regular__3GLIb, .Button_Inter-SemiBold__1BDr4, .Button_Inter-Bold__2hWZX {
  font-family: 'Inter', 'Nunito', sans-serif; }

.Button_Inter-regular__3GLIb {
  font-weight: 400; }

.Button_Inter-SemiBold__1BDr4 {
  font-weight: 600; }

.Button_Inter-Bold__2hWZX {
  font-weight: 800; }

.Button_btn__GGD-k {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff; }
  .Button_btn__GGD-k:disabled {
    color: #CCCCCC; }

.Button_primary__3tSJC, .Button_secondary__3Pa4P {
  background-color: $tekie-amethyst;
  background-color: var(--accent-color);
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  font-family: "Inter"; }
  @media (min-width: 1300px) {
    .Button_primary__3tSJC, .Button_secondary__3Pa4P {
      padding: 0.67708vw 1.04167vw;
      grid-gap: 0.3125vw;
      gap: 0.3125vw;
      border-radius: 0.52083vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .Button_primary__3tSJC, .Button_secondary__3Pa4P {
      padding: 0.75231vw 1.15741vw;
      grid-gap: 0.34722vw;
      gap: 0.34722vw;
      border-radius: 0.5787vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .Button_primary__3tSJC, .Button_secondary__3Pa4P {
      padding: 0.75231vw 1.15741vw;
      grid-gap: 0.34722vw;
      gap: 0.34722vw;
      border-radius: 0.5787vw; } }
  .Button_primary__3tSJC:disabled, .Button_secondary__3Pa4P:disabled {
    pointer-events: none;
    cursor: default;
    background-color: rgba(245, 245, 245, 0.5); }
    .Button_primary__3tSJC:disabled:hover, .Button_secondary__3Pa4P:disabled:hover {
      background-color: #CCCCCC; }
  .Button_primary__3tSJC:hover, .Button_secondary__3Pa4P:hover {
    background-color: $tekie-amethyst-dark;
    background-color: var(--accent-color-110);
    transition: 0.4s ease-in-out; }
  @media (min-width: 1300px) {
    .Button_primary__3tSJC span, .Button_secondary__3Pa4P span {
      font-size: 0.88542vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .Button_primary__3tSJC span, .Button_secondary__3Pa4P span {
      font-size: 0.9838vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .Button_primary__3tSJC span, .Button_secondary__3Pa4P span {
      font-size: 0.9838vw; } }

.Button_secondary__3Pa4P {
  background-color: #F3EFFA;
  color: $tekie-amethyst;
  color: var(--accent-color);
  font-family: "Inter"; }
  .Button_secondary__3Pa4P:disabled {
    pointer-events: none;
    cursor: default; }
    .Button_secondary__3Pa4P:disabled:hover {
      background-color: #CCCCCC; }
  .Button_secondary__3Pa4P:hover {
    cursor: pointer;
    background-color: #e2ceff;
    transition: 0.2s ease-in-out; }
  .Button_secondary__3Pa4P:active {
    background: #f3ebff;
    box-shadow: 0px 2px 8px rgba(113, 62, 188, 0.24);
    transition: 0.2s ease-in-out; }
  @media (min-width: 1300px) {
    .Button_secondary__3Pa4P span {
      font-size: 0.88542vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .Button_secondary__3Pa4P span {
      font-size: 0.9838vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .Button_secondary__3Pa4P span {
      font-size: 0.9838vw; } }

.Button_tertiary__2vWmp {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  color: $tekie-amethyst;
  color: var(--accent-color);
  border: 0.48309vw solid $tekie-amethyst;
  border: 0.48309vw solid var(--accent-color);
  padding: 2.17391vw 2.89855vw;
  border-radius: 1.93237vw;
  font-family: "Inter";
  font-weight: 500;
  background-color: #FAF6FF; }
  @media (min-width: 1300px) {
    .Button_tertiary__2vWmp {
      border: 0.10417vw solid $tekie-amethyst;
      border: 0.10417vw solid var(--accent-color);
      padding: 0.46875vw 0.625vw;
      border-radius: 0.41667vw; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    .Button_tertiary__2vWmp {
      border: 0.11574vw solid $tekie-amethyst;
      border: 0.11574vw solid var(--accent-color);
      padding: 0.52083vw 0.69444vw;
      border-radius: 0.46296vw; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    .Button_tertiary__2vWmp {
      border: 0.11574vw solid $tekie-amethyst;
      border: 0.11574vw solid var(--accent-color);
      padding: 0.52083vw 0.69444vw;
      border-radius: 0.46296vw; } }
  @media (min-width: 450px) and (max-width: 500px) {
    .Button_tertiary__2vWmp {
      border: 0.40258vw solid $tekie-amethyst;
      border: 0.40258vw solid var(--accent-color);
      padding: 1.81159vw 2.41546vw;
      border-radius: 1.61031vw; }
      .Button_tertiary__2vWmp else {
        border-radius: 8px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    .Button_tertiary__2vWmp {
      border: 0.32206vw solid $tekie-amethyst;
      border: 0.32206vw solid var(--accent-color);
      padding: 1.44928vw 1.93237vw;
      border-radius: 1.28824vw; }
      .Button_tertiary__2vWmp else {
        border-radius: 8px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    .Button_tertiary__2vWmp {
      border: 0.28417vw solid $tekie-amethyst;
      border: 0.28417vw solid var(--accent-color);
      padding: 1.27877vw 1.70503vw;
      border-radius: 1.13669vw; }
      .Button_tertiary__2vWmp else {
        border-radius: 8px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    .Button_tertiary__2vWmp {
      border: 0.25426vw solid $tekie-amethyst;
      border: 0.25426vw solid var(--accent-color);
      padding: 1.14416vw 1.52555vw;
      border-radius: 1.01704vw; }
      .Button_tertiary__2vWmp else {
        border-radius: 8px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    .Button_tertiary__2vWmp {
      border: 0.23004vw solid $tekie-amethyst;
      border: 0.23004vw solid var(--accent-color);
      padding: 1.0352vw 1.38026vw;
      border-radius: 0.92017vw; }
      .Button_tertiary__2vWmp else {
        border-radius: 8px; } }
  .Button_tertiary__2vWmp:disabled {
    pointer-events: none;
    cursor: default; }
  .Button_tertiary__2vWmp:hover {
    cursor: pointer;
    background-color: #F3EBFF;
    transition: 0.4s ease-in-out; }

.Button_ghost__L1S_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  color: #282828;
  font-family: "Inter";
  background-color: transparent;
  border: none;
  font-weight: 500;
  text-decoration: none; }
  .Button_ghost__L1S_1:disabled {
    pointer-events: none;
    cursor: default; }
  .Button_ghost__L1S_1:hover {
    cursor: pointer; }
  .Button_ghost__L1S_1:disabled {
    pointer-events: none; }

.Button_widthFull__2Lx9l {
  width: 100%; }

.Button_rounded__35bff {
  border-radius: 100%; }

.Button_posAbsolute__tqECD {
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: 999999; }

.Button_addIcon__3ZS8b svg {
  height: 20px;
  width: 20px; }

.Button_hoverStateAmethystDark__1qmPe:hover {
  background: $tekie-amethyst-dark;
  background: var(--accent-color-110);
  transition: 0.4s ease-in-out; }

:root {
  --accent-color:$tekie-amethyst;
  --accent-color-110: $tekie-amethyst-dark; }

.logoutModal_Inter__2OxCg, .logoutModal_Inter-regular__2oNcQ, .logoutModal_Inter-SemiBold__2A2GA, .logoutModal_Inter-Bold__2YgnN {
  font-family: 'Inter', 'Nunito', sans-serif; }

.logoutModal_Inter-regular__2oNcQ {
  font-weight: 400; }

.logoutModal_Inter-SemiBold__2A2GA {
  font-weight: 600; }

.logoutModal_Inter-Bold__2YgnN {
  font-weight: 800; }

.logoutModal_sessionModalContainer__1ucpj {
  z-index: 9999;
  background-color: rgba(125, 138, 143, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter'; }

.logoutModal_sessionModal__19xvl {
  width: 500px; }

.logoutModal_header__2kuHp {
  padding: 18px 20px;
  padding-bottom: 24px;
  background-color: #FAF6FF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  font-size: 18px; }

.logoutModal_header_headingContainer__uBErx {
  display: flex;
  justify-content: center;
  align-items: center; }

.logoutModal_body__3c__z {
  background-color: #FFFFFF;
  padding: 24px 22px 12px 22px;
  color: #424242;
  font-weight: 500; }

.logoutModal_sessionIcon__7jtSn {
  margin-right: 16px;
  padding: 16px;
  background-color: #FFFFFF;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center; }

.logoutModal_footer__178_o {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-top: 1px solid #F1F1F1;
  background-color: #FFFFFF; }

.logoutModal_closeModalIcon__1WgQn {
  cursor: pointer;
  margin-right: 0.5rem;
  margin-left: 1rem;
  padding: 0px 5px 2px 5px;
  border-radius: 4px; }
  .logoutModal_closeModalIcon__1WgQn:hover {
    background-color: $tekie-amethyst;
    background-color: var(--accent-color);
    color: #FFFFFF; }
    .logoutModal_closeModalIcon__1WgQn:hover svg > path {
      fill: white !important; }

.logoutModal_completed__JfxAf, .logoutModal_yetToBegin__3m2G3, .logoutModal_unAttended__3-W-r, .logoutModal_inProgress__2tnA4 {
  background-color: #E6F7F4;
  color: #01AA93;
  padding: 8px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: start;
  align-items: center; }
  .logoutModal_completed__JfxAf img, .logoutModal_yetToBegin__3m2G3 img, .logoutModal_unAttended__3-W-r img, .logoutModal_inProgress__2tnA4 img {
    margin-right: 10px; }

.logoutModal_yetToBegin__3m2G3 {
  background-color: #ebecec;
  color: $tekie-amethyst-dark;
  color: var(--accent-color-110); }

.logoutModal_unAttended__3-W-r {
  background-color: #FBEDEE;
  color: #FF5744; }

.logoutModal_inProgress__2tnA4 {
  background-color: #FFF7E7;
  color: #FAAD14; }

.logoutModal_headingContainer__v1vE- {
  margin: 16px auto;
  display: flex;
  justify-content: start;
  align-items: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px; }

.logoutModal_sessionDetails__1WAwQ {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px; }

.logoutModal_title__36jfl {
  color: #212121;
  font-weight: 600; }

.logoutModal_timeAndDay__191pi {
  color: #757575;
  font-size: 12px; }

.logoutModal_timeAndOtpContainer__1Js17 {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 12px; }

.logoutModal_gradeAndSection__dNDMF, .logoutModal_otp__1rymd {
  background-color: #EEEEEE;
  color: #282828;
  padding: 4px 8px;
  font-weight: 500;
  margin-right: 16px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px; }

.logoutModal_otp__1rymd {
  display: inline-block;
  color: #757575; }

.logoutModal_topicThumbnail__3JfEA {
  border: 1px solid #EEEEEE;
  border-radius: 16px;
  height: 110px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center; }

.logoutModal_reminderBtns__3hT7U {
  display: flex;
  justify-content: start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 20px; }

.logoutModal_moreDetailsHeading__liQap {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px; }

.logoutModal_moreDetailsContainer__2OOlv {
  display: flex;
  justify-content: start;
  align-items: center;
  grid-row-gap: 24px;
  row-gap: 24px;
  flex-wrap: wrap; }

.logoutModal_itemContainer__2W1mD {
  display: flex;
  justify-content: start;
  align-items: start;
  min-width: 33.33%; }

.logoutModal_sessionTypePill__1_OQa {
  background-color: #EEEEEE;
  color: #282828;
  font-size: 10px;
  border-radius: 16px;
  padding: 2px 8px;
  margin-right: 8px; }

.logoutModal_itemLabelAndTextContainer__33vXq {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px; }

.logoutModal_itemIcon__uH794 {
  color: #9E9E9E;
  margin-right: 4px; }

.logoutModal_itemLabel__1DzP2 {
  color: #757575;
  font-size: 10px; }

.logoutModal_link__SE3nM {
  color: #8C61CB;
  cursor: pointer;
  font-size: 12px; }

.logoutModal_timeDaySeparator__2zxpt {
  display: inline-block;
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: #757575;
  color: #757575;
  margin: auto 10px;
  margin-bottom: 4px; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',"Gilroy", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
  overflow-x: hidden; }

html, body {
  height: 100%; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',Gilroy monospace; }

@font-face {
  font-family: 'Nunito-Bold';
  src: local(Nunito-Bold), url(/static/media/Nunito-Bold.1cd294a7.ttf) format("truetype"); }

@font-face {
  font-family: 'Nunito-Bold';
  src: local(Gilroy-Light), url(/static/media/Gilroy-Light.da979280.woff) format("truetype"); }

@font-face {
  font-family: 'Nunito-Bold';
  src: local(Gilroy-Bold), url(/static/media/Gilroy-ExtraBold.871422df.woff) format("truetype"); }

@font-face {
  font-family: 'Nunito-SemiBold';
  src: local(Nunito-SemiBold), url(/static/media/Nunito-SemiBold.76ae20d4.ttf) format("truetype"); }

@font-face {
  font-family: 'Gilroy';
  src: url(/static/media/Gilroy-Medium.6444f14a.ttf) format("woff2");
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gilroy-light';
  src: url(/static/media/gilroy-light-webfont.69c677ec.woff2) format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

::-webkit-scrollbar {
  max-width: 0.78125vw;
  height: 1.20773vw;
  display: flex; }
  @media (min-width: 450px) and (max-width: 500px) {
    ::-webkit-scrollbar {
      height: 1.00644vw; }
      ::-webkit-scrollbar else {
        height: 5px; } }
  @media (min-width: 500px) and (max-width: 600px) {
    ::-webkit-scrollbar {
      height: 0.80515vw; }
      ::-webkit-scrollbar else {
        height: 5px; } }
  @media (min-width: 600px) and (max-width: 700px) {
    ::-webkit-scrollbar {
      height: 0.71043vw; }
      ::-webkit-scrollbar else {
        height: 5px; } }
  @media (min-width: 700px) and (max-width: 800px) {
    ::-webkit-scrollbar {
      height: 0.63565vw; }
      ::-webkit-scrollbar else {
        height: 5px; } }
  @media (min-width: 800px) and (max-width: 899px) {
    ::-webkit-scrollbar {
      height: 0.57511vw; }
      ::-webkit-scrollbar else {
        height: 5px; } }
  @media (min-width: 1300px) {
    ::-webkit-scrollbar {
      height: 0; } }
  @media (min-width: 900px) and (max-width: 1000px) {
    ::-webkit-scrollbar {
      height: 0; } }
  @media (min-width: 1000px) and (max-width: 1300px) {
    ::-webkit-scrollbar {
      height: 0; } }

::-webkit-scrollbar-thumb {
  background-color: #34e4ea;
  border-radius: 0.52083vw; }

::-webkit-resizer {
  display: none; }

@media all and (display-mode: standalone) {
  div, span, input, button {
    -webkit-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
    -webkit-tap-highlight-color: transparent !important; } }

