@import 'scss/size.scss';

.side-navbar-container {
  position: relative;
  width: hs(352px);
  height: 100%;
  background-image: linear-gradient(to bottom, #c9fcfd, #34e4ea);
  height: calc(100vh - #{hs(88px)});
  overflow: overlay;
  &::-webkit-scrollbar  {
    max-width: hs(8px);
    display: flex;
  }
  @include resD((
    display: block,
  ));
  @include resSm((
    display: none,
  ));
}

.side-navbar-navBarCloudsContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: hs(237px);
  pointer-events: none;
}

.side-navbar-topicTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: hs(67px);
  margin-bottom: hs(38px);
  cursor: pointer;
}

.side-navbar-topicTitle {
  font-size: hs(26px);
  color: #0B535F;
  font-weight: bold;
}
.side-navbar-backArrow {
  margin-left: hs(20px);
  margin-right: hs(18px);
  width: hs(10.5px);
  height: hs(18px);
}

.side-navbar-backIcon {
  position: relative;
  top: hs(-3.325px);
}

.side-navbar-logoutButton{
  position: absolute;
  bottom: hs(83.1px);
  left: hs(77px);
}

.side-navbar-settingsSidebar{
  padding-top: hs(80px);
}

.side-navbar-buttonContainer {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: hs(83.1px);
}

.side-navbar-disabled {
  opacity: 0.85;
}

.side-navbar-emptyDiv {
  width: 100%;
  height: hs(68px);
}
