@import 'scss/size.scss';

.nProgressContainer {
  pointer-events: none;
}

.nProgressWrapper {
  background: #29d;
  height: 2;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1031;
}

.nProgressItem {
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  display: block;
  height: 100%;
  opacity: 1;
  position: absolute;
  right: 0;
  transform: rotate(3deg) translate(0px, -4px);
  width: 100px;
}
